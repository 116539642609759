import i18n from "i18next";
import * as _ from "lodash";
import { initReactI18next } from "react-i18next";
import danishTranslationContent from "./assets/translations/strings_da.properties";
import dutchTranslationContent from "./assets/translations/strings_nl.properties";
import germanTranslationContent from "./assets/translations/strings_de.properties";
import englishTranslationContent from "./assets/translations/strings_en.properties";
import spanishTranslationContent from "./assets/translations/strings_es.properties";
import frenchTranslationContent from "./assets/translations/strings_fr.properties";
import norwegianTranslationContent from "./assets/translations/strings_nb.properties";
import polishTranslationContent from "./assets/translations/strings_pl.properties";
import swedishTranslationContent from "./assets/translations/strings_sv.properties";

export function createTranslationObject(translationContent: string): {} {
    const toReturn = {};
    const translationLines: string[] = translationContent.split("\n");
    let keyValuePair: string[];
    _.each(translationLines, function(line) {
        keyValuePair = line.split("=");
        toReturn[keyValuePair[0]] = keyValuePair[1];
    });
    return toReturn;
}

const resources = {
    en: {
        translation: createTranslationObject(englishTranslationContent),
    },
    fr: {
        translation: createTranslationObject(frenchTranslationContent),
    },
    pl: {
        translation: createTranslationObject(polishTranslationContent),
    },
    de: {
        translation: createTranslationObject(germanTranslationContent),
    },
    da: {
        translation: createTranslationObject(danishTranslationContent),
    },
    es: {
        translation: createTranslationObject(spanishTranslationContent),
    },
    nl: {
        translation: createTranslationObject(dutchTranslationContent),
    },
    no: {
        translation: createTranslationObject(norwegianTranslationContent),
    },
    sv: {
        translation: createTranslationObject(swedishTranslationContent),
    },
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",
        fallbackLng: "en",
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss F
        },
        defaultNS: "translation",
        react: {
            wait: true,
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
        },
    });

export default i18n;
