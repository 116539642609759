import * as _ from "lodash";
import Identification from "../../models/Identification";
import IDType from "../../models/IDType";
import { Region } from "../../models/Jurisdiction";
import OwnershipType from "../../models/OwnershipType";
import Person from "../../models/Person";
import PositionType from "../../models/PositionType";
import SelfboardApplication from "../../models/SelfBoardApplication";
import { getCountryFromState } from "../../models/StatesAbreviatedClass";
import TaxClasstype from "../../models/TaxClassType";
import { AppState } from "../../reducers";
import isEmpty from "../IsEmpty";
import { parseDateOfBirth } from "./SelfBoardToScarecrowApplicationConverter";

export const applyRegionalRules = (
    state: AppState,
    selfBoardApplication: SelfboardApplication,
): SelfboardApplication => {
    const nextSelfBoardApplication = _.cloneDeep(selfBoardApplication);
    const { region } = state.JurisdictionInfo;

    switch (region) {
        case Region.NA: {
            nextSelfBoardApplication.scarecrowApplication!.principal!.titleType =
                state.personalProfileForm.personalPrincipleTitle;

            const stateOwners = state.ownersProfileForm.owners;
            const appOwners =
                nextSelfBoardApplication.scarecrowApplication!
                    .additionalShareholders || [];
            appOwners.forEach((person, index) => {
                person.titleType = stateOwners[index].ownersPrincipalTitle;
            });

            if (
                nextSelfBoardApplication.scarecrowApplication!.businessInfo!
                    .ownershipType === OwnershipType.SOLE_TRADER
            ) {
                delete nextSelfBoardApplication.scarecrowApplication!.principal!
                    .responsibleParty;
            }

            nextSelfBoardApplication.scarecrowApplication!.businessInfo!.taxClassType = getTaxClasstype(
                state.placeHolderForm.ownershipType,
            );

            return nextSelfBoardApplication;
        }
        case Region.EU: {
            nextSelfBoardApplication.scarecrowApplication!.bankAccounts!.DEPOSIT!.bankName =
                state.placeHolderForm.bankName;

            return nextSelfBoardApplication;
        }
        default: {
            return nextSelfBoardApplication;
        }
    }
};

export const OwnershipTypeToTaxClassTypeAssociations: {
    [key in OwnershipType]?: TaxClasstype;
} = {
    [OwnershipType.LIMITED_COMPANY]: TaxClasstype.PARTNERSHIP,
    [OwnershipType.LIMITED_COMPANY_CORPORATION]: TaxClasstype.CORPORATION,
    [OwnershipType.LIMITED_COMPANY_DISREGARDED_ENTITY]:
        TaxClasstype.DISREGARDED_ENTITY,
};

const getTaxClasstype = (ownershipType?: OwnershipType) => {
    return ownershipType
        ? OwnershipTypeToTaxClassTypeAssociations[ownershipType]
        : undefined;
};

export const applyCountryRules = (
    state: AppState,
    selfBoardApplication: SelfboardApplication,
): SelfboardApplication => {
    const nextSelfBoardApplication = _.cloneDeep(selfBoardApplication);
    const { country } = state.JurisdictionInfo;

    const businessInfo = nextSelfBoardApplication.scarecrowApplication!
        .businessInfo!;
    const { businessProfileForm, EquipmentForm } = state;

    switch (country) {
        case "USA": {
            const {
                placeHolderForm: { ownershipType },
                creditUnderwritingForm: { employerId },
                personalProfileForm: { personalIDType, personalITIN },
            } = state;
            const ownershipTypeList = [OwnershipType.LIMITED_COMPANY_DISREGARDED_ENTITY, OwnershipType.FOUNDATION,
                OwnershipType.CHARITY, OwnershipType.CLUB];
            if (_.includes(ownershipTypeList, ownershipType)) {
                clearAdditionalShareholders(nextSelfBoardApplication);
            }
            if (ownershipType !== OwnershipType.SOLE_TRADER) {
                businessInfo.taxID = employerId;
            } else if (
                ownershipType === OwnershipType.SOLE_TRADER &&
                personalIDType === IDType.ITIN
            ) {
                businessInfo.taxID = personalITIN;
            }

            const principal = nextSelfBoardApplication.scarecrowApplication!
                .principal!;
            const owners = nextSelfBoardApplication.scarecrowApplication!
                .additionalShareholders || [];
            [principal, ...owners].forEach((person) => {
                const address = person.contactInfo!.address!;
                if (address.state && !address.country) {
                    address.country = getCountryFromState(address.state);
                }
            });

            businessInfo.additionalAddresses!.LEGAL!.classification =
                businessProfileForm.businessLegalAddressClassification;
            businessInfo.businessAddress!.classification =
                businessProfileForm.businessAddressClassification;

            if (businessInfo.additionalAddresses!.LEGAL!.country === "USA") {
                nextSelfBoardApplication.scarecrowApplication!.financialInfo!.fundingCurrency =
                    "USD";
            }

            return nextSelfBoardApplication;
        }
        case "CAN": {
            const { placeHolderForm: { ownershipType }} = state;
            const ownershipTypeList = [OwnershipType.LIMITED_COMPANY_DISREGARDED_ENTITY, OwnershipType.FOUNDATION,
                OwnershipType.CHARITY, OwnershipType.CLUB];
            if (_.includes(ownershipTypeList, ownershipType)) {
                clearAdditionalShareholders(nextSelfBoardApplication);
            }
            businessInfo.taxID = businessProfileForm.businessTaxID;

            return nextSelfBoardApplication;
        }
        case "NOR": {
            if (EquipmentForm.equipmentInfo && EquipmentForm.equipmentInfo.equipmentItems) {
                const { equipmentItems } = EquipmentForm.equipmentInfo;
                const { baxEffectiveDate, baxNumber } = EquipmentForm;
                if (!!baxEffectiveDate === !!baxNumber) {
                    equipmentItems
                        .filter((item) => item.code === "fakeNetsSku")
                        .forEach((item) => {
                            item.itemSettings.options = [ {} ];
                            if (baxEffectiveDate && baxNumber) {
                                item.itemSettings.options = [
                                    {
                                        baxNumber,
                                        baxEffectiveDate: parseDateOfBirth(
                                            new Date(baxEffectiveDate),
                                        ),
                                    },
                                ];
                            }
                        });
                }
            }
            nextSelfBoardApplication.scarecrowApplication!.equipmentInfo = EquipmentForm.equipmentInfo;

            const {
                bankAccounts = {},
            } = nextSelfBoardApplication.scarecrowApplication!;
            const { bankAccountNumber } = state.placeHolderForm;
            bankAccounts.DEPOSIT = {
                ...bankAccounts.DEPOSIT,
                sortCode: bankAccountNumber
                    ? bankAccountNumber.substr(0, 4)
                    : undefined,
                accountNumber: bankAccountNumber
                    ? bankAccountNumber.substr(4)
                    : undefined,
            };

            return nextSelfBoardApplication;
        }
        default: {
            return nextSelfBoardApplication;
        }
    }
};


export function setPositionType( personalDirectorQuestion: string, principal: Person) {
    if (!isEmpty(personalDirectorQuestion)) {
        if (!principal.positions) {
            principal.positions = {};
        }
        principal.positions[PositionType.DIRECTOR] = personalDirectorQuestion === "true" ? true : false;
    }
}

function clearAdditionalShareholders(application: SelfboardApplication) {
    application.scarecrowApplication!.additionalShareholders = [];
}

export const applyReferrerRules = (
    state: AppState,
    selfBoardApplication: SelfboardApplication,
): SelfboardApplication => {
    const nextSelfBoardApplication = _.cloneDeep(selfBoardApplication);
    const { referrerName, country } = state.JurisdictionInfo;

    switch (referrerName) {
        case "SELF_BOARD_USA": {
            const {
                bankAccounts = {},
            } = nextSelfBoardApplication.scarecrowApplication!;
            _.each(bankAccounts, (bankAccount) => {
                if (bankAccount) {
                    bankAccount.country = country;
                }
            });

            return nextSelfBoardApplication;
        }
        case "SELF_BOARD_POL": {
            const principal  = nextSelfBoardApplication.scarecrowApplication!.principal!;
            const addlOwners = nextSelfBoardApplication.scarecrowApplication!.additionalShareholders || [];
            const stateOwners = state.ownersProfileForm.owners;
            const ownershipType = state.placeHolderForm.ownershipType;
            const personalProfileForm = state.personalProfileForm;
            const ownershipTypeList = [OwnershipType.SOLE_TRADER];

            setPositionType(personalProfileForm.personalDirectorQuestion!, principal);
            addlOwners.forEach((person, index) => {
                setPositionType(stateOwners[index].ownersPersonalDirectorQuestion!, person);
                person.ids = [];
                if (stateOwners[index].ownersPersonalPeselNumber) {
                    person.ids.push({ idType: IDType.PESEL, idNumber: stateOwners[index].ownersPersonalPeselNumber});
                }
                if (stateOwners[index].ownersPersonalProofOfIdentity) {
                    const id: Identification = {
                        idType : IDType[stateOwners[index].ownersPersonalProofOfIdentity!],
                        idNumber: stateOwners[index].ownersPersonalDocumentNumber,
                        expiryDate: stateOwners[index].ownersIdExpiryDate
                                ? parseDateOfBirth(new Date(stateOwners[index].ownersIdExpiryDate!)) : undefined,
                        issuingAgency: stateOwners[index].ownersPersonalIssueAgency,
                        issuingCountry: stateOwners[index].ownersPersonalCountryOfDocIssuance,
                    };
                    person.ids.push(id);
                }
            });
            if (_.includes(ownershipTypeList, ownershipType)) {
                clearAdditionalShareholders(nextSelfBoardApplication);
            }
            return nextSelfBoardApplication;
        }
        case "SELF_BOARD_NOR": {
            const principal  = nextSelfBoardApplication.scarecrowApplication!.principal!;
            const addlOwners = nextSelfBoardApplication.scarecrowApplication!.additionalShareholders || [];
            const stateOwners = state.ownersProfileForm.owners;
            const ownershipType = state.placeHolderForm.ownershipType;
            const personalProfileForm = state.personalProfileForm;
            const ownershipTypeList = [OwnershipType.SOLE_TRADER];

            setPositionType(personalProfileForm.personalDirectorQuestion!, principal);
            addlOwners.forEach((person, index) => {
                setPositionType(stateOwners[index].ownersPersonalDirectorQuestion!, person);
            });
            if (_.includes(ownershipTypeList, ownershipType)) {
                clearAdditionalShareholders(nextSelfBoardApplication);
            }
            return nextSelfBoardApplication;
        }
        default: {
            return nextSelfBoardApplication;
        }
    }
};
