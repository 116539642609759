import { Button, Grid, Typography } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import * as React from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { SalesPersonContact } from "../../models/SalesPersonContact";
import { CustomerAppState } from "../../reducers/customer_ui";
import ARHttp from "../../services/ARHttp";
import CustomerFlowContactUs from "./CustomerFlowContactUs";

function mapStateToProps(state: CustomerAppState) {
    return {
        country: state.JurisdictionInfo.country,
        brand: state.JurisdictionInfo.brandCode,
        referrer: state.JurisdictionInfo.referrerName,
        username: state.auth.username,
        openContactUs: state.rest.showContactUsModal,
        isThirdParty: state.rest.isForThirdParty,
        salesPersonContact: state.rest.salesPersonContact,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        logout: () => {
            dispatch({ type: "LOGOUT" });
        },
        toggleContactUsModal: () => {
            dispatch({ type: "TOGGLE_CONTACT_US_MODAL" });
        },
    };
};

interface Props {
    logout: () => void;
    toggleContactUsModal: () => void;
    loggedIn: boolean;
    country?: string;
    brand?: string;
    referrer?: string;
    isLoggedIn: boolean;
    username: string;
    openContactUs: boolean;
    isThirdParty: boolean;
    salesPersonContact: SalesPersonContact;
}

class CustomerFlowHeaderMenu extends React.Component<Props & RouteComponentProps, any> {
    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
    }

    public render() {
        let developerNavigation;
        if (process.env.NODE_ENV === "development") {
            developerNavigation = (
                <Grid container={true} direction="row" spacing={1}>
                    <Grid item={true}>
                        <Typography variant="body1">Dev Nav Menu:</Typography>
                    </Grid>
                    <Grid item={true}>
                        <NavLink to="/register">Register</NavLink>
                    </Grid>
                    <Grid item={true}>
                        <NavLink to="/login">Login</NavLink>
                    </Grid>
                    <Grid item={true}>
                        <NavLink to="/applist">App List</NavLink>
                    </Grid>
                    <Grid item={true}>
                        <NavLink to="/quiz">Quiz</NavLink>
                    </Grid>
                    <Grid item={true}>
                        <NavLink to="/application">Application</NavLink>
                    </Grid>
                    <Grid item={true}>
                        <NavLink to="/submitted">Submitted</NavLink>
                    </Grid>
                    <Grid item={true}>
                        <NavLink to="/complete">Complete</NavLink>
                    </Grid>
                    <Grid item={true}>
                        <NavLink to="/declined">Declined</NavLink>
                    </Grid>
                    <Grid item={true}>
                        <NavLink to="/resetpw">Reset Password</NavLink>
                    </Grid>
                </Grid>
            );
        }

        const contactUS = (
            <Grid item={true}>
                <Button onClick={this.handleOpen} variant="text" style={{ width: "max-content" }}>
                    <Trans>contact_us</Trans>
                </Button>
                <CustomerFlowContactUs
                    open={this.props.openContactUs}
                    onClose={this.handleClose}
                    country={this.props.country}
                    brand={this.props.brand}
                    referrer={this.props.referrer}
                    isThirdParty={this.props.isThirdParty}
                    salesPersonContact={this.props.salesPersonContact}
                />
            </Grid>
        );

        const logout = (
            <Grid item={true}>
                <Button onClick={this.logout}><Trans>sign_out</Trans></Button>
            </Grid>
        );

        const identification = (
            <Grid item={true}>
                {/* Height 36px because I can't figure out how to get the item to size to 100% of it's container. */}
                <Grid container={true} spacing={0} direction={"row"} alignItems={"center"} style={{ height: "36px" }}>
                    <Person />
                    <span>{this.props.username}</span>
                </Grid>
            </Grid>
        );

        return (
            <React.Fragment>
                <Grid container={true} direction="row-reverse" spacing={1}>
                    {/* Row Reverse means these are displayed in reverse order on the page */}
                    {this.props.username ? logout : undefined}
                    {contactUS}
                    {this.props.username ? identification : undefined}
                </Grid>
                {developerNavigation}
            </React.Fragment>
        );
    }

    private logout() {
        ARHttp({
            method: "POST",
            url: "/aws/logout",
        })
            .pipe(
                catchError((err) => {
                    return of(err);
                }),
            )
            .subscribe((res) => {
                this.props.logout();
                this.props.history.push("/login");
            });
    }

    private handleOpen() {
        this.props.toggleContactUsModal();
    }

    private handleClose() {
        this.props.toggleContactUsModal();
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(CustomerFlowHeaderMenu as any));
