import { Grid, Typography } from "@material-ui/core";
import * as React from "react";
import { useStyles } from "./DefaultErrorScreen";

const CostcoErrorScreen = () => {
    const classes = useStyles();

    // TODO: Convert to string keys.
    return (
        <Grid item={true} md={6} xs={12} className={classes.errorItem}>
            <div style={{ margin: "100px" }}>
                <Typography variant="h6" style={{ fontSize: "20px" }}>
                    You have exceeded your maximum number of attempts.
                </Typography>
                <Typography variant="h6" style={{ fontSize: "20px" }}>
                    Your account has now been locked.
                </Typography>
            </div>
        </Grid>
    );
};

export default CostcoErrorScreen;
