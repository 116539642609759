import { Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import ConnectedApplicationComplete from "../../components/customer/CustomerFlowApplicationComplete";
import ConnectedGDPRCookiePreferences from "../../containers/customer/ConnectedGDPRCookiePreferences";
import ConnectedHeader from "../../containers/customer/ConnectedHeader";
import ConnectedLogin from "../../containers/customer/ConnectedLogin";
import ConnectedDisclosure from "../../containers/customer/ConnectedDisclosure";
import ConnectedRegistration from "../../containers/customer/ConnectedRegistration";
import ConnectedResetPassword from "../../containers/customer/ConnectedResetPassword";
import ConnectedReviewAndSign from "../../containers/customer/ConnectedReviewAndSign";
import ConnectedSelectApplication from "../../containers/customer/ConnectedSelectApplication";
import ConnectedSubmitted from "../../containers/customer/ConnectedSubmitted";
import ConnectedUpdateInProgress from "../../containers/customer/ConnectedUpdateInProgress";
import CustomerAuthenticationGuard from "../../containers/customer/CustomerAuthenticationGuard";
import CustomerEIDContainer from "../../containers/customer/CustomerEIDContainer";
import CustomerLockedApplicationStatusCheck from "../../containers/customer/CustomerLockedApplicationStatusCheck";
import { AuthDestination } from "../../models/AuthDestination";
import CustomerApplicationDeclined from "./CustomerApplicationDeclined";
import CustomerFlowCookieModal from "./CustomerFlowCookieModal";
import CookiePolicyPage from "./CookiePolicyPage";
import CustomerFlowPrivacyPolicyInfo from "./CustomerFlowPrivacyPolicyInfo";
import CustomerFlowSensitiveDataRemoved from "./CustomerFlowSensitiveDataRemoved";

export interface Props {
  showCookieModal: boolean;
  isLoggedIn: boolean;
  authenticationDestination: AuthDestination;
  country: string;
  enableCookies: () => void;
  toggleCookieModal: () => void;
  setSkipToApp: () => void;
}

const useStyles = makeStyles({
  body: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    margin: "0",
  },
  centralContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "960px",
    margin: "0 auto",
    flex: 1,
  },
  footer: {
    alignItems: "center",
    marginTop: "20px",
    backgroundColor: "#1F1F1F",
    borderTop: "2px solid #E7E7E7",
    color: "#fff",
  },
  footerWrapper: {
    margin: "1% 10%",
  },
  footerNav: {
    display: "flex",
    margin: "5px auto",
    justifyContent: "space-between",
    fontSize: "x-small",
  },
  modal: {
    postion: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    width: "fit-content",
  },
  policy: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      cursor: "pointer",
      textShadow: "0px 0px 5px #fff",
   },
  },
});

const CustomerFlow = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isLoggedIn, authenticationDestination, showCookieModal, country, toggleCookieModal, setSkipToApp } = props;

  let destinationString: string;
  let defaultDestination: React.JSX.Element;
  if (isLoggedIn) {
    switch (authenticationDestination) {
      // This is only used when returning from the Partial Application flow. We want to automate the selection of an app, instead
      // of skipping straight to it - as there are many details that need the whole app record to be present.
      case AuthDestination.APP:
        destinationString = "/applist";
        defaultDestination = <Redirect to={destinationString} />;
        setSkipToApp();
        break;
      case AuthDestination.RESETPW:
        destinationString = "/resetpw";
        defaultDestination = <Redirect to={destinationString} />;
        break;
      case AuthDestination.APPLIST:
      default:
        destinationString = "/applist";
        defaultDestination = <Redirect to={destinationString} />;
        break;
    }
  } else {
    switch (authenticationDestination) {
      case AuthDestination.REGISTER:
        destinationString = "/register";
        defaultDestination = <Redirect to={destinationString} />;
        break;
      case AuthDestination.RESETPW:
        destinationString = "/resetpw";
        defaultDestination = <Redirect to={destinationString} />;
        break;
      case AuthDestination.LOGIN:
      default:
        destinationString = "/login";
        defaultDestination = <Redirect to={destinationString} />;
        break;
    }
  }

  const FooterSection = (): React.JSX.Element => {
    return (
      <footer className={classes.footer}>
        <div className={classes.footerWrapper}>
          <FooterNavigationCopyright />
          <ConnectedDisclosure/>
        </div>
      </footer>
    );
  };

  const FooterNavigationCopyright = (): React.JSX.Element => {
    const CookiePolicyLink = (): React.JSX.Element => {
      return (!(country === "CAN" || country === "USA")) ? (
        <span>
          <span aria-hidden="true" style={{ margin: "auto 5px" }}>|</span>
          <Link id="cookiePolicy" to="/cookiepolicy" className={classes.policy}>
            {t("cookie_policy_header")}
          </Link>
        </span>
        )
      :
        <></>;
    };

    const PrivacyPolicyLink = (): React.JSX.Element => {
      return (country === "USA" || country === "CAN") ? PrivacyPolicyNA() : PrivacyPolicyEU();
    };

    const PrivacyPolicyEU = (): React.JSX.Element => (
      <Link id="privacyPolicy" to="/privacypolicy" className={classes.policy}>
        {t('privacy_policy_header')}
      </Link>
    );
    const PrivacyPolicyNA = (): React.JSX.Element => {
      const href = country === 'CAN' ? t('privacy_policy_link_canada') : t('privacy_policy_link');
      const privacyText = country === 'CAN' ? t('privacy_policy_canada') : "Privacy Policy";
      return <a id="privacyPolicy" href={href} target="_blank" rel="noreferrer" className={classes.policy}>{privacyText}</a>;
    };

    const Copyright = (): React.JSX.Element => {
      const copyright = country === "CAN" ? 'copyright_CAN' : 'copyright';
      return (
        <>
        {t(copyright, { year: new Date().getUTCFullYear() })}
        </>
      );
    };

    return (
      <Typography component="nav" variant="body2" className={classes.footerNav}>
        <Copyright/>
        <span>
          <PrivacyPolicyLink />
          <CookiePolicyLink/>
        </span>
      </Typography>
    );
  };

  function getCookiePolicyPageComponent() {
    return <CookiePolicyPage {...props} destinationStr={destinationString} />;
  }

  function getCookiePreferencesComponent() {
    return <ConnectedGDPRCookiePreferences {...props} destinationStr={destinationString} />;
  }

  function getPrivacyPolicyComponent() {
    return <CustomerFlowPrivacyPolicyInfo {...props} destinationStr={destinationString} />;
  }

  const MainSection = (): React.JSX.Element => {
    return (
      <main className={classes.centralContent}>
        <Switch>
          <Route path="/register" component={ConnectedRegistration} />
          <Route path="/login" component={ConnectedLogin} />
          <Route path="/applist" component={ConnectedSelectApplication} />
          <Route path="/quiz" component={CustomerEIDContainer} />
          <Route path="/application" component={ConnectedReviewAndSign} />
          <Route path="/submitted" component={ConnectedSubmitted} />
          <Route path="/complete" component={ConnectedApplicationComplete} />
          <Route path="/declined" component={CustomerApplicationDeclined} />
          <Route path="/dataremoved" component={CustomerFlowSensitiveDataRemoved} />
          <Route path="/resetpw" component={ConnectedResetPassword} />
          <Route path="/updateinprogress" component={ConnectedUpdateInProgress} />
          <Route path="/cookiepreferences" component={getCookiePreferencesComponent} />
          <Route path="/cookiepolicy" component={getCookiePolicyPageComponent} />
          <Route path="/privacypolicy" component={getPrivacyPolicyComponent} />
          {defaultDestination}
        </Switch>
      </main>
    );
  };

  return (
    <div className={classes.body}>
      <ConnectedHeader />
      <Modal open={showCookieModal} onClose={toggleCookieModal} className={classes.modal}>
        <CustomerFlowCookieModal />
      </Modal>
      <CustomerAuthenticationGuard>
        <CustomerLockedApplicationStatusCheck>
          <MainSection />
        </CustomerLockedApplicationStatusCheck>
        <FooterSection />
      </CustomerAuthenticationGuard>
    </div>
  );
};

export default CustomerFlow;
