import { Button, Grid, Typography } from "@material-ui/core";
import { StyleRules, WithStyles, withStyles } from "@material-ui/core/styles";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import i18next from "i18next";
import * as _ from "lodash";
import * as React from "react";
import { Trans } from "react-i18next";
import SwipeableViews from "react-swipeable-views";
import DocumentDownloadItem, { DocumentDownloadIcon } from "../../components/customer/DocumentDownloadItem";
import DocumentDownloadList from "../../components/customer/DocumentDownloadList";
import App from "../../models/App";
import { BoardingStatus } from "../../models/BoardingStatus";
import GroupApp from "../../models/GroupApp";

const pageStyles: StyleRules = {
    referenceNumber: {
        color: "#83380c",
    },
};

interface Props extends WithStyles {
    cToken: string;
    apps: App[];
    groupAppInfo?: GroupApp;
    documentsToBeSigned: boolean;
    setHasWetSignNeededDocuments?: (hasWetSignNeededDocuments: boolean) => void;
}

interface State {
    activeStep: number;
    shouldShowSignatureAndReturnRequired: boolean;
}

class DocumentDownloadContainer extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.openDocument = this.openDocument.bind(this);
        this.shouldShowSignatureAndReturnRequired = this.shouldShowSignatureAndReturnRequired.bind(this);

        this.state = {
            activeStep: 0,
            shouldShowSignatureAndReturnRequired: false,
        };
    }

    public render() {
        const { classes, documentsToBeSigned, apps, groupAppInfo, cToken, setHasWetSignNeededDocuments } = this.props;
        const { shouldShowSignatureAndReturnRequired, activeStep } = this.state;
        const signatureAndReturnReqired =
            documentsToBeSigned && shouldShowSignatureAndReturnRequired ? (
                <Typography variant="h6">
                    <Trans>boarding_decision_signatureTitle</Trans>
                </Typography>
            ) : (
                undefined
            );
        const documentComponents = _.map(apps, (app, index) => {
            let referenceNumber;
            let applicationName;
            let merchantId;
            let status;
            if (groupAppInfo) {
                if (groupAppInfo.thirdParty) {
                    applicationName = (
                                        <React.Fragment>
                                            <Trans key={index}>application_name</Trans>&nbsp;:&nbsp;{app.businessName}<br/>
                                        </React.Fragment>
                                       );
                    merchantId = (
                                    <React.Fragment>
                                        <Trans key={index}>application_reference_number</Trans>&nbsp;:&nbsp;{app.merchantId}<br/>
                                    </React.Fragment>
                                );
                    status = (
                                <React.Fragment>
                                    <Trans key={index}>status</Trans>&nbsp;:&nbsp;{this.convertBoardingStatus(app.boardingStatus)}<br/>
                                </React.Fragment>
                             );
                } else {
                    referenceNumber = (
                        <React.Fragment key={index}>
                            <Trans key={"referenceString:" + index} values={{name: app.businessName}}>
                                reference_for_application
                            </Trans>{" "}
                            <span key={"referenceNumber:" + index} className={classes.referenceNumber}>
                            {app.merchantId}
                        </span>
                        </React.Fragment>
                    );
                }
            }
            return (
                <React.Fragment key={index}>
                    {signatureAndReturnReqired}
                    {referenceNumber}
                    {applicationName}
                    {merchantId}
                    {status}
                    <DocumentDownloadList
                        key={"list:" + index}
                        appId={app.id!}
                        cToken={cToken}
                        thirdPartyApp={!!app.thirdParty}
                        documentsToBeSigned={documentsToBeSigned}
                        setHasWetSignNeededDocuments={setHasWetSignNeededDocuments}
                        shouldShowSignatureAndReturnRequired={this.shouldShowSignatureAndReturnRequired}
                    />
                </React.Fragment>
            );
        });

        let downloadZIPLink;
        if (groupAppInfo) {
            downloadZIPLink = `/scwebapi/group/getdocuments?&ctoken=${cToken}&groupId=${groupAppInfo.id}`;
        } else {
            downloadZIPLink = `/scwebapi/apps/getcompressedappdocs?&ctoken=${cToken}&appId=${apps[0].id}`;
        }
        if (this.props.groupAppInfo && this.props.groupAppInfo.thirdParty) {
            downloadZIPLink += "&isThirdParty=true";
        } else {
            downloadZIPLink += "&isThirdParty=false";
        }
        const downloadZIP = !documentsToBeSigned ? (
            <DocumentDownloadItem
                last={true}
                title={i18next.t("get_all_documents_zipped")}
                description={""}
                link={downloadZIPLink}
                onClick={this.openDocument}
                icon={DocumentDownloadIcon.ZIP_FILE}
            />
        ) : (
            undefined
        );

        let navButtons;
        if (groupAppInfo) {
            const maxLength = apps.length;
            navButtons = (
                <Grid item={true} style={{ textAlign: "center" }}>
                    <Button
                        onClick={this.decrement}
                        size="small"
                        variant="contained"
                        style={{ display: "inline-block" }}
                        disabled={activeStep === 0}
                    >
                        <ChevronLeft />
                    </Button>
                    <p style={{ marginLeft: "30px", marginRight: "30px", display: "inline-block" }}>
                        {activeStep + 1}/{maxLength}
                    </p>
                    <Button
                        onClick={this.increment}
                        size="small"
                        variant="contained"
                        style={{ display: "inline-block" }}
                        disabled={activeStep === maxLength - 1}
                    >
                        <ChevronRight />
                    </Button>
                </Grid>
            );
        }

        return (
            <React.Fragment>
                {navButtons}
                <SwipeableViews index={activeStep}>{documentComponents}</SwipeableViews>
                {downloadZIP}
            </React.Fragment>
        );
    }

    public openDocument(link: string) {
        window.open(link, "_blank");
    }

    private shouldShowSignatureAndReturnRequired(showSignatureAndReturnRequired: boolean) {
        this.setState({ shouldShowSignatureAndReturnRequired: showSignatureAndReturnRequired });
    }
    private increment() {
        this.setState({ activeStep: this.state.activeStep + 1 });
    }

    private decrement() {
        this.setState({ activeStep: this.state.activeStep - 1 });
    }

    private convertBoardingStatus(boardingStatus: BoardingStatus) {
        let convertedBoardingStatus = boardingStatus;
        if (boardingStatus === BoardingStatus.ERROR) {
            convertedBoardingStatus = BoardingStatus.INPROGRESS;
        }
        return BoardingStatus[convertedBoardingStatus];
    }
}

export default withStyles(pageStyles)(DocumentDownloadContainer);
