import { Store } from "redux";

let store: Store;

export function getStore(): Store {
    // istanbul ignore next
    if (!store && process.env.NODE_ENV === "development") {
        console.error("Attempted to getStore from StoreService without setting it first.");
    }
    return store;
}

export function setStore(reduxStore: Store): void {
    store = reduxStore;
}
