import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import { StyleRules, withStyles, WithStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Trans } from "react-i18next";

const styles: StyleRules = {
    pageContent: {
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column",
        marginTop: 150,
    },
    cardContent: {
        padding: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column",
        width: "fit-content",
    },
};

class CustomerFlowCookieModal extends React.Component<WithStyles> {
    constructor(props) {
        super(props);
    }

    public render() {
        const { classes } = this.props;
        return (
            <Card className={classes.pageContent}>
                <CardHeader title={<Trans i18nKey="cookiesDisabledTitle" />} component="h1"/>
                <CardContent className={classes.cardContent}>
                    <Typography variant="body2">
                        <Trans i18nKey="cookiesDisabledbody_1" />
                    </Typography>
                    <Typography variant="body2">
                        <Trans i18nKey="cookiesDisabledbody_2" /> <Trans i18nKey="cookiesDisabledbody_3" />{" "}
                        <Trans i18nKey="cookiesDisabledbody_4" />
                    </Typography>
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(CustomerFlowCookieModal);
