import { CustomerAction } from ".";

export interface ForgotPasswordState {
    pending: boolean;
    emailSent: boolean;
    roleError: boolean;
    userError: boolean;

}

const initialForgotPasswordState: ForgotPasswordState = {
    pending: false,
    emailSent: false,
    roleError: false,
    userError: false
};

export function forgotPasswordReducer(state: ForgotPasswordState = initialForgotPasswordState, action: CustomerAction) {
    switch (action.type) {
        case "FORGOT_PASSWORD_SUBMIT":
            return {
                pending: true,
                emailSent: false,
                roleError: false,
                userError: false
            };

        case "FORGOT_PASSWORD_FINISHED":
            return {
                pending: false,
                emailSent: true,
                roleError: false,
                userError: false
            };

        case "FORGOT_PASSWORD_ROLE_ERROR":
            return {
                pending: false,
                emailSent: false,
                roleError: true,
                userError: false
            };
        case "FORGOT_PASSWORD_USER_ERROR":
                return {
                    pending: false,
                    emailSent: false,
                    roleError: false,
                    userError: true
                };

        default:
            return state;
    }
}
