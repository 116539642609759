import { Button, Typography } from "@material-ui/core";
import i18n from "i18next";
import * as React from "react";
import { Trans, withTranslation } from "react-i18next";

interface Props {
    setUserLanguage: (locale: string) => void;
}

class CustomerFlowLanguageSelector extends React.Component<Props> {
    constructor(props) {
        super(props);

        this.toggleLanguage = this.toggleLanguage.bind(this);
    }

    public render() {
        return (
            <React.Fragment>
                <Typography variant="body2">
                    <Trans i18nKey={"assisted_switch_language_text"} />
                </Typography>
                <Button onClick={this.toggleLanguage}>
                    <Trans i18nKey={"assisted_switch_language_button"} />
                </Button>
            </React.Fragment>
        );
    }

    private toggleLanguage() {
        if (i18n.language === "en") {
            i18n.changeLanguage("fr");
            this.props.setUserLanguage("fr_CA");
        } else {
            i18n.changeLanguage("en");
            this.props.setUserLanguage("en_CA");
        }
    }
}

// The withTranslation HOC is used to make sure language changes trigger the render function.
export default withTranslation()(CustomerFlowLanguageSelector);
