import { combineEpics } from "redux-observable";
import forgotPasswordEpic from "./forgotPasswordEpic";
import { removeAnalyticsCookie, setGDPRMixPanelCookie, setOnlyNecessaryCookies } from "./gdprMixPanelCookie";
import loginEpic from "./loginEpic";
import { loginSuccessNavigator } from "./navigationEpics";
import { setLanguageCookie } from "./setLanguageCookie";

export default combineEpics(loginEpic, loginSuccessNavigator, forgotPasswordEpic,
    setLanguageCookie, setGDPRMixPanelCookie, setOnlyNecessaryCookies, removeAnalyticsCookie);

