interface SelfBoardSettings {
    host?: string;
    tokenKey: string;
    locale: string;
}

let selfBoardSettings: SelfBoardSettings;

function initializeSelfBoardSettings(): SelfBoardSettings {
    let sessionSettings;
    try {
        sessionSettings = window.sessionStorage.getItem("selfBoardSettings") || "{}";
    } catch {
        sessionSettings = "{}";
    }

    const settings = JSON.parse(sessionSettings);

    return (selfBoardSettings = settings);
}

export function getSettings(): SelfBoardSettings {
    return selfBoardSettings || initializeSelfBoardSettings();
}
