import { Observable } from "rxjs";
import App from "../models/App";
import GroupApp from "../models/GroupApp";
import ARHttp from "./ARHttp";

const ONE_MINUTE: number = 60000;
let isPeriodicallyCheckActive: boolean = false;

export interface ShouldShowLockPageResponse {
    packetUpdated: boolean;
}

export interface LockStatusCheckServiceModel {
    onComponent: boolean
}

export function getIsPeriodicallyCheckActive(): boolean {
    return isPeriodicallyCheckActive;
}

export function checkPeriodicallyLockStatus(appId: number, token: string, context: any,
                                            instance: LockStatusCheckServiceModel) {
    if  (instance.onComponent && !isPeriodicallyCheckActive) {
        isPeriodicallyCheckActive = true;
        checkLockStatus(appId, token).subscribe(
            (response: ShouldShowLockPageResponse) => {
                const path = context.props.location.pathname;
                const history = context.props.history;
                resolveLockStatus(response, path, history);
            },
            () => {
                setTimeout(function() {
                    isPeriodicallyCheckActive = false;
                    checkPeriodicallyLockStatus(appId, token, context, instance);
                }.bind(this), ONE_MINUTE);
            }
        );
    }
}

export function checkLockStatus(appId: number, token: string): Observable<any> {
    return ARHttp({
        method: "POST",
        url: "/aws/shouldshowlockpage",
        body: {
            "thirdPartyAppId": appId,
        },
        headers: {
            "X-XSRF-TOKEN": token,
        },
    });
}

export function resolveLockStatus(response: ShouldShowLockPageResponse, path: string, history: any) {
    if (response.packetUpdated && path !== "/updateinprogress") {
        history.push("/updateinprogress");
    } else if (!response.packetUpdated && path === "/updateinprogress") {
        history.push("/quiz");
    }
}

export function shouldCheckLockStatus(app?: App, groupApp?: GroupApp): boolean {
    return !!(app && app.thirdParty) || !!(groupApp && groupApp.thirdParty);
}
