export interface HttpConfig {
    usePortalSettings: boolean;
}

const httpConfig: HttpConfig = {
    usePortalSettings: false,
};

export function getHttpConfig(): HttpConfig {
    return httpConfig;
}

export function setHttpConfig(usePortalSettings: boolean): void {
    httpConfig.usePortalSettings = usePortalSettings;
}
