import ApplicationState from "./ApplicationState";
import ApplicationType from "./ApplicationType";
import BrandCode from "./BrandCode";
import RegistrationDetailList from "./RegistrationDetailList";
import { SalesPersonContact } from "./SalesPersonContact";

export enum LogicalAppStatus {
    TO_SIGNER = "TO_SIGNER",
    TO_COMPLETER = "TO_COMPLETER",
    EXPIRED = "EXPIRED",
    HOLD = "HOLD",
    HOLD_EXPIRED = "HOLD_EXPIRED",
    PROCESSING = "PROCESSING",
    DECLINED = "DECLINED",
    COMPLETED = "COMPLETED",
    DATA_REMOVED = "DATA_REMOVED",
    ERROR = "ERROR",
}

export default interface App {
    id?: string;
    appState?: ApplicationState;
    decision?: any;
    archived?: boolean;
    sensitiveDataRemoved?: boolean;
    applicationType?: ApplicationType;
    boardingStatus?: any;
    boardingId?: any;
    merchantId?: any;
    merchangeEmailSent?: boolean;
    creationDate?: any;
    modifiedDate?: any;
    appModifiedDate?: any;
    boardingDate?: any;
    boardingStatusDate?: any;
    emailAddress?: string;
    salesId?: string;
    salesPersonid?: number;
    salesAgentName?: string;
    principalName?: string;
    businessName?: string;
    businessNameExtended?: string;
    businessAddress?: string;
    country?: string;
    brand?: BrandCode;
    referrerName?: any;
    storeNumber?: any;
    taxId?: string;
    merchantAppHashValue?: string;
    customerMembershipNumber?: any;
    creditCheckid?: any;
    creditCheckAppRefNo?: any;
    eidQuizStatus?: any;
    eCommPct?: string;
    motoPct?: string;
    cardPresentPct?: string;
    holdAfterSignature?: boolean;
    signatureTimestamp?: any;
    subjurisdictionDescription?: any;
    partialApp?: boolean;
    wetSignFinalized?: boolean;
    clonedFromid?: any;
    errorText?: any;
    pendText?: any;
    underwriterNotes?: any;
    underwriterContacts?: any;
    registrationDetailList?: RegistrationDetailList[];
    allSignatureCaptured?: boolean;
    allAccountCreated?: boolean;
    salesforceAppid?: any;
    thirdParty?: boolean;
    thirdPartyStandalone?: boolean;
    salesPersonContact?: SalesPersonContact;
    ownershipType?: string;

    logicalStatus: LogicalAppStatus;
}

export function isApp(app: any): app is App {
    return "appState" in app;
}

export function cardSplit(cardPresentPct, motoPct, eCommPct): string {
    if (cardPresentPct === "100") {
        return "card_present";
    } else if (motoPct === "100") {
        return "applications.moto";
    } else if (eCommPct === "100") {
        return "internet";
    } else {
        return "omniCommerce";
    }
}
