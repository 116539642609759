import { connect } from "react-redux";
import CustomerFlowHeader from "../../components/customer/CustomerFlowHeader";
import { CustomerAppState } from "../../reducers/customer_ui";
import { getBrandedLogo } from "../../services/brandImageSelector";

const mapStateToProps = (state: CustomerAppState) => {
    const { brandCode, country, subJurisdictionCode } = state.JurisdictionInfo;
    return {
        brandLogo: getBrandedLogo(country, brandCode, subJurisdictionCode),
        country: state.JurisdictionInfo.country,
    };
};

export default connect(mapStateToProps)(CustomerFlowHeader);
