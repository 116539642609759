import { connect } from "react-redux";
import SelectApplication from "../../components/customer/CustomerFlowSelectApplication";
import App from "../../models/App";
import GroupApp from "../../models/GroupApp";
import { SalesPersonContact } from "../../models/SalesPersonContact";
import { CustomerAppState } from "../../reducers/customer_ui";

const mapStateToProps = (state: CustomerAppState) => ({
    cToken: state.auth.ctoken,
    userLanguage: state.rest.userLanguage,
    country: state.JurisdictionInfo.country,
    user: state.auth.username,
    skipToApp: state.rest.skipToApp ? state.rest.appId : undefined,
});

const mapDispatchToProps = (dispatch) => {
    return {
        selectApplication: (app: App | GroupApp) => {
            dispatch({ type: "LOAD_APP", payload: app });
        },
        loadJurisdictionInfo: (jurisdictionInfo) => {
            dispatch({ type: "SET_JURISDICTION_VALUES", payload: jurisdictionInfo });
        },
        clearSkipToApp: () => {
            dispatch({ type: "CLEAR_SKIP_TO_APP" });
        },
        appListSize: (appsListedSize: number) => {
            dispatch({ type: "LOAD_APP_SIZE", payload: appsListedSize });
        },
        clearSelectedApplication: () => {
            dispatch({ type: "CLEAR_SELECTED_APPLICATION" });
        },
        setIsForThirdPartyAndSalesContact: (thirdParty: boolean, salesPersonContact?: SalesPersonContact) => {
            dispatch({type: "SET_THIRD_PARTY_AND_SALES_CONTACT", payload: {
                    thirdParty,
                    salesPersonContact,
                }});
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectApplication);
