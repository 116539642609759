import { Card, CardContent, CardHeader, CircularProgress } from "@material-ui/core";
import * as React from "react";
import { Trans } from "react-i18next";

export default class SignaturePageLoading extends React.Component {
    public render() {

        const documentLoadingHeaderElem = <Trans>document_signature_item_loading</Trans>;

        return (
            <Card>
                <CardHeader title={documentLoadingHeaderElem} role="heading" aria-level={1} />
                <CardContent style={{ textAlign: "center" }}>
                    <CircularProgress variant="indeterminate" />
                </CardContent>
            </Card>
        );
    }
}
