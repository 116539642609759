import { StyledComponentProps, withStyles, StyleRules } from "@material-ui/core/styles";
import {Typography } from "@material-ui/core";
import * as React from "react";
import { Component } from "react";
import { Trans } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { connect } from "react-redux";
import { CustomerAppState } from "../../reducers/customer_ui";
import ARHttp from "../../services/ARHttp";

const styles: StyleRules = {
    disclosure: {
        margin: "5px auto",
        textAlign: "justify",
        fontSize: "x-small",
    },
};

interface StateProps {
    brandCode?: string;
    referrerName?: string;
    country?: string;
    userLanguage?: string;
}

interface Props extends StateProps {
    dataRetrieved?: boolean;
    disclosure?: string;
}

interface State {
    dataRetrieved?: boolean;
    disclosure?: string;
}

function mapStateToProps(state: CustomerAppState): StateProps {
    return {
        brandCode: state.JurisdictionInfo.brandCode,
        referrerName: state.JurisdictionInfo.referrerName,
        country: state.JurisdictionInfo.country,
        userLanguage: state.rest.userLanguage,
    };
}


class CustomerFlowDisclosure extends Component<Props & StyledComponentProps, State, {}> {
    public hasBeenMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            dataRetrieved: false,
            disclosure: '',
        };
    }

    public componentDidMount() {
        this.hasBeenMounted = true;
        ARHttp({
            method: "POST",
            url: "/scwebapi/getScarecrowProperty",
            body: {
                brandCode: this.props.brandCode,
                countryCode: this.props.country,
                referrerName: this.props.referrerName,
                propertyName: "DOCUMENT_FOOTER",
                userLanguage: this.convertUserLanguageToLanguageTag(this.props.userLanguage),
            },
        })
        .pipe(
            catchError((err) => of(err)),
        )
        .subscribe((res) => {
            if (this.hasBeenMounted) {
                this.setState({
                    dataRetrieved: true,
                    disclosure: res.scarecrowProperty,
                });
            }
        });
    }

    public componentWillUnmount() {
        this.hasBeenMounted = false;
    }

    public render() {
        let disclosure;
        const classes = this.props.classes!;

        function renderText(props) {
            return <>{props.children}</>;
        }

        if (this.state.dataRetrieved && this.state.disclosure) {
            return (
                <React.Fragment>
                    <ReactMarkdown className={classes.disclosure}
                        source={this.state.disclosure}
                        renderers={{ text: renderText }} />
                </React.Fragment>
            );
        } else {
            disclosure = this.getDisclosure(this.props.country);
            return (
                <React.Fragment>
                    <Typography variant="body1" className={classes.disclosure}>
                        <Trans i18nKey={disclosure} />
                    </Typography>
                </React.Fragment>
            );
        }
    }

    private getDisclosure(countryCode): string {
        switch (countryCode) {
            case "USA":
            case "CAN":
                return " ";
            case "FIN":
                return 'disclosureFNL';
            case "IRL":
                return 'disclosureIRL';
            default:
                return 'disclosure';
        }
    }

    private convertUserLanguageToLanguageTag(language): string {
        if (language) {
            const languageTag: string = language.substring(0, language.indexOf("_"));
            return languageTag;
        }
        return "";
    }
}

export default connect(mapStateToProps)(withStyles(styles)(CustomerFlowDisclosure) as any);
