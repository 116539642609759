import { CircularProgress, Snackbar } from "@material-ui/core";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { fromEvent, merge } from "rxjs";
import { CustomerAppState } from "../../reducers/customer_ui";
import ARHttp from "../../services/ARHttp";
import { initializeSessionExpiry, SessionStatusCode } from "../../services/SessionExpiryService";

const styles = (theme) => createStyles({
    centralContent: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        maxWidth: "960px",
        margin: "0 auto",
        marginTop: "16px",
    },
    ["@media (max-width: 766px)"]: {
        modalContainer: {
            position: "absolute",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            outline: "none",
            margin: "16px",
        },
    },
    ["@media (min-width: 767px)"]: {
        modalContainer: {
            position: "absolute",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            outline: "none",
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
        },
    },
});

const mapStateToProps = (state: CustomerAppState) => {
    return {
        authTestCompleted: !state.auth.initialAttemptPending,
        isLoggedIn: state.auth.loggedIn,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        logout: () => {
            dispatch({
                type: "EXPIRE_SESSION",
            });
        },
    };
};

interface Props extends WithStyles {
    authTestCompleted: boolean;
    isLoggedIn: boolean;
    logout: () => void;
}

interface State {
    sessionRunning: boolean;
    warningOpen: boolean;
}

class CustomerAuthenticationGuard extends React.Component<Props & RouteComponentProps, State> {
    constructor(props) {
        super(props);

        this.state = {
            sessionRunning: false,
            warningOpen: false,
        };
    }

    public componentDidUpdate() {
        const { isLoggedIn, logout, history } = this.props;
        const { sessionRunning } = this.state;
        if (isLoggedIn && !sessionRunning) {
            initializeSessionExpiry(
                10 * 60,
                this.touchSession,
                merge(...["mousemove", "touch", "keypress"].map((e) => fromEvent(document, e))),
                60,
                60,
            ).subscribe(
                (result) => {
                    if (result.statusCode === SessionStatusCode.SESSION_ENDING_SOON) {
                        this.setState({
                            warningOpen: true,
                        });
                    }
                    if (result.statusCode === SessionStatusCode.SESSION_RENEWED) {
                        this.setState({
                            warningOpen: false,
                        });
                    }
                },
                undefined,
                () => {
                    logout();
                    this.setState({
                        sessionRunning: false,
                        warningOpen: false,
                    });
                    history.push("/login");
                },
            );

            this.setState({
                sessionRunning: true,
            });
        }
    }

    public render() {
        const { classes, authTestCompleted, children } = this.props!;
        const { warningOpen } = this.state;
        if (!authTestCompleted) {
            return (
                <div className={classes.centralContent}>
                    <CircularProgress variant="indeterminate" />
                </div>
            );
        }

        return (
            <React.Fragment>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    ContentProps={{ className: classes.snackBar }}
                    open={warningOpen}
                    message={<Trans i18nKey={"session_expiring_body"} />}
                />
                {children}
            </React.Fragment>
        );
    }

    private touchSession() {
        return ARHttp({
            method: "POST",
            url: "/aws/env/touch",
        });
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(withStyles(styles)(CustomerAuthenticationGuard) as any));
