enum OwnershipType {
    SOLE_TRADER = "SOLE_TRADER",
    OTHER = "OTHER",
    PARTNERSHIP = "PARTNERSHIP",
    DISREGARDED = "DISREGARDED",
    LIMITED_COMPANY = "LIMITED_COMPANY",
    C_CORPORATION_PRIVATE_COMPANY = "C_CORPORATION_PRIVATE_COMPANY",
    LIMITED_COMPANY_DISREGARDED_ENTITY = "LIMITED_COMPANY_DISREGARDED_ENTITY",
    SUB_S_CORPORATION = "SUB_S_CORPORATION",
    LIMITED_COMPANY_CORPORATION = "LIMITED_COMPANY_CORPORATION",
    LIMITED_LIABILITY_PARTNERSHIP = "LIMITED_LIBABILITY_PARTNERSHIP",
    CHARITY = "CHARITY",
    LIMITED_PARTNERSHIP = "LIMITED_PARTNERSHIP",
    GENERAL_PARTNERSHIP = "GENERAL_PARTNERSHIP",
    FOUNDATION = "FOUNDATION",
    CLUB = "CLUB",
    LISTED_COMPANY = "LISTED_COMPANY",
    PUBLIC_LIMITED_COMPANY = "PUBLIC_LIMITED_COMPANY",
    POLAND_PARTNERSHIP = "POLAND_PARTNERSHIP",
}

export function isOwnershipTaxExempt(ownershipType: OwnershipType | undefined) {
    return ownershipType === OwnershipType.CLUB || ownershipType === OwnershipType.FOUNDATION || ownershipType === OwnershipType.CHARITY;
}

export function convertToScarecrowOwnershipType(ownershipType: OwnershipType) {
    switch (ownershipType) {
        case OwnershipType.LIMITED_COMPANY_DISREGARDED_ENTITY:
            return OwnershipType.LIMITED_COMPANY;
        case OwnershipType.LIMITED_COMPANY_CORPORATION:
            return OwnershipType.LIMITED_COMPANY;
        case OwnershipType.POLAND_PARTNERSHIP:
            return OwnershipType.PARTNERSHIP;
        default:
            return ownershipType;
    }
}

export default OwnershipType;
