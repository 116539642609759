import { Action } from "../../actions/counterActions";
import App, { isApp } from "../../models/App";
import { AuthDestination } from "../../models/AuthDestination";
import { CookiePolicy } from "../../models/CookiePolicy";
import { DocumentSignatureType } from "../../models/DocumentType";
import GroupApp, { isGroupApp } from "../../models/GroupApp";
import { Region } from "../../models/Jurisdiction";
import { SalesPersonContact } from "../../models/SalesPersonContact";

export interface CustomerFlowState {
    brand?: string;
    cookiesEnabled: boolean;
    gdprCookiesPolicyEnabled: boolean;
    showCookieModal: boolean;
    showContactUsModal: boolean;
    requiredSignatures: DocumentSignatureType[];
    faceToFace?: string;
    createAccountString?: string;
    isType2Verification?: boolean;
    userLanguage?: string;
    confirmationCode?: string;
    appId?: string;
    language?: string;
    forgotPasswordModal?: boolean;
    loginSubmit?: boolean;
    multisignPending?: boolean;
    app?: App;
    groupApp?: GroupApp;
    destination: AuthDestination;
    unlockAccountSuccess?: string;
    resetPasswordSuccess?: boolean;
    skipToApp: boolean;
    country?: any;
    region?: Region;
    appListSize?: number;
    isForThirdParty: boolean;
    isThirdPartyStandalone: boolean;
    salesPersonContact?: SalesPersonContact;
    cookiePolicy?: CookiePolicy
}

const initialState: CustomerFlowState = {
    cookiesEnabled: false,
    gdprCookiesPolicyEnabled: false,
    showCookieModal: false,
    showContactUsModal: false,
    forgotPasswordModal: false,
    multisignPending: false,
    requiredSignatures: [],
    destination: AuthDestination.LOGIN,
    skipToApp: false,
    isForThirdParty: false,
    isThirdPartyStandalone: false,
};

export default function customerFlowReducer(state: CustomerFlowState = initialState, action: Action): CustomerFlowState {
    switch (action.type) {
        case "SET_COOKIES":
            return {
                ...state,
                cookiesEnabled: action.payload,
            };
        case "SET_GDPR_COOKIES":
            return {
                ...state,
                gdprCookiesPolicyEnabled: action.payload,
            };
        case "SET_ONLY_NECESSARY_COOKIES":
            return {
                ...state,
                gdprCookiesPolicyEnabled: action.payload,
            };
        case "TOGGLE_COOKIE_MODAL":
            return {
                ...state,
                showCookieModal: !state.showCookieModal,
            };
        case "SET_CREATE_ACCOUNT_STRING":
            return {
                ...state,
                createAccountString: action.payload,
            };
        case "SET_TYPE_2_VERIFICATION":
            return {
                ...state,
                isType2Verification: action.payload,
            };

        case "SET_USER_LANGUAGE":
            return {
                ...state,
                userLanguage: action.payload,
            };

        case "SET_CONFIRMATION_CODE":
            return {
                ...state,
                confirmationCode: action.payload,
            };

        case "LOAD_APP":
            if (isApp(action.payload)) {
                let multisign = false;
                if (action.payload && action.payload.registrationDetailList && action.payload.registrationDetailList.length > 1) {
                    multisign = true;
                }
                return {
                    ...state,
                    appId: action.payload.id,
                    app: action.payload,
                    groupApp: undefined,
                    multisignPending: multisign,
                };
            } else if (isGroupApp(action.payload)) {
                let multisign = false;
                if (action.payload && action.payload.registrationDetailList && action.payload.registrationDetailList.length > 1) {
                    multisign = true;
                }
                return {
                    ...state,
                    appId: action.payload.id,
                    groupApp: action.payload,
                    app: undefined,
                    multisignPending: multisign,
                };
            } else {
                return state;
            }

        case "ADD_REQUIRED_SIGNATURE": {
            const newSignatures = state.requiredSignatures;
            newSignatures.push(action.payload);
            return {
                ...state,
                requiredSignatures: newSignatures,
            };
        }

        case "SET_FACE_TO_FACE":
            return {
                ...state,
                faceToFace: action.payload,
            };

        case "SET_DESTINATION":
            return {
                ...state,
                destination: action.payload,
            };

        case "SET_UNLOCK_SUCCESS_MESSAGE":
            return {
                ...state,
                unlockAccountSuccess: action.payload,
            };

        case "CLEAR_UNLOCK_STATUS":
            return {
                ...state,
                unlockAccountSuccess: undefined,
            };

        case "SET_APPLICATION_ID":
            return {
                ...state,
                appId: action.payload,
            };

        case "SET_RESET_PASSWORD_SUCCESS":
            return {
                ...state,
                resetPasswordSuccess: action.payload,
            };
        case "TOGGLE_CONTACT_US_MODAL":
            return {
                ...state,
                showContactUsModal: !state.showContactUsModal,
            };

        case "SET_SKIP_TO_APP":
            return {
                ...state,
                skipToApp: true,
            };

        case "CLEAR_SKIP_TO_APP":
            return {
                ...state,
                destination: AuthDestination.APPLIST,
                skipToApp: false,
            };
        case "LOAD_APP_SIZE":
            return {
                ...state,
                appListSize: action.payload,
            };
        case "CLEAR_SELECTED_APPLICATION":
            return {
                ...state,
                app: undefined,
                groupApp: undefined,
            };
        case "SET_THIRD_PARTY_AND_SALES_CONTACT":
            return {
                ...state,
                isForThirdParty:  action.payload.thirdParty,
                salesPersonContact: action.payload.salesPersonContact,
            };
        case "SET_COOKIE_POLICY":
            return {
                ...state,
                cookiePolicy: action.payload,
                gdprCookiesPolicyEnabled: action.payload.essential,
            };

        default:
            return state;
    }
}
