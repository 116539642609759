import { Button, Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { StyledComponentProps, StyleRules, withStyles } from "@material-ui/core/styles";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import i18next from "i18next";
import * as React from "react";
import { Component } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { CustomerAppState } from "../../reducers/customer_ui";

const styles: StyleRules = {
    cardContent: {
        marginTop: "16px",
        alignItems: "center",
        flexDirection: "column",
        display: "flex",
    },
    link: {
        color: "#428bca",
        textDecoration: "none",
        "&:hover": {
            color: "#2a6496",
            textDecoration: "underline",
        },
    },
};

const mapStateToProps = (state: CustomerAppState) => {
    return {
        country: state.JurisdictionInfo.country,
        tradingName: state.rest.app ? state.rest.app.businessNameExtended : state.rest.groupApp!.appInfo.businessNameExtended,
        supportEmail: "queries@elavon.com",
        supportPhoneNumber: "1-877-326-7989",
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        clearSkipToApp: () => {
            dispatch({ type: "CLEAR_SKIP_TO_APP"});
        },
    };
};


interface Props {
    country?: string;
    tradingName: string;
    supportEmail: string;
    supportPhoneNumber: string;
    clearSkipToApp: () => void;
}

class CustomerApplicationDeclinedPage extends Component<Props & StyledComponentProps & RouteComponentProps> {
    constructor(props) {
        super(props);
        this.navigateToAppList = this.navigateToAppList.bind(this);
    }
    public componentDidMount() {
        window.document.title = i18next.t("customer_ui_declined");
    }

    public render() {
        const classes = this.props.classes!;
        return (
            <Card style={{ padding: "24px" ,  marginTop: "32px" }}>
                {this.props.country == 'BEL' || this.props.country == 'LUX' ?
                    <CardHeader title={i18next.t("header_declined_bel_lux")} style={{textAlign: "center"}} component="h1"/> :
                    <CardHeader title={i18next.t("Declined")} style={{textAlign: "center"}} component="h1"/>
                }
                
                <CardContent>
                    <Typography align="left" variant="subtitle1">
                        <Trans values={{ dbaName: this.props.tradingName }}>
                            {this.props.country == 'CAN' ? "declined_body_1_can" : "declined_body_1"}
                        </Trans>
                    </Typography>
                    <br />
                    <Typography align="left" variant="body2">
                        <Trans
                            values={{ supportEmail: this.props.supportEmail, supportPhoneNumber: this.props.supportPhoneNumber }}
                            components={[
                                // eslint-disable-next-line jsx-a11y/anchor-has-content
                                <a href={`mailto:${this.props.supportEmail}`} key="a1" className={classes.link}/>,
                            ]}>
                            {this.props.country == 'CAN' ? "declined_body_2_can" : "declined_body_2"}
                        </Trans>
                    </Typography>
                    <div className={classes.cardContent}>
                        <FormControl>
                            <Button variant="contained" color="primary" type="submit" onClick={this.navigateToAppList}>
                                <ChevronLeft />
                                {this.props.country == 'CAN' ?
                                    <Trans>Return_to_Application_List_can</Trans> :
                                    <Trans>Return_to_Application_List</Trans>
                                }
                            </Button>
                        </FormControl>
                    </div>
                </CardContent>
            </Card>
        );
    }

    private navigateToAppList() {
        this.props.clearSkipToApp();
        this.props.history.push("/applist");
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerApplicationDeclinedPage)) as any);
