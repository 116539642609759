import { CircularProgress, FormControlLabel } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import i18next from "i18next";
import * as _ from "lodash";
import * as React from "react";
import { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import CustomerFlowSignatureAffirmation from "../../components/customer/CustomerFlowSignatureAffirmation";
import AgreementPresenter from "../../components/signature/AgreementPresenter";
import SignatureAffirmation from "../../components/signature/SignatureAffirmation";
import { DocumentType, getDocumentDisplayString } from "../../models/DocumentType";
import { AppState } from "../../reducers";
import ARHttp from "../../services/ARHttp";

interface DocumentInfo {
    documentType?: DocumentType;
    cardinalNumber?: number;
}

interface Props {
    document: DocumentType;
    signatureStyle: number;
    fontNames: string[];
    signed: boolean;
    fullName: string;
    getDocumentUrl: string;
    applicationCountry?: string;
    onSign?: () => void;
    onError: () => void;
    isPartnerSignatureFlow?: boolean;
    isCustomerSignatureFlow?: boolean;
    appId?: number;
    cToken?: string;
    documentKey: string;
    isGroupApp?: boolean;
    isThirdPartyApp?: boolean;
    signAllCheckbox: (document: DocumentType, cardinalNumber?: number) => void;
    agreementsAllSigned?: DocumentInfo[];
    groupAppSize?: number | undefined;
    cardinalNumber?: number | undefined;
    disableSigning: boolean;
    disableSignAll: (document: DocumentType) => boolean;
}

interface State {
    documentHtml: string;
    allInitialsChecked: boolean;
    hasInitials: boolean;
}

class SignableDocumentContainer extends Component<Props, State> {
    private documentStringKey: string;
    private signDate: Date;
    constructor(props) {
        super(props);

        this.documentStringKey = getDocumentDisplayString(props.document);
        this.handleAllInitialsChecked = this.handleAllInitialsChecked.bind(this);
        this.checkIfhasInitials = this.checkIfhasInitials.bind(this);

        this.state = {
            documentHtml: "",
            allInitialsChecked: false,
            hasInitials: true,
        };
    }

    public shouldComponentUpdate(nextProps: Props) {
        if (nextProps.signed) {
            this.signDate = new Date(Date.now());
        }
        return true;
    }

    public componentDidMount() {
        if (this.props.isCustomerSignatureFlow) {
            this.getScarecrowDocument();
        } else {
            this.getAbbeyRoadDocument();
        }
    }

    public render() {
        let showSignAllBox;

        const signAllDisabled = this.props.disableSignAll(this.props.document);
        const allSigned = _.some(this.props.agreementsAllSigned,
            {documentType: this.props.document, cardinalNumber: this.props.cardinalNumber});

        if (this.props.isGroupApp && (this.props.groupAppSize && this.props.groupAppSize > 1) && this.props.signed && !signAllDisabled) {
            showSignAllBox = (
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={allSigned}
                            checked={allSigned}
                            onChange={this.checkAllSignedBox.bind(this)}
                        />
                    }
                    label={i18next.t("group_check_all")}
                />
            );
        }
        const loaded = (
            <Fragment>
                <AgreementPresenter
                    handleAllInitialsChecked={this.handleAllInitialsChecked()}
                    documentHtml={this.state.documentHtml}
                    checkIfHasInitials={this.checkIfhasInitials}
                />
                <br />
                {this.getSignatureForm()}
                {showSignAllBox}
            </Fragment>
        );

        return (
            <Fragment>
                <h2>
                    <Trans i18nKey={this.documentStringKey} />
                </h2>
                {this.state.documentHtml ? loaded : <CircularProgress variant="indeterminate" />}
            </Fragment>
        );
    }

    private getSignatureForm() {
        if (this.props.isThirdPartyApp || this.props.isPartnerSignatureFlow) {
            return (
                <CustomerFlowSignatureAffirmation
                    fontSelection={this.props.signatureStyle}
                    fontNames={this.props.fontNames}
                    signed={this.props.signed}
                    dateSigned={new Date(Date.now())}
                    fullName={this.props.fullName}
                    onSign={this.props.onSign}
                    applicationCountry={this.props.applicationCountry}
                    allInitialsChecked={false}
                    hasInitials={false}
                    readyToSign={true}
                    disabled={this.props.disableSigning}
                />
            );
        } else {
            return (
                <SignatureAffirmation
                    fontSelection={this.props.signatureStyle}
                    fontNames={this.props.fontNames}
                    signed={this.props.signed}
                    dateSigned={this.signDate}
                    documentKey={this.props.documentKey}
                    fullName={this.props.fullName}
                    onSign={this.props.onSign}
                    applicationCountry={this.props.applicationCountry}
                    allInitialsChecked={this.state.allInitialsChecked}
                    hasInitials={this.state.hasInitials}
                    disabled={this.props.disableSigning}
                />
            );
        }
    }

    private getAbbeyRoadDocument() {
        ARHttp({
            method: "GET",
            // headers: {
            //     "X-XSRF-TOKEN": this.props.cToken,
            // },
            url: (this.props.getDocumentUrl + "?documentType=" + this.props.document +
            (this.props.cardinalNumber ? '&cardinalNumber=' + this.props.cardinalNumber : '')),
            cache: false,
        }).subscribe(
            (data: any) => {
                this.setState({
                    documentHtml:
                        this.props.isPartnerSignatureFlow && data.documents && data.documents[this.props.document]
                            ? data.documents[this.props.document]
                            : data.html,
                });
            },
            () => {
                this.props.onError();
            },
        );
    }

    private getScarecrowDocument() {
        ARHttp({
            method: "POST",
            url: "/aws/document/getapplicationdocument",
            headers: {
                "X-XSRF-TOKEN": this.props.cToken,
            },
            body: {
                appId: this.props.appId,
                userDocumentType: this.props.document,
                html: true,
                thirdPartyApp: this.props.isThirdPartyApp,
                cardinalNumber: this.props.cardinalNumber,
            },
        }).subscribe(
            (data: any) => {
                this.setState({
                    documentHtml: data.html,
                });
            },
            () => {
                this.props.onError();
            },
        );
    }

    private handleAllInitialsChecked() {
        return (initials: boolean[]) => {
            let allTrue = true;
            if (initials) {
                initials.forEach((initial) => {
                    if (!initial) {
                        allTrue = false;
                    }
                });
            }
            this.setState({
                ...this.state,
                allInitialsChecked: allTrue,
            });
        };
    }

    private checkIfhasInitials(hasInitials: boolean) {
        this.setState({
            ...this.state,
            hasInitials,
        });
    }
    private checkAllSignedBox() {
        this.props.signAllCheckbox(this.props.document, this.props.cardinalNumber);
    }
}

const mapStateToProps = (state: AppState, ownProps) => {
    if (ownProps && ownProps.isCustomerSignatureFlow) {
        return {
            applicationCountry: ownProps.applicationCountry,
        };
    } else {
        return {
            applicationCountry: state.JurisdictionInfo.country,
        };
    }
};

export default connect(
    mapStateToProps,
    undefined,
)(SignableDocumentContainer);
