import { Checkbox, FormControlLabel } from "@material-ui/core";
import * as _ from "lodash";
import * as React from "react";
import { Trans } from "react-i18next";

interface Props {
    labels: string[];
    header: string;
    onChange: (value: boolean) => void;
    checked: boolean;
    required: boolean;
    disabled: boolean;
}

export default class TermCheckbox extends React.Component<Props> {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    public render() {
        const { labels, disabled, checked, header } = this.props;
        const labelElements = _.map(labels, function(label) {
            return (
                <p style={{marginTop: "0px"}} key={label + ""}>
                    <Trans>{label}</Trans>
                </p>
            );
        });

        return (
            <FormControlLabel
                disabled={disabled}
                style={{ alignItems: "start" }}
                control={
                    <Checkbox
                        checked={checked}
                        onChange={this.onChange}
                    />
                }
                label={
                    <div>
                        <h4 style={{marginBottom: "0px", marginTop: "14px"}}>
                            <Trans>{header}</Trans>
                        </h4>
                        {labelElements}
                    </div>
                }
            />
        );
    }

    private onChange(event) {
        this.props.onChange(!this.props.checked);
    }
}
