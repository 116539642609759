import Address from "./Address";

enum AddressType {
    BUSINESS = "BUSINESS",
    LEGAL = "LEGAL",
    SHIPPING = "SHIPPING",
    MAILING = "MAILING",
    PRINCIPAL = "PRINCIPAL",
    PREVIOUS = "PREVIOUS",
    STATEMENT = "STATEMENT",
}
export default AddressType;

export type AddressTypeMap = { [addressType in AddressType]?: Address };
