enum BrandCode {
    ELAVON = "ELAVON",
    AVON = "AVON",
    OPAYO = "OPAYO",
    SEMS_DE = "SEMS_DE",
    KEY_BANK = "KEY_BANK",
    ATHENA_HEALTH = "ATHENA_HEALTH",
    WYNDHAM = "WYNDHAM",
    MSP_USA = "MSP_USA",
    MSP_CANADA = "MSP_CANADA",
    AGENT_BANK = "AGENT_BANK",
    SANTANDER = "SANTANDER",
    BNP = "BNP",
    DIRBILL_USA = "DIRBILL_USA",
    DIRBILL_CANADA = "DIRBILL_CANADA",
    CUTPAY = "CUTPAY",
    SCOOP_USA = "SCOOP_USA",
    SCOOP_CAN = "SCOOP_CAN",
    ENFORE_DEU = "ENFORE_DEU",
    BEST_WESTERN = "BEST_WESTERN",
    PAYIUS = "PAYIUS",
    OPENSOL_SWE = "OPENSOL_SWE",
    UB_ASSISTED = "UB_ASSISTED",
    BOTW_BANKER = "BOTW_BANKER",
}

export default BrandCode;
