import * as _ from "lodash";
import { Store } from "redux";
import { share, switchMap } from "rxjs/operators";
import i18n, { createTranslationObject } from "../../i18n";
import { DocumentSignatureType } from "../../models/DocumentType";
import MerchantApplication from "../../models/MerchantApplication";
import SelfboardApplication from "../../models/SelfBoardApplication";
import ApplicationDocumentService, { UploadDocument } from "../ApplicationDocumentService";
import ARHttp from "../ARHttp";
import { getSettings } from "../Settings";
import { parseState } from "./SelfBoardToScarecrowApplicationConverter";

interface GetAppRequest {
    id?: string;
    email?: string;
}

interface SaveAppRequest {
    emailAddress?: string;
    merchantApplication?: SelfboardApplication;
}

let existingApplication: SelfboardApplication = {};
let merchantAppHashValue: string;
let stateStore: Store;

export function setStore(store: Store) {
    stateStore = store;
}

// Retrieve application from the server and parse into the state store.
export function loadApplication() {
    const getAppBody: GetAppRequest = {};
    let lang = "en";
    if (getSettings().locale) {
        lang = getSettings().locale.substring(0, 2);
    }

    const getAppCall = ARHttp({
        method: "POST",
        url: "/sb/apps/getapp",
        body: getAppBody,
    }).pipe(share());

    getAppCall.subscribe(
        (response: any) => {
            loadStrings(response.app, lang).subscribe(
                (resp: any) => {
                    if (resp.stringProps) {
                        i18n.addResourceBundle(lang, "translation",
                            createTranslationObject(resp.stringProps));
                        i18n.reloadResources();
                    }
                    if (response.appInfo.appState === "DRAFT") {
                        parseApplication(response);
                    } else {
                        const boardingComplete = response.appInfo.boardingStatus === "COMPLETE" ||
                         response.appInfo.boardingStatus === "APPROVED";
                        const appError = _.includes(
                            ["DATASETONE_CREDIT_ERROR", "DATASETTWO_BOARDING_ERROR", "DOCUMENTS_ERROR"],
                            response.appInfo.appState,
                        );
                        stateStore.dispatch({
                            type: "SET_SUBMISSION_STATE",
                            payload: { success: !appError, approved: boardingComplete },
                        });
                        stateStore.dispatch({ type: "APPLICATION_COMPLETE" });
                    }
                },
            );
        },
        (error) => {
            stateStore.dispatch({ type: "SET_ERROR", payload: error });
        },
    );
    return getAppCall;
}

export function loadStrings(merchantApp: MerchantApplication, lang) {
    return ARHttp({
        method: "POST",
        url: "/sb/lookup/buildStringProps",
        body: {
            stringPropParams: {
                applicationType: "SELFBOARD",
                language: lang,
                subJurisdictionCode: merchantApp.subJurisdictionCode,
            },
        },
    });
}

// Parse the state store values and save a selfboardapplication. Will create a new one if the id is absent.
export function saveApplication() {
    const saveAppRequest: SaveAppRequest = {
        merchantApplication: parseState(stateStore, existingApplication),
    };

    const saveCall = ARHttp({
        method: "POST",
        url: "/sb/apps/saveapp",
        body: saveAppRequest,
    }).pipe(share());

    saveCall.subscribe(refreshApplication, (error) => {
        if (error.message !== "Not Allowed") {
            stateStore.dispatch({ type: "SET_ERROR", payload: error });
        } // else eat the error and do nothing.
    });

    return saveCall;
}

export function submitApplication(fontType: string, marketingDataConsentMap?: any, gdprOptInOut?: boolean) {
    return ARHttp({
        method: "POST",
        url: "/sb/documents/submitsignature",
        body: {
            fontType,
        },
    })
    .pipe(
        switchMap(() => {
            return ARHttp({
                method: "POST",
                url: "/sb/documents/submittermcheckboxes",
                body: {
                    marketingDataConsentMap,
                    gdprOptInOut,
                },
            });
        }),
    )
    .pipe(
        switchMap(() => {
            return ARHttp({
                method: "POST",
                url: "/sb/apps/submitapp",
                body: {},
            });
        }),
    );
}

export function submitStandardApplication(
    cToken: string,
    appId: number,
    fontType: string,
    requiredSignatures: DocumentSignatureType[],
    marketingDataConsentMap?: any, marketingOptOut?: boolean, thirdPartyApp?: boolean) {
    if (thirdPartyApp) {
        return ARHttp({
            url: "/scwebapi/apps/submitthirdpartyapp",
            method: "POST",
            headers: {
                "X-XSRF-TOKEN": cToken,
            },
            body: {
                id : appId.toString(),
                optOut: marketingOptOut,
            },
        });
    } else {
    const documentSignatures: UploadDocument[] = _.map(requiredSignatures, function(signature: DocumentSignatureType) {
        return {
            documentType: signature,
            mimeType: "image/svg+xml",
        };
    });

    return ARHttp({
                method: "POST",
                url: "/scwebapi/apps/esigncompletedanduploadattachments",
                headers: {
                    "X-XSRF-TOKEN": cToken,
                },
                body: {
                    id: appId.toString(),
                    documents: documentSignatures,
                    selectedFontName: fontType,
                    signedDate: new Date(),
                    thirdPartyApp,
                    optOut: marketingOptOut,
                    marketingDataConsentMap,
                },
    });
   }
}

export function eSignInitiate( cToken: string, appId: number) {
    return ARHttp({
        method: "POST",
        url: "/scwebapi/apps/esigninitiated",
        headers: {
            "X-XSRF-TOKEN": cToken,
        },
        body: {
            id: appId.toString(),
        },
});
}

export function submitGroupApplication(
    cToken: string,
    groupId: string,
    groupAppIds: number[],
    selectedFontName: string,
    requiredSignatures: DocumentSignatureType[],
    thirdPartyApp?: boolean, optOut?: boolean) {
        if (thirdPartyApp) {
           return  ARHttp({
                url: "/scwebapi/apps/submitthirdpartygroupapp",
                method: "POST",
                headers: {
                    "X-XSRF-TOKEN": cToken,
                },
                body: {
                    id : groupId,
                    optOut,
                },
            });
        } else {
    const documents: UploadDocument[] = _.map(requiredSignatures, function(signature: DocumentSignatureType) {
        return {
            documentType: signature,
            mimeType: "image/svg+xml",
        };
    });

    return ARHttp({
        method: "POST",
        url: "/scwebapi/apps/uploadgroupsignatures",
        headers: {
            "X-XSRF-TOKEN": cToken,
        },
        body: {
            groupId,
            groupAppIds,
            documents,
            selectedFontName,
            signedDate: new Date(),
        },
    })
    .pipe(
        switchMap(() => {
            return ARHttp({
                method: "POST",
                url: "/scwebapi/group/executestates",
                headers: {
                    "X-XSRF-TOKEN": cToken,
                },
                body: {
                    id: groupId,
                },
            });
        }),
    );
}
}

export function clearExistingApplication() {
    existingApplication = {};
}

// Passes a scarecrow application through to the reducers.
function parseApplication(response) {
    ApplicationDocumentService.getSupportingDocuments();

    merchantAppHashValue = response.appInfo.merchantAppHashValue;
    const application = response.app;
    existingApplication = application;
    stateStore.dispatch({ type: "LOAD_APPLICATION", payload: application });
}

export function fieldAutomationLoadApp(response) {
    if (response.fieldAutoReply && response.fieldAutoReply.applied === true) {
        merchantAppHashValue = response.merchantAppHashValue;
        const application = response.app;
        existingApplication = application;
        stateStore.dispatch({ type: "MERCHANT_HASH_VALUE", payload: response.merchantAppHashValue });
        stateStore.dispatch({ type: "LOAD_APPLICATION", payload: application });
    } else {
        stateStore.dispatch({ type: "FIELD_AUTOMATION_FAILURE" });
    }
}

export function bundleLoadApp(response) {
    merchantAppHashValue = response.merchantAppHashValue;
    const application = response.app;
    existingApplication = application;
    stateStore.dispatch({ type: "MERCHANT_HASH_VALUE", payload: response.merchantAppHashValue });
    stateStore.dispatch({ type: "LOAD_EQUIPMENT", payload: application });
}

function refreshApplication(response) {
    merchantAppHashValue = response.merchantAppHashValue;
    const application = response.app;
    existingApplication = application;
}

export function getMerchantHashvalue() {
    return merchantAppHashValue;
}
