import { connect } from "react-redux";
import CustomerFlowGDPRCookieBar from "../../components/customer/CustomerFlowGDPRCookieBar";
import { CustomerAppState } from "../../reducers/customer_ui";

const mapStateToProps = (state: CustomerAppState) => ({
    cookiePolicy: state.rest.cookiePolicy,
});

const mapDispatchToProps = (dispatch) => {
    return {
        acceptCookies: () => {
            dispatch({ type: "SET_GDPR_COOKIES", payload: true });
        },
        removeAnalyticsCookie: () => {
            dispatch({type: "REMOVE_ANALYTICS_COOKIE"});
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerFlowGDPRCookieBar);
