import { connect } from "react-redux";
import { Dispatch } from "redux";
import SubmissionPage from "../../components/customer/CustomerFlowSubmitted";
import { CustomerAppState } from "../../reducers/customer_ui";

const mapStateToProps = (state: CustomerAppState) => ({
    multisignPending: state.rest.multisignPending,
    email: state.rest.app ? state.rest.app.emailAddress : state.rest.groupApp!.appInfo.emailAddress,
    token: state.auth.ctoken,
    appId: state.rest.appId,
    thirdParty: getThirdPartyFlag(state),
    thirdPartyStandalone: getThirdPartyStandalonFlag(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadJurisdictionInfo: (jurisdictionInfo) => {
            dispatch({ type: "SET_JURISDICTION_VALUES", payload: jurisdictionInfo });
        },
        clearSkipToApp: () => {
            dispatch({ type: "CLEAR_SKIP_TO_APP" });
        },
    };
};

function getThirdPartyFlag(state: CustomerAppState) {
    if (state.rest.app) {
        return state.rest.app.thirdParty;
    } else if (state.rest.groupApp && state.rest.groupApp.appInfo) {
        return state.rest.groupApp.appInfo.thirdParty;
    } else {
        return false;
    }
}

function getThirdPartyStandalonFlag(state: CustomerAppState) {
    if (state.rest.app) {
        return state.rest.app.thirdPartyStandalone;
    } else if (state.rest.groupApp && state.rest.groupApp.appInfo) {
        return state.rest.groupApp.appInfo.thirdPartyStandalone;
    }
    return false;
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SubmissionPage);
