export enum Region {
    NA = "NA",
    EU = "EU",
}

export interface Jurisdiction {
    region: Region;
    brandCode: string;
    countryCode: string;
    referrerName: string;
    subJurisdictionId?: number;
    subJurisdictionCode?: string;
}
