import { ofType } from "redux-observable";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { CustomerAction, CustomerAppState } from "../../reducers/customer_ui";
import ARHttp from "../../services/ARHttp";

export default function(action$: Observable<CustomerAction>, state$: Observable<CustomerAppState>): Observable<CustomerAction> {
    return action$.pipe(
        ofType("FORGOT_PASSWORD_SUBMIT"),
        withLatestFrom(
            state$.pipe(
                map((state: CustomerAppState) => {
                    return {
                        referrer: state.JurisdictionInfo.referrerName,
                        languageTag: state.rest.userLanguage,
                        applicationCountry: state.JurisdictionInfo.country,
                        subJurisdictionCode: state.JurisdictionInfo.subJurisdictionCode,
                        brand: state.JurisdictionInfo.brandCode,
                    };
                }),
            ),
        ),
        switchMap(([action, params]) => {
            return ARHttp({
                method: "POST",
                url: "/scwebapi/forgotpw",
                body: {
                    referrer: params.referrer,
                    emailAddress: action.payload,
                    returnPath: "/assisted-signup",
                    languageTag: params.languageTag,
                    applicationCountry: params.applicationCountry,
                    brand: params.brand,
                    subJurisdictionCode: params.subJurisdictionCode,
                },
            }).pipe(
                map(() => {
                    // On any successful response we say that we sent the email.
                    return {
                        type: "FORGOT_PASSWORD_FINISHED",
                    };                

                }),
                catchError((error:Error) => {
                    if (error.message === 'Invalid Role') {
                        return of ({
                            type: "FORGOT_PASSWORD_ROLE_ERROR",
                        });
                    } else {
                        return of ({
                            type: "FORGOT_PASSWORD_USER_ERROR",
                        });
                    }
                }),
            );
        }),
    );
}
