import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useTranslation } from "react-i18next";
import convertImages from "../../services/ImageCoverter";

const serverError = convertImages(require("../../assets/images/ServerError.png"));

export const useStyles = makeStyles({
    errorItem: {
        alignSelf: "center",
    },
    serverErrorImg: {
        height: "399px",
        maxWidth: "100%",
        background: `url(${serverError})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
    },
});

const DefaultErrorScreen = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const supportEmail = "support@elavon.com";

    return (
        <React.Fragment>
            <Grid item={true} md={6} xs={12} className={classes.errorItem}>
                <div style={{ margin: "100px" }}>
                    <Typography variant="h2">{t("error")}</Typography>
                    <Typography variant="h4">{t("this_page_is_not_available_error")}</Typography>
                    <br />
                    <Typography variant="subtitle1">{t("dont_worry_subheading")}</Typography>
                    <Typography variant="subtitle1">
                        {t("try_again_subheading")} <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                        {t("for_assistance_subheading")}
                    </Typography>
                </div>
            </Grid>
            <Grid item={true} md={6} xs={12}>
                <div style={{ background: serverError }} className={classes.serverErrorImg}>
                    <span style={{ display: "none" }}>{t("server_error")}</span>
                </div>
            </Grid>
        </React.Fragment>
    );
};

export default DefaultErrorScreen;
