import { connect } from "react-redux";
import CustomerFlowDisclosure from "../../components/customer/CustomerFlowDisclosure";
import { CustomerAppState } from "../../reducers/customer_ui";

const mapStateToProps = (state: CustomerAppState) => {
    return {
        country: state.JurisdictionInfo.country,
    };
};

export default connect(mapStateToProps)(CustomerFlowDisclosure);
