import { Observable, of } from "rxjs";
import { catchError, delay, map, startWith, switchMap, tap, throttleTime } from "rxjs/operators";

export enum SessionStatusCode {
    SESSION_RENEWED,
    SESSION_ENDING_SOON,
}

export interface SessionExpiryStatus {
    statusCode: SessionStatusCode;
    details?: any;
}

/**
 * Initializes a session expiry countdown.
 *
 * @export
 * @param {number} timeout time, in seconds, until this session will expire.
 *
 * @param {number} warningDuration the duration, in seconds, from the end of the countdown where the "SESSION_ENDING_SOON"
 * warning is emitted.
 * Default is 60.
 *
 * @param {() => Observable<any>} extend a function to be called when the session is to be extended.
 * If the observable emits any value, it will be deemed to be a success.
 * The value emitted from the extend function is passed along in the details property of the SessionExpiryStatus object.
 * If the observable emits an error, it will be deemed a failure, and the session will immediately end, as if it had timed out.
 *
 * @param {number} extendThrottle time, in seconds, to throttle calls to the extend function.
 * Calls to extend are made on the leading edge of the time window, and are limited to at least extendThrottle seconds apart.
 * Default is 1.
 *
 * @param {Observable<any>} extendEvent$ an observable that will be used to subscribe to trigger session extensions.
 * A common use case is to listen to a list of events against the document, like so:
 * merge(...["mousemove", "touch", "keypress"].map((event) => fromEvent(document, event)))
 *
 * @returns {Observable<SessionExpiryStatus>} returns an observable that will emit a SessionExpiryStatus object when the session is renewed
 * or when the session is about to expire.
 * The returned observable will complete when the session has timed out or otherwise ended.
 */
export function initializeSessionExpiry(
    timeout: number,
    extend: () => Observable<any>,
    extendEvent$: Observable<any>,
    extendThrottle: number = 1,
    warningDuration: number = 60,
): Observable<SessionExpiryStatus> {
    if (warningDuration > timeout) {
        throw new Error("initializeSessionExpiry: warningDuration is longer than the timeout.");
    }
    return new Observable<SessionExpiryStatus>((observer) => {
        const extend$ = extendEvent$.pipe(
            startWith(0),
            throttleTime(extendThrottle * 1000),
            switchMap(() =>
                extend().pipe(
                    map((result) => {
                        observer.next({ statusCode: SessionStatusCode.SESSION_RENEWED, details: result });
                        return true;
                    }),
                    catchError(() => {
                        observer.complete();
                        return of(false);
                    }),
                ),
            ),
        );

        const timeout$ = extend$.pipe(
            switchMap((renewResult) =>
                of(renewResult).pipe(
                    delay((timeout - warningDuration) * 1000),
                    tap(() => {
                        observer.next({ statusCode: SessionStatusCode.SESSION_ENDING_SOON });
                    }),
                    delay(warningDuration * 1000),
                ),
            ),
        );

        timeout$.subscribe(() => {
            observer.complete();
        });
    });
}
