import * as _ from "lodash";
import { Store } from "redux";
import Address from "../../models/Address";
import AddressType from "../../models/AddressType";
import BankAccountTypes from "../../models/BankAccounts";
import BankingInfo from "../../models/BankingInfo";
import BusinessInfo from "../../models/BusinessInfo";
import ContactInfo from "../../models/ContactInfo";
import DateComponents from "../../models/DateComponents";
import FinancialInfo from "../../models/FinancialInfo";
import Identification from "../../models/Identification";
import IDType from "../../models/IDType";
import Month from "../../models/Month";
import Name from "../../models/Name";
import { convertToScarecrowOwnershipType } from "../../models/OwnershipType";
import Person from "../../models/Person";
import PhoneNumber from "../../models/PhoneNumber";
import ScarecrowApplication from "../../models/ScarecrowApplication";
import SelfboardApplication from "../../models/SelfBoardApplication";
import StatesAbreviatedClass, { getCountryFromState } from "../../models/StatesAbreviatedClass";
import UIStateMap from "../../models/UIStatemap";
import { AppState } from "../../reducers";
import { OwnersProfile } from "../../reducers/ownersProfile";
import { applyCountryRules, applyReferrerRules, applyRegionalRules } from "./SelfBoardToScarecrowJurisdictionRules";

// Parses the AppState and puts the data into a SelfboardApplication.
export function parseState(
    stateStore: Store,
    existingApplication,
): SelfboardApplication {
    const state = stateStore.getState() as AppState;
    let scarecrowApplication: ScarecrowApplication = {};
    scarecrowApplication = parseAboutBusinessFormToScareApp(
        state,
        scarecrowApplication,
    );
    scarecrowApplication = parsePersonalProfileToScareApp(
        state,
        scarecrowApplication,
    );
    scarecrowApplication = parseBusinessProfileToScareApp(
        state,
        scarecrowApplication,
    );
    scarecrowApplication = parseBankingInformationFormToState(
        state,
        scarecrowApplication,
    );
    scarecrowApplication = parseAdditionalOwnersFormToState(
        state,
        scarecrowApplication,
    );
    scarecrowApplication = parseCreditUnderwritingToScareApp(
        state,
        scarecrowApplication,
    );
    scarecrowApplication = parseGeographyInfoToScareApp(
        state,
        scarecrowApplication,
    );
    scarecrowApplication = parseCardAcceptanceInfoToSelfBoardApp(
        state,
        scarecrowApplication,
    );
    scarecrowApplication = parsePlaceholderInfoToScareApp(
        state,
        scarecrowApplication,
    );

    let selfBoardApplication: SelfboardApplication = {
        scarecrowApplication,
    };

    selfBoardApplication = parsePlaceholderInfoToSelfBoardApp(
        state,
        selfBoardApplication,
    );
    selfBoardApplication = mergeAboutBusinessFormToMerchantApp(
        state,
        selfBoardApplication,
    );
    selfBoardApplication = configureAddressSameAs(state, selfBoardApplication);

    // NOTE: Any data that is cleared from the selfBoardApplication but was present on the existingApplication MUST be cleared
    // with a null value - NOT using undefined. Empty string will also work if the server permits that value. If a value is
    // undefined, then it will be skipped during the merge and the data present in the existingApplication will take precedence.
    selfBoardApplication = _.mergeWith(
        {},
        existingApplication,
        selfBoardApplication,
        assignWithNuller,
    );
    overrideAdditionalShareholders(scarecrowApplication, selfBoardApplication);
    return applyJurisdictionalConversions(state, selfBoardApplication);
}

const applyJurisdictionalConversions = (
    state: AppState,
    selfBoardApplication: SelfboardApplication,
): SelfboardApplication => {
    let nextSelfBoardApplication = { ...selfBoardApplication };
    nextSelfBoardApplication = applyRegionalRules(
        state,
        nextSelfBoardApplication,
    );
    nextSelfBoardApplication = applyCountryRules(
        state,
        nextSelfBoardApplication,
    );
    nextSelfBoardApplication = applyReferrerRules(
        state,
        nextSelfBoardApplication,
    );
    return nextSelfBoardApplication;
};

// Merging existing application with current does not work properly when a shareholder is deleted, so we have to override that merge.
function overrideAdditionalShareholders(application, selfBoardApplication) {
    selfBoardApplication.scarecrowApplication.additionalShareholders =
        application.additionalShareholders;
}

function assignWithNuller(objValue, srcValue): any {
    if (srcValue === undefined) {
        return null;
    }

    return undefined;
}

export function mergeAboutBusinessFormToMerchantApp(
    { aboutBusinessForm: state }: AppState,
    application: SelfboardApplication,
) {
    if (!application.uiStateMap) {
        application.uiStateMap = {};
    }

    if (state.isUsBusiness !== undefined) {
        application.uiStateMap.isUsBusiness = state.isUsBusiness;
    }

    if (state.businessPercentageQuestion !== undefined) {
        application.uiStateMap.businessPercentageQuestion =
            state.businessPercentageQuestion;
    }

    if (state.businessVatNumberQuestion !== undefined) {
        application.uiStateMap.businessVatNumberQuestion =
            state.businessVatNumberQuestion;
    }

    if (!application.uiStateMap.fieldAutomationData) {
        application.uiStateMap.fieldAutomationData = {};
    }
    application.uiStateMap.fieldAutomationData.showBusinessNameAndAddress =
        state.showBusinessNameAndAddress !== undefined
            ? state.showBusinessNameAndAddress
            : undefined;
    application.uiStateMap.fieldAutomationData.companyName =
        state.fieldAutomationBusinessName !== undefined
            ? state.fieldAutomationBusinessName
            : undefined;
    application.uiStateMap.fieldAutomationData.companyAddress =
        state.fieldAutomationBusinessAddress !== undefined
            ? state.fieldAutomationBusinessAddress
            : undefined;
    application.uiStateMap.fieldAutomationData.companyID =
        state.companyID !== undefined ? state.companyID : undefined;
    application.uiStateMap.fieldAutomationData.showVatIdWarningMessage =
        state.showVatIdWarningMessage !== undefined
            ? state.showVatIdWarningMessage
            : undefined;
    application.uiStateMap.fieldAutomationData.fieldAutomationBusinessSelection =
        state.fieldAutomationBusinessSelection !== undefined
            ? state.fieldAutomationBusinessSelection
            : undefined;
    application.uiStateMap.fieldAutomationData.continueManuallyWithOutFieldAutomation =
        state.continueManuallyWithOutFieldAutomation !== undefined
            ? state.continueManuallyWithOutFieldAutomation
            : undefined;

    return application;
}

// Read state for the personal profile and place it into the scarecrow application.
export function parsePersonalProfileToScareApp(
    { personalProfileForm: state }: AppState,
    application: ScarecrowApplication,
): ScarecrowApplication {
    const name: Name = {
        firstName: state.firstName,
        middleName: state.middleName,
        lastName: state.lastName,
    };
    const address: Address = {
        // streetName for LineOne?
        streetNumber: state.personalStreetNumber,
        streetName: state.streetName,
        lineTwo: state.lineTwo,
        city: state.city,
        postCode: state.postCode,
        state: state.state,
        country: state.country,
    };

    const contactInfo: ContactInfo = {
        address,
        phone: state.personalPhoneNumber as PhoneNumber,
        emailAddress: state.personalEmailAddress,
    };

    const dob = state.dob ? parseDateOfBirth(new Date(state.dob)) : undefined;
    const ids: Identification[] = [];
    if (state.personalSSN) {
        ids.push({ idType: IDType.ID_CARD, idNumber: state.personalSSN });
    }
    if (state.personalITIN) {
        ids.push({ idType: IDType.ITIN, idNumber: state.personalITIN });
    }
    if (state.personalPeselNumber) {
        ids.push({ idType: IDType.PESEL, idNumber: state.personalPeselNumber });
    }
    if (state.personalProofOfIdentity) {
        ids.push({
            idType: IDType[state.personalProofOfIdentity],
            idNumber: state.personalDocumentNumber,
            issuingCountry: state.personalCountryOfDocIssuance,
            issuingAgency: state.personalIssueAgency,
            issueDate: state.idIssueDate
                ? parseDateOfBirth(new Date(state.idIssueDate))
                : undefined,
            expiryDate: state.idExpiryDate
                ? parseDateOfBirth(new Date(state.idExpiryDate))
                : undefined,
        });
    }
    const principal: Person = {
        name,
        contactInfo,
        dob,
        primaryNationality: state.countryOfCitizenship,
        ids: ids.length > 0 ? ids : undefined,
        ownershipPct: state.personalOwnersPercentage ? state.personalOwnersPercentage : undefined,
        title: state.personalTitleOther,
    };

    return { ...application, principal };
}

export function parseDateOfBirth(dob: Date): DateComponents {
    const toReturn: any = {
        year: dob.getUTCFullYear(),
        day: dob.getUTCDate(),
    };
    const uscMonth = dob.getUTCMonth();
    if (typeof uscMonth === "number" && uscMonth >= 0 && uscMonth < 12) {
        toReturn.month = Month[dob.getUTCMonth() + 1];
    }
    return toReturn;
}

// Read state for the business profile and place it into the scarecrow application.
export function parseBusinessProfileToScareApp(
    { businessProfileForm: state }: AppState,
    application: ScarecrowApplication,
): ScarecrowApplication {
    // Parsing Business Legal Information

    const legalAddress = {
        streetName: state.businessLegalAddress,
        lineTwo: state.businessLegalAddressLineTwo,
        city: state.businessLegalCity,
        postCode: state.businessLegalZipCode,
        country: state.businessLegalCountry,
        state: state.businessLegalStateOrProvince,
        streetNumber: state.businessLegalStreetNumber,
    } as Address;

    if (legalAddress.state && !legalAddress.country) {
        legalAddress.country = getCountryFromState(legalAddress.state);
    }

    const legalAddressMap = { LEGAL: legalAddress };

    const businessAddress = {
        streetName: state.businessAddressLineOneField,
        lineTwo: state.businessAddressLineTwoField,
        city: state.businessCityField,
        postCode: state.businessZipCodeField,
        country: state.businessCountryField,
        state: state.businessStateField,
        streetNumber: state.businessPhysicalAddressNumber,
    } as Address;

    if (businessAddress.state && !businessAddress.country) {
        businessAddress.country = getCountryFromState(businessAddress.state);
    }

    const businessInfo = {
        businessAddress,
        additionalAddresses: legalAddressMap,
        dbaName: state.businessName,
        dbaNameExtended: state.businessName,
        legalName: state.businessName,
        legalNameExtended: state.businessName,
        registrationNumber: state.registrationNumber,
        taxFormType: state.businessTaxFormType,
        taxIDType: state.businessTaxIdType,
        // this is like this because the aboutBusinessForm sets the tax id based on businessVatNumber prior to this function call
        taxID: application.businessInfo
            ? application.businessInfo.taxID
            : undefined,
    } as BusinessInfo;

    const financialInfo = {
        customerServicePhone: state.businessCustomerServicePhone,
        businessEmailAddress: state.businessCustomerServiceEmailAddress,
        moneyServices: state.moneyServices,
        paymentServices: state.paymentServices,
        thirdPartyProcessor: state.thirdPartyProcessor,
        nonGovernmentNonProfit: state.nonGovernmentNonProfit,
    } as FinancialInfo;

    const contactInfo = {
        emailAddress: state.businessEmailAddress,
        phone: state.businessPhoneNumber,
    } as ContactInfo;

    const contact = {
        contactInfo,
    } as Person;

    if (application.principal && application.principal.name) {
        contact.name = application.principal.name;
    }

    application.businessInfo = { ...application.businessInfo, ...businessInfo };
    application.financialInfo = {
        ...application.financialInfo,
        ...financialInfo,
    };
    application.contact = { ...application.contact, ...contact };
    return { ...application };
}

export function parseBankingInformationFormToState(
    {
        bankingInformationForm: state,
        JurisdictionInfo: jurisdictionInfoState,
    }: AppState,
    application: ScarecrowApplication,
): ScarecrowApplication {
    // sortCode is the name for routing number in the server
    const bankingInfo: BankingInfo = {
        accountNumber: state.bankAccountNumber,
        sortCode: state.bankRoutingNumber,
        accountName: state.accountName,
        iban: state.bankIbanNumber,
        country: jurisdictionInfoState.country,
    };

    application.bankAccounts = {
        ...application.bankAccounts,
        ...{
            [BankAccountTypes.DEPOSIT]: bankingInfo,
        },
    };

    return { ...application };
}

export function parseAdditionalOwnersFormToState(
    { ownersProfileForm: state, placeHolderForm: { numberOfOwners } }: AppState,
    application: ScarecrowApplication,
): ScarecrowApplication {
    const additionalShareholders = [] as Person[];

    _.each(state.owners, (owner: OwnersProfile, index: number) => {
        // Rather than clear the state, we'll leave the state alone so that it's still there until they leave the page
        // Just in case they flip flop and don't want to re-type all the data.
        if (index >= Number(numberOfOwners!)) {
            return;
        }

        const contactInfoAddress: Address = {
            streetName: owner.ownersAddressLine1,
            streetNumber: owner.ownersAddressNumber,
            lineTwo: owner.ownersAddressLine2,
            city: owner.ownersCity,
            postCode: owner.ownersZipCode,
            country: owner.ownersCountry,
            state: owner.ownersProvence as StatesAbreviatedClass,
        };

        const contactInfo: ContactInfo = {
            address: contactInfoAddress,
            emailAddress: owner.ownersEmailAddress,
            phone: owner.ownersPhoneNumber as PhoneNumber,
        };

        const name: Name = {
            firstName: owner.ownersFirstName,
            middleName: owner.ownersMiddleName,
            lastName: owner.ownersLastName,
        };

        const identification: Identification = {
            idType: owner.ownersIDType,
            idNumber:
                owner.ownersIDType === IDType.ITIN
                    ? owner.ownersITIN
                    : owner.ownersSSN,
        };

        const person: Person = {
            contactInfo,
            dob: owner.ownersDateOfBirth
                ? parseDateOfBirth(new Date(owner.ownersDateOfBirth))
                : undefined,
            name,
            ids:
                identification.idType !== undefined
                    ? [identification]
                    : undefined,
            ownershipPct: owner.ownersOwnershipPercentage,
            responsibleParty: false,
            primaryNationality: owner.ownersCitizenship,
        };
        additionalShareholders.push(person);
    });

    return { ...application, additionalShareholders };
}

export function parseAboutBusinessFormToScareApp(
    { aboutBusinessForm: state }: AppState,
    application: ScarecrowApplication,
): ScarecrowApplication {
    application.businessInfo = {
        ...application.businessInfo,
        ...{
            taxID: state.businessVatNumber,
            customerMembershipNumber: state.customerMembershipNumber,
        },
    };

    application.businessInfo.vatInfo = {
        ...application.businessInfo.vatInfo,
        numberOption: state.vatNumberOption,
    };

    return { ...application };
}

export function parseCreditUnderwritingToScareApp(
    { creditUnderwritingForm: state }: AppState,
    application: ScarecrowApplication,
): ScarecrowApplication {
    application.businessInfo = {
        ...application.businessInfo,
        ...{
            establishmentYear: state.businessEstablishmentYear,
            currentOwnershipMonths: state.currentOwnershipMonths,
            currentOwnershipYears: state.currentOwnershipYears,
            employerId: state.employerId,
        },
    };
    application.financialInfo = {
        ...application.financialInfo,
        ...{
            annualRevenue: state.companyAnnualRevenue,
            monthlyCardSales: state.projectedMonthlyCardSales,
            avgSaleAmount: state.averageTransactionValue,
        },
    };
    return { ...application };
}

export function parseGeographyInfoToScareApp(
    { geographyInfoForm: state }: AppState,
    application: ScarecrowApplication,
): ScarecrowApplication {
    application.businessInfo = {
        ...application.businessInfo,
        ...{
            countryOfOrigin: state.countryOfFormation,
            countryOfPrimaryOperation: state.countryOfPrimaryBusinessOperations,
        },
    };
    return application;
}

export function parsePlaceholderInfoToScareApp(
    { placeHolderForm: state }: AppState,
    application: ScarecrowApplication,
): ScarecrowApplication {
    application.businessInfo = {
        ...application.businessInfo,
        mccCode: state.mccCode,
        productDescription: state.businessMCC
            ? state.businessMCC.description
            : undefined,
        ownershipType: state.ownershipType
            ? convertToScarecrowOwnershipType(state.ownershipType)
            : undefined,
    };

    if (application.principal) {
        application.principal.responsibleParty =
            state.responsibleParty === "true";
    }

    return { ...application };
}

export function parsePlaceholderInfoToSelfBoardApp(
    { placeHolderForm: state }: AppState,
    application: SelfboardApplication,
): SelfboardApplication {
    const placeholderInfo: UIStateMap = {
        isTaxIdSSN: state.isTaxIdSSN,
        businessCustomerDropDown: state.businessCustomerDropDown,
        businessAddressDropDown: state.businessAddressDropDown,
        businessLegalAddressSelection: state.businessLegalAddressSelection,
        businessMCC: state.businessMCC,
        activeSection: state.activeSection,
        bankName: state.bankName,
        routingNumStatus: state.routingNumStatus,
        ownershipType: state.ownershipType,
        numberOfOwners: state.numberOfOwners,
        hasEin: state.hasEIN,
        hasIban: state.hasIban,
        responsibleParty: state.responsibleParty,
    };

    application.uiStateMap = { ...application.uiStateMap, ...placeholderInfo };
    application.bundleOfferCode = state.bundleOfferCode;

    application.scarecrowApplication!.businessInfo = {
        ...application.scarecrowApplication!.businessInfo,
        mccCode: state.businessMCC ? state.businessMCC.value : undefined,
    };
    const bankingVerificationDetails = {
        bankName: state.bankName,
        bankBranch: state.bankBranchName,
    };

    application.bankAccountVerifications = {
        [BankAccountTypes.DEPOSIT]: bankingVerificationDetails,
    };

    return application;
}

export function configureAddressSameAs(
    { businessProfileForm: state }: AppState,
    selfboardApplication: SelfboardApplication,
) {
    if (state.businessAddressDropDown === "PERSONAL") {
        selfboardApplication.businessAddressSameAs = AddressType.PRINCIPAL;
    } else {
        selfboardApplication.businessAddressSameAs = null;
    }

    if (state.businessLegalAddressSelection === "BUSINESS") {
        selfboardApplication.legalAddressSameAs = AddressType.BUSINESS;
    } else if (state.businessLegalAddressSelection === "PERSONAL") {
        selfboardApplication.legalAddressSameAs = AddressType.PRINCIPAL;
    } else {
        selfboardApplication.legalAddressSameAs = null;
    }

    return selfboardApplication;
}

export function parseCardAcceptanceInfoToSelfBoardApp(
    { cardAcceptanceForm: state }: AppState,
    application: ScarecrowApplication,
): ScarecrowApplication {
    const financialInfo: FinancialInfo = {
        cardPresentAcceptancePercent: state.cardPresentAcceptancePercent,
        internetAcceptancePercent: state.internetAcceptancePercent,
        motoAcceptancePercent: state.motoAcceptancePercent,
        businessWebsiteURL: state.businessWebsiteURL,
    };
    application.financialInfo = {
        ...application.financialInfo,
        ...financialInfo,
    };
    return application;
}
