import IDType from "../../models/IDType";
import OwnershipType, { isOwnershipTaxExempt } from "../../models/OwnershipType";
import { AppState } from "../../reducers";
import shouldCustomerExit, {
    canExitConditions, gbrExitConditions, jurisdictionSpecificInfoAvailable, norExitConditions, polExitConditions, usaExitConditions,
} from "../../services/contactExit";
import isEmpty from "../../services/IsEmpty";

export interface ConditionalRendererLookup {
    [key: string]: (state: AppState, payloadId?: string) => any;
}

const conditionalRendererLookup: ConditionalRendererLookup = {
    shouldRenderBusinessAddressFields: (state) => state.businessProfileForm.businessAddressDropDown === "NEW_ADDRESS",
    shouldRenderLegalAddressFields: (state) => state.businessProfileForm.businessLegalAddressSelection === "NEW",
    shouldRenderContactExit: (state) => shouldCustomerExit(state),
    shouldRenderUsaContactExitDynamic: (state: AppState) => usaExitConditions(state) && jurisdictionSpecificInfoAvailable(state),
    shouldRenderCanContactExitDynamic: (state: AppState) => canExitConditions(state) && jurisdictionSpecificInfoAvailable(state),
    shouldRenderPolandContactExit: (state) => shouldCustomerExit(state),
    shouldRenderPolandContactExitDynamic: (state) => polExitConditions(state) && jurisdictionSpecificInfoAvailable(state),
    shouldRenderNorwayContactExitDynamic: (state) => norExitConditions(state) && jurisdictionSpecificInfoAvailable(state),
    shouldRenderBusinessWebsiteUrl: (state) => Number(state.cardAcceptanceForm.internetAcceptancePercent) > 0,
    shouldRenderGBContactExit: (state) => shouldCustomerExit(state),
    shouldRenderGBContactExitDynamic: (state) => gbrExitConditions(state) && jurisdictionSpecificInfoAvailable(state),
    shouldRenderVatNumberField: (state) => state.aboutBusinessForm.businessVatNumberQuestion === "true",
    shouldRenderPrincipalTitle: (state) => isOwnershipTaxExempt(state.placeHolderForm.ownershipType),
    shouldRenderPrincipalTitleOther: (state) => state.personalProfileForm.personalPrincipleTitle === "OTHER",
    shouldShowEINChoice: (state) => {
        if (state.JurisdictionInfo && state.JurisdictionInfo.country === "CAN") {
            if (state.personalProfileForm && state.personalProfileForm.countryOfCitizenship === "CAN") {
                return false;
            }
        }
        return state.placeHolderForm.ownershipType === OwnershipType.SOLE_TRADER;
    },
    shouldShow4AMLChoice: (state) => {
        if (state.JurisdictionInfo && state.JurisdictionInfo.country === "CAN") {
            if (state.personalProfileForm && state.personalProfileForm.countryOfCitizenship === "CAN") {
                return false;
            }
        }
        return state.placeHolderForm.ownershipType !== OwnershipType.SOLE_TRADER;
    },
    shouldShowMoneyServiceBusiness: (state) => {
        if (state.JurisdictionInfo && state.JurisdictionInfo.country === "CAN") {
            if (state.personalProfileForm && state.personalProfileForm.countryOfCitizenship === "CAN") {
                return false;
            }
        }
        return state.placeHolderForm.ownershipType === OwnershipType.SOLE_TRADER;
    },
    employerIdNumber: (state: AppState) => {
        if (state.JurisdictionInfo && state.JurisdictionInfo.country === "CAN") {
            if (state.personalProfileForm && state.personalProfileForm.countryOfCitizenship === "CAN") {
                return false;
            }
        }
        return state.placeHolderForm.hasEIN === "true";
    },
    CanadaTaxIdNumber: (state: AppState) => {
        if (state.JurisdictionInfo && state.JurisdictionInfo.country === "CAN") {
            if (state.personalProfileForm && state.personalProfileForm.countryOfCitizenship === "CAN") {
                return true;
            }
        }
        return false;
    },
    personalIDType: (state: AppState) => state.personalProfileForm.countryOfCitizenship === "CAN",
    personalSSN: (state: AppState) => {
        if (state.JurisdictionInfo && state.JurisdictionInfo.country === "CAN") {
            return state.personalProfileForm.countryOfCitizenship === "USA" && state.personalProfileForm.personalIDType === IDType.ID_CARD;
        } else {
            return state.personalProfileForm.countryOfCitizenship === "USA" || state.personalProfileForm.personalIDType === IDType.ID_CARD;
        }
    },
    personalITIN: (state: AppState) =>
        state.personalProfileForm.countryOfCitizenship === "CAN" && state.personalProfileForm.personalIDType === IDType.ITIN,
    shouldShowOwnershipPercent: (state: AppState) =>
        state.placeHolderForm.ownershipType === OwnershipType.PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.LIMITED_LIABILITY_PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.C_CORPORATION_PRIVATE_COMPANY ||
        state.placeHolderForm.ownershipType === OwnershipType.SUB_S_CORPORATION ||
        state.placeHolderForm.ownershipType === OwnershipType.LIMITED_COMPANY ||
        state.placeHolderForm.ownershipType === OwnershipType.LIMITED_COMPANY_CORPORATION,
    shouldShowAlternateOwnershipQuestionPoland: (state: AppState) =>
        state.placeHolderForm.ownershipType === OwnershipType.POLAND_PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.GENERAL_PARTNERSHIP,
    shouldShowDefaultOwnershipQuestionPoland: (state: AppState) =>
        !conditionalRendererLookup.shouldShowAlternateOwnershipQuestionPoland(state),
    shouldShowOwnershipPercentPolandHeaderSoleProp: (state: AppState) =>
        state.placeHolderForm.ownershipType === OwnershipType.SOLE_TRADER,
    shouldShowOwnershipPercentPoland: (state: AppState) =>
        state.placeHolderForm.ownershipType === OwnershipType.POLAND_PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.GENERAL_PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.LIMITED_COMPANY,
    shouldShowOwnershipPercentPolandHeader: (state: AppState) =>
        state.placeHolderForm.ownershipType === OwnershipType.PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.LIMITED_COMPANY ||
        state.placeHolderForm.ownershipType === OwnershipType.GENERAL_PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.POLAND_PARTNERSHIP,
    shouldRenderExceptionThreeAndFourText: (state: AppState) =>
        state.placeHolderForm.ownershipType === OwnershipType.PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.LIMITED_COMPANY,
    shouldRenderTextForPrtnrLmtdStText: (state: AppState) =>
        state.placeHolderForm.ownershipType === OwnershipType.POLAND_PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.GENERAL_PARTNERSHIP,
    shouldRenderForSoleProp: (state: AppState) =>
        state.placeHolderForm.ownershipType === OwnershipType.SOLE_TRADER,
    shouldRenderForExceptionOneTwoAndThreeIncludingSoleTrader: (state: AppState) =>
        state.placeHolderForm.ownershipType === OwnershipType.POLAND_PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.GENERAL_PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.LIMITED_COMPANY,
    shouldRenderForAllOwnershipTypes: (state: AppState) =>
        state.placeHolderForm.ownershipType === OwnershipType.PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.LIMITED_COMPANY ||
        state.placeHolderForm.ownershipType === OwnershipType.GENERAL_PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.POLAND_PARTNERSHIP,
    shouldRenderCompanyAgreementHeaderForOwnershipTypes: (state: AppState) =>
        state.placeHolderForm.ownershipType === OwnershipType.PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.GENERAL_PARTNERSHIP ||
        state.placeHolderForm.ownershipType === OwnershipType.POLAND_PARTNERSHIP,
    shouldRenderGeneralPartnerText: (state: AppState) =>
        state.placeHolderForm.ownershipType === OwnershipType.PARTNERSHIP,
    isOneOrMore: (state: AppState) => Number(state.placeHolderForm.numberOfOwners || 0) >= 1,
    isTwoOrMore: (state: AppState) => Number(state.placeHolderForm.numberOfOwners || 0) >= 2,
    isThreeOrMore: (state: AppState) => Number(state.placeHolderForm.numberOfOwners || 0) >= 3,
    isFourOrMore: (state: AppState) => Number(state.placeHolderForm.numberOfOwners || 0) >= 4,
    isFiveOrMore: (state: AppState) => Number(state.placeHolderForm.numberOfOwners || 0) >= 5,
    isSixOrMore: (state: AppState) => Number(state.placeHolderForm.numberOfOwners || 0) >= 6,
    isSevenOrMore: (state: AppState) => Number(state.placeHolderForm.numberOfOwners || 0) >= 7,
    isLessThanThree: (state: AppState) => Number(state.placeHolderForm.numberOfOwners || 0) < 3,
    ownerIsSsn: (state, payloadId) =>
        state.ownersProfileForm.owners[Number(payloadId)] &&
        state.ownersProfileForm.owners[Number(payloadId)].ownersIDType === IDType.ID_CARD,
    ownerIsSsnCanada: (state, payloadId) =>
        state.ownersProfileForm.owners[Number(payloadId)] && state.ownersProfileForm.owners[Number(payloadId)].ownersCitizenship === "USA",
    ownerIsItin: (state, payloadId) =>
        state.ownersProfileForm.owners[Number(payloadId)] && state.ownersProfileForm.owners[Number(payloadId)].ownersIDType === IDType.ITIN,
    shouldRenderVatFields: (state) => state.aboutBusinessForm.businessVatNumberQuestion === "true",
    shouldRenderVatNumberQuestion: (state) => !isEmpty(state.placeHolderForm.ownershipType),
    shouldRenderVatFieldUK: (state) => state.aboutBusinessForm.vatNumberOption === "VAT_NBR",
    personalPeselNumber: (state: AppState) => state.personalProfileForm.countryOfCitizenship === "POL",
    shouldShowIban: (state) => state.placeHolderForm.hasIban === "true",
    shouldShowAccountSortCode: (state) => state.placeHolderForm.hasIban === "false",
    shouldShowBaxNumberFields: (state) => state.EquipmentForm.equipment && state.EquipmentForm.equipment[0] === "fakeNetsSku",
    shouldShowBaxDateField: (state) =>
        state.EquipmentForm.equipment &&
        state.EquipmentForm.equipment[0] === "fakeNetsSku" &&
        state.EquipmentForm.baxNumber &&
        state.EquipmentForm.baxNumber.length >= 1,
    shouldRenderBusinessByVatId: (state) =>
        state.aboutBusinessForm.businessVatNumberQuestion === "true" && state.aboutBusinessForm.showBusinessNameAndAddress === "true",
    shouldRenderVatNumberWarningMessage: (state) =>
        state.aboutBusinessForm.showVatIdWarningMessage === "true" &&
        state.aboutBusinessForm.businessVatNumber !== "" &&
        state.aboutBusinessForm.businessVatNumber !== undefined,
    shouldRenderVatFieldAutomationProgress: (state) =>
        state.aboutBusinessForm.showSearchingProgress === "true" &&
        state.aboutBusinessForm.businessVatNumber !== "" &&
        state.aboutBusinessForm.businessVatNumber !== undefined,
    shouldRenderProofOfIdentityFields: (state) =>
        state.personalProfileForm.personalProofOfIdentity !== "" && state.personalProfileForm.personalProofOfIdentity !== undefined,
    shouldShowOwnershipPercentNorway: (state) =>
        state.placeHolderForm.ownershipType === "GENERAL_PARTNERSHIP" ||
        state.placeHolderForm.ownershipType === "PUBLIC_LIMITED_COMPANY" ||
        state.placeHolderForm.ownershipType === "LIMITED_COMPANY" ||
        state.placeHolderForm.ownershipType === "LIMITED_COMPANY_CORPORATION" ||
        state.placeHolderForm.ownershipType === "LIMITED_PARTNERSHIP" ||
        state.placeHolderForm.ownershipType === "OTHER",
    shouldRenderDirectorQuestion : (state) =>
        state.placeHolderForm.ownershipType === OwnershipType.LIMITED_COMPANY,
};

export default conditionalRendererLookup;
