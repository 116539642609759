import { connect } from "react-redux";
import CustomerFlowGDPRCookiePreferences from "../../components/customer/CustomerFlowGDPRCookiePreferences";
import { CustomerAppState } from "../../reducers/customer_ui";

const mapStateToProps = (state: CustomerAppState) => ({
    cookiePolicy: state.rest.cookiePolicy,
});

const mapDispatchToProps = (dispatch) => {
    return {
        acceptCookies: () => {
            dispatch({ type: "SET_GDPR_COOKIES", payload: true });
        },
        removeAnalyticsCookie: () => {
            dispatch({type: "SET_ONLY_NECESSARY_COOKIES", payload: true});
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerFlowGDPRCookiePreferences);
