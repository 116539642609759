import { StyledComponentProps, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Component } from "react";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { CustomerAppState } from "../../reducers/customer_ui";
import ARHttp from "../../services/ARHttp";
import getLocale from "../../services/getLocaleService";

const styles = {
  partnerlogo: {
    maxHeight: "64px",
    borderLeft: "1px gray solid",
    paddingLeft: "10px",
  },
  displayInline: {
    display: "inline-block",
  },
};

interface StateProps {
  subjurisdiction?: string;
  subjurisdictionId?: number;
  brandCode?: string;
  jurisdiction?: string;
  language?: string;
}

interface Props extends StateProps, StyledComponentProps {
  dataRetrived: boolean;
  info?: any;
}

interface State {
  dataRetrived?: boolean;
  info?: any;
}

class CustomerFlowLogoPartner extends Component<Props, State, {}> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dataRetrived: false,
      info: " ",
    };
  }

  public componentDidMount() {
    ARHttp({
      method: "POST",
      url: "/sb/md/getJurisdictionInfo",
      body: {
        subJurisdiction: this.props.subjurisdiction || this.props.subjurisdictionId,
        jurisdiction: this.props.jurisdiction || this.props.brandCode,
        infoType: "PARTNER_LOGO",
        language: this.props.language || getLocale(),
      },
    })
    .pipe(
      catchError((err) => of(err)),
    )
    .subscribe((res) => {
      this.setState({
        dataRetrived: true,
        info: res.info,
      });
    });
  }

  public render() {
    const { classes } = this.props as any;

    function renderImage(props) {
      return <img alt="Partner Logo" src={props.src} className={classes.partnerlogo} id="partnerLogo"/>;
    }

    if (this.state.dataRetrived) {
      return (
        <React.Fragment>
          <ReactMarkdown
            source={this.state.info}
            renderers={{image: renderImage}}
            className={classes.displayInline}
          />
        </React.Fragment>
      );
    }

    return null;
  }
}

function mapStateToProps(state: CustomerAppState): StateProps {
  return {
    subjurisdiction: state.JurisdictionInfo.subJurisdictionCode,
    subjurisdictionId: state.JurisdictionInfo.subJurisdictionId,
    brandCode: state.JurisdictionInfo.brandCode,
    jurisdiction: state.JurisdictionInfo.referrerName,
    language: state.rest.userLanguage,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(CustomerFlowLogoPartner) as any);
