import { BehaviorSubject } from 'rxjs';

interface PropertiesType {
  passwordMinLength: number;
}

const eventStream = new BehaviorSubject<PropertiesType>({
passwordMinLength : 14,
});

export default {
  setProps(props) {
    eventStream.next(props);
  },

  subscribe(x) {
    return eventStream.subscribe(x);
  },

  unsubscribe(x) {
    x.unsubscribe();
  },
};
