import { connect } from "react-redux";
import CustomerFlowReviewAndSign from "../../components/customer/CustomerFlowReviewAndSign";
import App from "../../models/App";
import { DocumentSignatureType } from "../../models/DocumentType";
import GroupApp from "../../models/GroupApp";
import { CustomerAppState } from "../../reducers/customer_ui";

const mapStateToProps = (state: CustomerAppState) => {
    return {
        appId: state.rest.appId,
        cToken: state.auth.ctoken,
        app: state.rest.app,
        groupApp: state.rest.groupApp,
        requiredSignatures: state.rest.requiredSignatures,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addRequiredSignature: (signature: DocumentSignatureType) => {
            dispatch({ type: "ADD_REQUIRED_SIGNATURE", payload: signature });
        },
        loadAppInfo: (app: App | GroupApp) => {
            dispatch({ type: "LOAD_APP", payload: app });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerFlowReviewAndSign as any);
