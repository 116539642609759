import { getSettings } from "../services/Settings";

function convertImages(image) {
    if (image.includes("data:image/png;base64,")) {
        return image;
    } else if (getSettings().host) {
        return getSettings().host + image;
    } else {
        return image;
    }
}

export default convertImages;
