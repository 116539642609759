import { connect } from "react-redux";
import CustomerDocumentUpload from "../../components/customer/CustomerDocumentUpload";
import { CustomerAppState } from "../../reducers/customer_ui";

const mapStateToProps = (state: CustomerAppState, props) => ({
    appId: props.appId,
    cToken: state.auth.ctoken,
    isThirdPartyApp: props ? props.isThirdPartyApp : false,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setAppError: () => {
            dispatch({ type: "SET_ERROR", payload: true });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerDocumentUpload);
