import * as _ from "lodash";

export enum DocumentType {
    POWER_TO_BIND_RESOLUTION = "POWER_TO_BIND_RESOLUTION",
    APPLICATION = "APPLICATION",
    UNSIGNED_APPLICATION = "UNSIGNED_APPLICATION",
    MERCHANT_AGREEMENT = "MERCHANT_AGREEMENT",
    SELF_GUARANTEE = "SELF_GUARANTEE",
    CALIFORNIA_LEASE = "CALIFORNIA_LEASE",
    CANADIAN_CODE_OF_CONDUCT = "CANADIAN_CODE_OF_CONDUCT",
    TERMS_OF_SERVICE = "TERMS_OF_SERVICE",
    OPERATING_GUIDE = "OPERATING_GUIDE",
    TALECH_ADDENDUM = "TALECH_ADDENDUM",
    BLIK_ADDENDUM = "BLIK_ADDENDUM",
    TALECH_ELAVON_TERMS_AND_CONDITION_ADDENDUM = "TALECH_ELAVON_TERMS_AND_CONDITION_ADDENDUM",
    TALECH_TERMS_OF_SERVICE = "TALECH_TERMS_OF_SERVICE",
    AMEX_AGREEMENT = "AMEX_AGREEMENT",
    AMEX_TERMS_OF_SERVICE = "AMEX_TERMS_OF_SERVICE",
    AMEX_SITE_LETTER = "AMEX_SITE_LETTER",
    SAFE_T_ADDENDUM = "SAFE_T_ADDENDUM",
    EPG_TOC = "EPG_TOC",
    DEPOSIT_DIRECT_DEBIT = "DEPOSIT_DIRECT_DEBIT",
    DEPOSIT_SIGNED_DIRECT_DEBIT = "DEPOSIT_SIGNED_DIRECT_DEBIT",
    BILLING_DIRECT_DEBIT = "BILLING_DIRECT_DEBIT",
    BILLING_SIGNED_DIRECT_DEBIT = "BILLING_SIGNED_DIRECT_DEBIT",
    CHARGEBACK_DIRECT_DEBIT = "CHARGEBACK_DIRECT_DEBIT",
    CHARGEBACK_SIGNED_DIRECT_DEBIT = "CHARGEBACK_SIGNED_DIRECT_DEBIT",
    TENNESSEE_LEASE = "TENNESSEE_LEASE",
    TERMINAL_HIRE_AGREEMENT = "TERMINAL_HIRE_AGREEMENT",
    TERMINAL_HIRE_AGREEMENT_FOR_TALECH_ELO = "TERMINAL_HIRE_AGREEMENT_FOR_TALECH_ELO",
    TERMINAL_HIRE_PRE_CONTRACT = "TERMINAL_HIRE_PRE_CONTRACT",
    TERMINAL_HIRE_PRE_CONTRACT_FOR_TALECH_ELO = "TERMINAL_HIRE_PRE_CONTRACT_FOR_TALECH_ELO",
    TERMINAL_HIRE_AGREEMENT_STATUTORY = "TERMINAL_HIRE_AGREEMENT_STATUTORY",
    EPG_ADDENDUM = "EPG_ADDENDUM",
    ELAVON_TOKENIZATION_TOC = "ELAVON_TOKENIZATION_TOC",
    ELAVON_SECURED_PRO_TOC = "ELAVON_SECURED_PRO_TOC",
    ELAVON_SECURED_ENCRYPT_TOC = "ELAVON_SECURED_ENCRYPT_TOC",
    SUPPORTING_DOCUMENT = "SUPPORTING_DOCUMENT",
    GOVERNMENT_INCENTIVE_ADDENDUM = "GOVERNMENT_INCENTIVE_ADDENDUM",
    PROMOTION_ADDENDUM = "PROMOTION_ADDENDUM",
    POYNT_ADDENDUM = "POYNT_ADDENDUM",
    CONVERGE_ELAVON_TERMS_AND_CONDITION_ADDENDUM = "CONVERGE_ELAVON_TERMS_AND_CONDITION_ADDENDUM",
    CONVERGE_ADDENDUM = "CONVERGE_ADDENDUM",
    LEASE_AGREEMENT = "LEASE_AGREEMENT",
    SANTANDER_POS_ADDENDUM = "SANTANDER_POS_ADDENDUM",
    KKT_ADDENDUM = "KKT_ADDENDUM",
    PARTNER_DOCUMENTS = "PARTNER_DOCUMENTS",
    PARTNER_STATIC_DOCUMENTS = "PARTNER_STATIC_DOCUMENTS",
    OPAYO_CONTRACT = "OPAYO_CONTRACT",
    OPAYO_ADDENDUM = "OPAYO_ADDENDUM",
}

export enum DocumentSignatureType {
    APPLICATION_SIGNATURE = "APPLICATION_SIGNATURE",
    SELFGUARANTEE_SIGNATURE = "SELFGUARANTEE_SIGNATURE",
    AMEX_SIGNATURE = "AMEX_SIGNATURE",
    TERMINAL_HIRE_AGREEMENT_SIGNATURE = "TERMINAL_HIRE_AGREEMENT_SIGNATURE",
    CALIFORNIA_LEASE_SIGNATURE = "CALIFORNIA_LEASE_SIGNATURE",
    TALECH_ADDENDUM_SIGNATURE = "TALECH_ADDENDUM_SIGNATURE",
    BLIK_ADDENDUM_SIGNATURE = "BLIK_ADDENDUM_SIGNATURE",
    TENNESSEE_LEASE_SIGNATURE = "TENNESSEE_LEASE_SIGNATURE",
    ELAVON_SECURED_PRO_TOC_SIGNATURE = "ELAVON_SECURED_PRO_TOC_SIGNATURE",
    EPG_ADDENDUM_SIGNATURE = "EPG_ADDENDUM_SIGNATURE",
    ELAVON_SECURED_ENCRYPT_TOC_SIGNATURE = "ELAVON_SECURED_ENCRYPT_TOC_SIGNATURE",
    PROMOTION_ADDENDUM_SIGNATURE = "PROMOTION_ADDENDUM_SIGNATURE",
    DEPOSIT_DIRECT_DEBIT_SIGNATURE = "DEPOSIT_DIRECT_DEBIT_SIGNATURE",
    ELAVON_TOKENIZATION_TOC_SIGNATURE = "ELAVON_TOKENIZATION_TOC_SIGNATURE",
    BILLING_DIRECT_DEBIT_SIGNATURE = "BILLING_DIRECT_DEBIT_SIGNATURE",
    CHARGEBACK_DIRECT_DEBIT_SIGNATURE = "CHARGEBACK_DIRECT_DEBIT_SIGNATURE",
    GOVERNMENT_INCENTIVE_ADDENDUM_SIGNATURE = "GOVERNMENT_INCENTIVE_ADDENDUM_SIGNATURE",
    POYNT_ADDENDUM_SIGNATURE = "POYNT_ADDENDUM_SIGNATURE",
    CONVERGE_ADDENDUM_SIGNATURE = "CONVERGE_ADDENDUM_SIGNATURE",
    LEASE_SIGNATURE = "LEASE_SIGNATURE",
    SANTANDER_POS_ADDENDUM_SIGNATURE = "SANTANDER_POS_ADDENDUM_SIGNATURE",
    KKT_ADDENDUM_SIGNATURE = "KKT_ADDENDUM_SIGNATURE",
    PARTNER_DOCUMENTS = "PARTNER_DOCUMENTS",
    POWER_TO_BIND_RESOLUTION_SIGNATURE = "POWER_TO_BIND_RESOLUTION_SIGNATURE",
    PARTNER_STATIC_DOCUMENTS = "PARTNER_STATIC_DOCUMENTS",
}

const DocumentTypeTitleKeyMap: { [name: string]: string } = {
    APPLICATION: "company_application",
    MERCHANT_AGREEMENT: "company_application",
    SELF_GUARANTEE: "personal_guarantee",
    TENNESSEE_LEASE: "tennessee_lease_agreement",
    CALIFORNIA_LEASE: "california_lease_agreement",
    CANADIAN_CODE_OF_CONDUCT: "canadian_code_of_conduct",
    TERMS_OF_SERVICE: "terms_of_service",
    OPERATING_GUIDE: "merchant_operating_guide",
    TALECH_ADDENDUM: "talech_addendum",
    BLIK_ADDENDUM: "blik_addendum",
    TALECH_ELAVON_TERMS_AND_CONDITION_ADDENDUM: "talech_elavon_terms_and_condition_addendum",
    CONVERGE_ADDENDUM: "converge_addendum",
    CONVERGE_ELAVON_TERMS_AND_CONDITION_ADDENDUM: "converge_elavon_terms_and_condition_addendum",
    TALECH_TERMS_OF_SERVICE: "talech_terms_of_service",
    AMEX_AGREEMENT: "amex_agreement",
    AMEX_TERMS_OF_SERVICE: "amex_terms_of_service",
    AMEX_SITE_LETTER: "amex_site_letter",
    OPAYO_CONTRACT: "opayo_contract",
    OPAYO_ADDENDUM: "opayo_addendum",
    SAFE_T_ADDENDUM: "safe_t_addendum",
    EPG_TOC: "epg_terms_and_conditions",
    DEPOSIT_DIRECT_DEBIT: "deposit_direct_debit",
    DEPOSIT_SIGNED_DIRECT_DEBIT: "deposit_signed_direct_debit",
    BILLING_DIRECT_DEBIT: "billing_direct_debit",
    BILLING_SIGNED_DIRECT_DEBIT: "billing_signed_direct_debit",
    CHARGEBACK_DIRECT_DEBIT: "chargeback_direct_debit",
    CHARGEBACK_SIGNED_DIRECT_DEBIT: "chargeback_signed_direct_debit",
    TERMINAL_HIRE_AGREEMENT: "terminal_hire_agreement",
    TERMINAL_HIRE_AGREEMENT_FOR_TALECH_ELO: "terminal_hire_agreement_for_talech_elo",
    TERMINAL_HIRE_PRE_CONTRACT_FOR_TALECH_ELO: "terminal_hire_pre_contract_for_talech_elo",
    TERMINAL_HIRE_PRE_CONTRACT: "terminal_hire_pre_contract",
    TERMINAL_HIRE_AGREEMENT_STATUTORY: "terminal_hire_agreement_statutory",
    EPG_ADDENDUM: "epg_addendum",
    ELAVON_TOKENIZATION_TOC: "elavon_tokenization_terms_and_conditions",
    ELAVON_SECURED_PRO_TOC: "elavon_secured_pro_terms_and_conditions",
    ELAVON_SECURED_ENCRYPT_TOC: "elavon_secured_encrypt_terms_and_conditions",
    GOVERNMENT_INCENTIVE_ADDENDUM: "government_incentive_addendum",
    PAY_NAV_MEDICAL_AND_BUSINESS_AGREEMENT: "pay_nav_medical_business_agreement",
    UNSIGNED_APPLICATION: "unsigned_application",
    SIGNED_APPLICATION: "signed_application",
    PROMOTION_ADDENDUM: "promotion_addendum",
    POYNT_ADDENDUM: "poynt_addendum",
    LEASE_AGREEMENT: "lease_agreement",
    SANTANDER_POS_ADDENDUM: "santander_pos_addendum",
    KKT_ADDENDUM: "kkt_agreement",
    PARTNER_DOCUMENTS: "partner_documents",
    PARTNER_STATIC_DOCUMENTS: "partner_static_documents",
    APPLICATION_ADENDUM: "application_adendum",
    POWER_TO_BIND_RESOLUTION: "power_to_bind_resolution",
};

const DocumentDescriptionKeyMap: { [name: string]: string } = {
    APPLICATION: "boarding_decision_application",
    OPERATING_GUIDE: "boarding_decision_operationguide",
    TERMS_OF_SERVICE: "boarding_decision_termsofconditions",
    TENNESSEE_LEASE: "boarding_decision_tennessee_lease",
    CALIFORNIA_LEASE: "boarding_decision_california_lease",
    CANADIAN_CODE_OF_CONDUCT: "boarding_decision_canadian_code_of_conduct",
    TERMINAL_HIRE_PRE_CONTRACT: "boarding_decision_terminalhire_precontract",
    TERMINAL_HIRE_PRE_CONTRACT_FOR_TALECH_ELO: "boarding_decision_terminalhire_precontract_for_talech_elo",
    EPG_TOC: "boarding_epg_toc",
    EPG_ADDENDUM: "boarding_epg_addendum",
    ELAVON_TOKENIZATION_TOC: "boarding_elavon_tokenization_toc",
    ELAVON_SECURED_PRO_TOC: "boarding_elavon_secured_pro_toc",
    ELAVON_SECURED_ENCRYPT_TOC: "boarding_elavon_secured_encrypt_toc",
    TALECH_ELAVON_TERMS_AND_CONDITION_ADDENDUM: "boarding_decision_talech_elavon_terms_and_condition_addendum",
    GOVERNMENT_INCENTIVE_ADDENDUM: "boarding_govtIncentiveAddendum",
    AMEX_TERMS_OF_SERVICE: "boarding_decision_amextermsofconditions",
    TALECH_TERMS_OF_SERVICE: "boarding_decision_talech_terms_of_service",
    AMEX_SITE_LETTER: "boarding_amexSiteLetter",
    DEPOSIT_DIRECT_DEBIT: "boarding_decision_directdebit",
    BILLING_DIRECT_DEBIT: "boarding_decision_directdebit",
    CHARGEBACK_DIRECT_DEBIT: "boarding_decision_directdebit",
    DEPOSIT_SIGNED_DIRECT_DEBIT: "boarding_decision_directdebitsign",
    BILLING_SIGNED_DIRECT_DEBIT: "boarding_decision_directdebitsign",
    CHARGEBACK_SIGNED_DIRECT_DEBIT: "boarding_decision_directdebitsign",
    CONVERGE_ADDENDUM: "boarding_decision_converge_addendum",
    CONVERGE_ELAVON_TERMS_AND_CONDITION_ADDENDUM: "boarding_decision_converge_terms_of_service",
    PAY_NAV_MEDICAL_AND_BUSINESS_AGREEMENT: "boarding_decision_paynav_medical_and_business_agreement",
    TERMINAL_HIRE_AGREEMENT_STATUTORY: "boarding_decision_terminalhire_statutorycopy",
    PROMOTION_ADDENDUM: "boarding_promotion_addendum",
    TERMINAL_HIRE_AGREEMENT: "terminal_hire_agreement_description",
    TERMINAL_HIRE_AGREEMENT_FOR_TALECH_ELO: "terminal_hire_agreement_for_talech_elo_description",
    TALECH_ADDENDUM: "talech_addendum_description",
    BLIK_ADDENDUM: "blik_addendum_description",
    AMEX_AGREEMENT: "amex_agreement_description",
    SAFE_T_ADDENDUM: "safe_t_addendum_description",
    UNSIGNED_APPLICATION: "unsigned_application_description",
    SIGNED_APPLICATION: "signed_application_description",
    POYNT_ADDENDUM: "poynt_addendum",
    SANTANDER_POS_ADDENDUM: "santander_pos_addendum",
    KKT_ADDENDUM: "Annex KKT",
    OPAYO_CONTRACT: "opayo_gateway_contract_description",
    OPAYO_ADDENDUM: "boarding_decision_opayo_addendum",
    POWER_TO_BIND_RESOLUTION: "power_to_bind_resolution",
};

const SignableDocumentSignatureMap: { [key in DocumentType]?: DocumentSignatureType} = {
    MERCHANT_AGREEMENT: DocumentSignatureType.APPLICATION_SIGNATURE,
    SELF_GUARANTEE: DocumentSignatureType.SELFGUARANTEE_SIGNATURE,
    AMEX_AGREEMENT: DocumentSignatureType.AMEX_SIGNATURE,
    TERMINAL_HIRE_AGREEMENT: DocumentSignatureType.TERMINAL_HIRE_AGREEMENT_SIGNATURE,
    CALIFORNIA_LEASE: DocumentSignatureType.CALIFORNIA_LEASE_SIGNATURE,
    TALECH_ADDENDUM: DocumentSignatureType.TALECH_ADDENDUM_SIGNATURE,
    TENNESSEE_LEASE: DocumentSignatureType.TENNESSEE_LEASE_SIGNATURE,
    ELAVON_SECURED_PRO_TOC: DocumentSignatureType.ELAVON_SECURED_PRO_TOC_SIGNATURE,
    EPG_ADDENDUM: DocumentSignatureType.EPG_ADDENDUM_SIGNATURE,
    ELAVON_SECURED_ENCRYPT_TOC: DocumentSignatureType.ELAVON_SECURED_ENCRYPT_TOC_SIGNATURE,
    PROMOTION_ADDENDUM: DocumentSignatureType.PROMOTION_ADDENDUM_SIGNATURE,
    DEPOSIT_DIRECT_DEBIT: DocumentSignatureType.DEPOSIT_DIRECT_DEBIT_SIGNATURE,
    ELAVON_TOKENIZATION_TOC: DocumentSignatureType.ELAVON_TOKENIZATION_TOC_SIGNATURE,
    BILLING_DIRECT_DEBIT: DocumentSignatureType.BILLING_DIRECT_DEBIT_SIGNATURE,
    CHARGEBACK_DIRECT_DEBIT: DocumentSignatureType.CHARGEBACK_DIRECT_DEBIT_SIGNATURE,
    GOVERNMENT_INCENTIVE_ADDENDUM: DocumentSignatureType.GOVERNMENT_INCENTIVE_ADDENDUM_SIGNATURE,
    POYNT_ADDENDUM: DocumentSignatureType.POYNT_ADDENDUM_SIGNATURE,
    CONVERGE_ADDENDUM: DocumentSignatureType.CONVERGE_ADDENDUM_SIGNATURE,
    LEASE_AGREEMENT: DocumentSignatureType.LEASE_SIGNATURE,
    BLIK_ADDENDUM: DocumentSignatureType.BLIK_ADDENDUM_SIGNATURE,
    SANTANDER_POS_ADDENDUM: DocumentSignatureType.SANTANDER_POS_ADDENDUM_SIGNATURE,
    KKT_ADDENDUM: DocumentSignatureType.KKT_ADDENDUM_SIGNATURE,
    PARTNER_DOCUMENTS : DocumentSignatureType.PARTNER_DOCUMENTS,
    PARTNER_STATIC_DOCUMENTS : DocumentSignatureType.PARTNER_STATIC_DOCUMENTS,
    POWER_TO_BIND_RESOLUTION: DocumentSignatureType.POWER_TO_BIND_RESOLUTION_SIGNATURE,
};

export const DocumentDisplayOrder: { [name: string]: number } = _.invert(Object.keys(DocumentType).map((key) => DocumentType[key])) as any;

export enum DocumentDisplayType {
    SIGNABLE = "SIGNABLE",
    NOT_SIGNABLE = "NOT_SIGNABLE",
}

export interface DocumentDetails {
    titleKey: string;
    displayType: DocumentDisplayType;
    link?: string;
    partnerDocName?: string;
}

// istanbul ignore next: This is just a data dictionary. No need to test it.
export function getDocumentDisplayString(documentType: DocumentType): string {
    return DocumentTypeTitleKeyMap[documentType] || "unknown";
}

// istanbul ignore next: This is just a data dictionary. No need to test it.
export function getDocumentDetailsString(documentType: DocumentType): string {
    return DocumentDescriptionKeyMap[documentType] || "unknown";
}

// istanbul ignore next: This is just a data dictionary. No need to test it.
export function getDocumentSignatureType(documentType: DocumentType): DocumentSignatureType | void {
    return SignableDocumentSignatureMap[documentType];
}
