import { History } from "history";
import { connect } from "react-redux";
import AssistedLogin from "../../components/customer/AssistedLogin";
import { CustomerAppState } from "../../reducers/customer_ui";

const mapStateToProps = (state: CustomerAppState) => ({
    country: state.JurisdictionInfo.country,
    forgotSub: state.rest.loginSubmit,
    language: state.rest.language,
    referrer: state.JurisdictionInfo.referrerName,
    loginError: state.loginState.error,
    loginPending: state.loginState.pending,
    sessionExpired: state.loginState.expired,
    isThirdParty: state.rest.isForThirdParty,
    hasAcceptedCookies: state.rest.cookiesEnabled || state.rest.gdprCookiesPolicyEnabled,
    resetPasswordSuccess: state.rest.resetPasswordSuccess,
    passwordExpired: state.loginState.passwordExpired,
    unlockSuccess: state.rest.unlockAccountSuccess === "true",
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (username: string, password: string, history: History, transmitToken?: string) => {
            dispatch({
                type: "SUBMIT_LOGIN",
                payload: {
                    username,
                    password,
                    history,
                    transmitToken,
                },
            });
        },
        showCookieModal: () => {
            dispatch({
                type: "TOGGLE_COOKIE_MODAL",
            });
        },
        clearExpiredState: () => {
            dispatch({
                type: "CLEAR_EXPIRED_STATUS",
            });
        },
        clearResetPasswordSuccessState: () => {
            dispatch({
                type: "SET_RESET_PASSWORD_SUCCESS",
                payload: false,
            });
        },
        clearUnlockSuccess: () => {
            dispatch({
                type: "CLEAR_UNLOCK_STATUS",
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssistedLogin);
