import { Observable } from "rxjs";
import App from "../models/App";
import GroupApp from "../models/GroupApp";
import ARHttp from "./ARHttp";

export interface ShouldShowWaitPageResponse {
    showWaitPage: boolean;
}

export function checkWaitStatus(appId: number, thirdPartyApp: boolean, token: string): Observable<any> {
    return ARHttp({
        method: "POST",
        url: "/aws/shouldshowwaitpage",
        body: {
            "appId": appId,
            "thirdPartyApp": thirdPartyApp,
        },
        headers: {
            "X-XSRF-TOKEN": token,
        },
    });
}

export function shouldCheckWaitStatus(app?: App, groupApp?: GroupApp): boolean {
    return !!(app || groupApp);
}
