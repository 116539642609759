/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, CardContent, CardHeader, Grid, Paper } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { StyledComponentProps, StyleRules, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ChevronLeft } from "@material-ui/icons";
import i18next from "i18next";
import * as _ from "lodash";
import * as React from "react";
import { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import DocumentDownloadContainer from "../../containers/customer/DocumentDownloadContainer";
import App from "../../models/App";
import GroupApp from "../../models/GroupApp";
import { Region } from "../../models/Jurisdiction";
import { CustomerAppState } from "../../reducers/customer_ui";
import ARHttp from "../../services/ARHttp";
import { getLogicalStatusFromApp } from "../../services/CustomerLogicalAppStatus";
import {
    checkWaitStatus,
    shouldCheckWaitStatus,
    ShouldShowWaitPageResponse,
} from "../../services/WaitStatusCheckService";
import SignaturePageLoading from "../signature/SignaturePageLoading";
import CustomerDocumentUpload from "./CustomerDocumentUpload";
const feedbackIcon = require("../../assets/images/customer/feedback.png");

const pageStyles: StyleRules = {
    centerContent: {
        marginTop: "16px",
        alignItems: "center",
        flexDirection: "column",
        display: "flex",
    },
    referenceNumber: {
        color: "#83380c",
    },
    feedbackBox: {
        backgroundColor: "#fcf8e3",
        padding: "8px",
        marginTop: "24px",
    },
    link: {
        color: "#000",
        textDecoration: "none",
        fontWeight: "bold",
        "&:hover": {
            textDecoration: "underline",
        },
    },
};

const mapStateToProps = (state: CustomerAppState) => {
    return {
        country: state.JurisdictionInfo.country,
        cToken: state.auth.ctoken,
        groupAppInfo: state.rest.groupApp,
        appInfo: state.rest.app,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        toggleContactUsModal: () => {
            dispatch({ type: "TOGGLE_CONTACT_US_MODAL" });
        },
        loadJurisdictionInfo: (jurisdictionInfo) => {
            dispatch({ type: "SET_JURISDICTION_VALUES", payload: jurisdictionInfo });
        },
        clearSkipToApp: () => {
            dispatch({ type: "CLEAR_SKIP_TO_APP"});
        },
    };
};

interface Props {
    country?: string;
    history: any;
    cToken: string;
    groupAppInfo?: GroupApp;
    appInfo?: App;
    toggleContactUsModal: () => void;
    loadJurisdictionInfo: (jurisdictionInfo: any) => void;
    clearSkipToApp: () => void;
}

interface State {
    loading: boolean;
    documents: CustomerDocument[];
    apps: App[];
    hasWetSignNeededDocuments: boolean;
    region: Region;
}

interface CustomerDocument {
    link: string;
    title: string;
    description: string;
}

class ApplicationComplete extends Component<Props & StyledComponentProps, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            documents: [],
            apps: [],
            hasWetSignNeededDocuments: false,
            region: Region.NA,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.setHasWetSignNeededDocuments = this.setHasWetSignNeededDocuments.bind(this);
    }

    public componentDidMount() {
        window.document.title = i18next.t("customer_ui_completed");
        if (this.props.groupAppInfo) {
            if (this.props.groupAppInfo.thirdParty) {
                ARHttp({
                    method: "POST",
                    url: "/scwebapi/group/getthirdpartyappinfogroup",
                    body: {
                        id: this.props.groupAppInfo.id,
                    },
                    headers: {
                        "X-XSRF-TOKEN": this.props.cToken,
                    },
                }).subscribe((response: any) => {
                    const groupThirdPartyAppList: GroupApp[] | undefined = _.chain((response as any).groups)
                    .map((thirdPartyGroupApp) => {
                        return {
                            appInfo: thirdPartyGroupApp.appInfo,
                            groupInfo: {groupId: thirdPartyGroupApp.groupId, groupSize: thirdPartyGroupApp.appNumber},
                            index: thirdPartyGroupApp.index,
                            logicalStatus: getLogicalStatusFromApp(thirdPartyGroupApp.appInfo),
                            thirdParty: true,
                            id: thirdPartyGroupApp.groupId + "",
                        };
                    })
                    .value();
                    const apps = _.map(groupThirdPartyAppList, function(groupAppInfo: GroupApp) {
                        return groupAppInfo.appInfo;
                    });
                    const appId: number = this.props.groupAppInfo ? +this.props.groupAppInfo.id : 0;
                    this.setWaitStatus(appId, true);
                    this.setState({ apps, loading: false });
                });
            } else {
            ARHttp({
                method: "POST",
                url: "/scwebapi/group/getgroup",
                body: {
                    id: this.props.groupAppInfo.id,
                },
                headers: {
                    "X-XSRF-TOKEN": this.props.cToken,
                },
            }).subscribe((response: any) => {
                const groupAppInfoList = _.filter(response.groupAppInfoList, function(groupAppInfo) {
                    return groupAppInfo.appInfo.applicationType === "APPLICATION";
                });

                const apps = _.map(groupAppInfoList, function(groupAppInfo: GroupApp) {
                    return groupAppInfo.appInfo;
                });
                const appId: number = this.props.groupAppInfo ? +this.props.groupAppInfo.id : 0;
                this.setWaitStatus(appId, false);
                this.setState({ apps, loading: false });
            });

            ARHttp({
                method: "POST",
                url: "/aws/document/getappdetails",
                body: {
                    appId: this.props.groupAppInfo.id,
                },
                headers: {
                    "X-XSRF-TOKEN": this.props.cToken,
                },
            }).subscribe((response: any) => {
                this.setState({ region: response.jurisdiction.region });
                this.props.loadJurisdictionInfo(response);
            });
        }
        } else if (this.props.appInfo) {
            ARHttp({
                method: "POST",
                url: "/aws/getappdetails",
                body: {
                    appId: this.props.appInfo.id,
                },
                headers: {
                    "X-XSRF-TOKEN": this.props.cToken,
                },
            }).subscribe((response: any) => {
                this.setState({ region: response.region });
                this.props.loadJurisdictionInfo(response);
            });
            const appId: number = this.props.appInfo.id ? +this.props.appInfo.id : 0;
            this.setWaitStatus(appId, this.props.appInfo ? this.props.appInfo.thirdParty : false);
            this.setState({ apps: [this.props.appInfo], loading: false });
        }
    }

    public render() {
        const classes = this.props.classes!;

        let subtext1;
        if (this.props.appInfo && this.props.appInfo!.decision === "APPROVAL_STAGING") {
            this.props.country === 'CAN' ?
            subtext1 = <Trans>boarding_decision_header_body_1_approved_can</Trans> :
            subtext1 = <Trans>boarding_decision_header_body_1_approved</Trans>
        } else if (this.props.appInfo && this.props.appInfo!.decision === "d4") {
            subtext1 = <Trans>boarding_decision_header_body_1_conditional</Trans>;
        } else {
            subtext1 = <Trans>boarding_decision_header_body_1_smu_rmu_referred</Trans>;
        }

        let referenceNumber;
        if (this.props.appInfo && this.state.apps[0]) {
            referenceNumber = (
                <Fragment>
                    <Trans>boarding_decision_header_body_2</Trans>{" "}
                    <span className={classes.referenceNumber}>{this.state.apps[0].merchantId}</span>
                    <br />
                </Fragment>
            );
        }

        let documentUpload;
        const isThirdPartyApp = this.props.groupAppInfo && this.props.groupAppInfo.thirdParty;
        if (this.props.appInfo || isThirdPartyApp) {
            const appId = isThirdPartyApp ? this.props.groupAppInfo!.appInfo.id : this.props.appInfo!.id;
            documentUpload = (
                <Fragment>
                    <Typography variant="h6">
                        <Trans>upload_file</Trans>
                    </Typography>
                    <Typography variant="body1">
                        <Trans>upload_file_instruction</Trans> <Trans>supported_files</Trans>
                    </Typography>
                    <br />
                    <CustomerDocumentUpload cToken={this.props.cToken} appId={appId}
                        isThirdPartyApp={isThirdPartyApp} shouldHideRemoveDocument={true}/>
                </Fragment>
            );
        }

        const documentsToReview = (
            <Fragment>
                <Typography variant="h6">
                {(this.props.groupAppInfo && this.props.groupAppInfo.thirdParty) ? <Trans>application_status</Trans> :
                    <Trans>boarding_decision_reviewTitle</Trans>}
                </Typography>
                <DocumentDownloadContainer
                    cToken={this.props.cToken}
                    apps={this.state.apps}
                    groupAppInfo={this.props.groupAppInfo}
                    documentsToBeSigned={false}
                />
            </Fragment>
        );

        let documentsToBeSigned;
        if (this.state.region !== Region.NA) {
            documentsToBeSigned = (
                <Fragment>
                    <DocumentDownloadContainer
                        cToken={this.props.cToken}
                        apps={this.state.apps}
                        groupAppInfo={this.props.groupAppInfo}
                        documentsToBeSigned={true}
                        setHasWetSignNeededDocuments={this.setHasWetSignNeededDocuments}
                    />
                </Fragment>
            );
        }

        let shouldShowOpayoEmail: boolean = false;
        if (this.props.appInfo && this.props.appInfo.brand && this.props.appInfo.brand === "OPAYO") {
            shouldShowOpayoEmail = true;
        } else if (
            this.props.groupAppInfo &&
            this.props.groupAppInfo.appInfo.brand &&
            this.props.groupAppInfo.appInfo.brand === "OPAYO"
        ) {
            shouldShowOpayoEmail = true;
        }

        const opayoEmailOrAllOfTheDocsString = shouldShowOpayoEmail ? (
            "All the above documents must be in date, clearly legible and sent to sales@Opayo.com"
        ) : (
            <Trans>all_of_the_above_documents</Trans>
        );

        const conditionalApprovalNeededDocuments =
            this.props.appInfo && this.props.appInfo!.decision === "CONDITIONAL_APPROVAL" ? (
                <Fragment>
                    <Typography>
                        <Trans>in_order_to_complete_the_application</Trans>
                    </Typography>
                    <div>
                        <ul>
                            <li>
                                <Trans>a_recent_utility_bill</Trans>
                            </li>
                        </ul>
                        <b>
                            <span>
                                <Trans>and_one</Trans>
                            </span>
                        </b>
                        <Trans>of_the_following</Trans>
                        <ul>
                            <li>
                                <Trans>copy_of_passport</Trans>
                            </li>
                            <li>
                                <Trans>copy_of_drivers_licence</Trans>
                            </li>
                            <li>
                                <Trans>copy_of_government_identity_card</Trans>
                            </li>
                        </ul>
                        <i>{opayoEmailOrAllOfTheDocsString}</i>
                    </div>
                    <br />
                </Fragment>
            ) : (
                undefined
            );

        const documentsToSignBoardingDecisionWhatsnext =
            this.props.appInfo && this.props.appInfo!.decision === "CONDITIONAL_APPROVAL" ? (
                <Trans>boarding_decision_whatsnext_signeddocuments_1b</Trans>
            ) : (
                <Trans>boarding_decision_whatsnext_signeddocuments_1</Trans>
            );

        const documentsToSignText = this.state.hasWetSignNeededDocuments ? (
            <Fragment>
                {documentsToSignBoardingDecisionWhatsnext}{" "}
                <em>
                    <Trans>boarding_decision_signatureTitle</Trans>
                </em>
                <br />
                <u>
                    <Trans>boarding_decision_whatsnext_signeddocuments_2</Trans>
                </u>
            </Fragment>
        ) : (
            undefined
        );

        // always using prefix aws because this is asways assistd signup ?
        let moreToDoText;
        if ((this.props.appInfo && this.props.appInfo!.decision === "CONDITIONAL_APPROVAL") || this.state.hasWetSignNeededDocuments) {
            if (this.props.appInfo && this.props.appInfo!.decision === "APPROVAL_STAGING") {
                moreToDoText = (
                    <Fragment>
                        <br />
                        <Trans>aws_boarding_decision_whatsnext_MTD_AS</Trans>
                    </Fragment>
                );
            } else {
                moreToDoText = (
                    <Fragment>
                        <br />
                        <Trans>aws_boarding_decision_whatsnext_MTD_CA_RS_SS</Trans>
                    </Fragment>
                );
            }
        } else {
            if (this.props.appInfo && this.props.appInfo!.decision === "APPROVAL_STAGING") {
                moreToDoText = (
                    <Fragment>
                        <br />
                        <Trans>aws_boarding_decision_whatsnext_NMTD_AS</Trans>
                    </Fragment>
                );
            } else {
                moreToDoText = (
                    <Fragment>
                        <br />
                        <Trans>aws_boarding_decision_whatsnext_NMTD_CA_RS_SS</Trans>
                    </Fragment>
                );
            }
        }
        const euWhatsNextText =
            this.state.region === Region.EU ? (
                <Fragment>
                    <Typography variant="h6">
                        <Trans>boarding_decision_body_title_3</Trans>
                    </Typography>
                    {conditionalApprovalNeededDocuments}
                    {documentsToSignText}
                    {moreToDoText}
                </Fragment>
            ) : (
                undefined
            );
        const supportText = (
            <Fragment>
                <Typography variant="h6">
                    {this.props.country === 'CAN' ? 
                    <Trans>boarding_decision_body_title_2_can</Trans> :
                    <Trans>boarding_decision_body_title_2</Trans>}
                </Typography>
                <Typography variant="body1">
                    <Trans>boarding_decision_body2_content_1</Trans>{" "}
                    <a href="javascript:void(0)" onClick={this.props.toggleContactUsModal} className={classes.link}>
                        <Trans>boarding_decision_body2_content_2</Trans>
                    </a>
                </Typography>
                <br />
            </Fragment>
        );

        if (this.state.loading) {
            return (
                <Grid item={true}>
                    <SignaturePageLoading />
                </Grid>
            );
        }
        if (this.props.appInfo && this.props.appInfo!.thirdParty) {
            return (
                <Card style={{ padding: "24px", marginTop: "32px" }}>
                <CardContent>
                    <Grid item={true} xs={12} direction={"row"} container={true}>
                        <Grid item={true} md={7} xs={12} style={{ paddingRight: "24px" }}>
                            <Typography variant="h6">
                                    <Trans>application_status</Trans>
                                </Typography>
                            <Typography variant="body1">
                            <Trans>application_name</Trans> : {this.props.appInfo!.businessName}<br/>
                            <Trans>application_reference_number</Trans> : {this.props.appInfo!.boardingId}<br/>
                            <Trans>status</Trans> : {this.props.appInfo!.decision}
                            </Typography>
                            <br />
                        </Grid>
                        <Grid item={true} md={12} xs={12}>
                            {documentsToBeSigned}
                            {documentsToReview}
                        </Grid>
                    </Grid>
                    <div className={classes.centerContent}>
                        <FormControl>
                            <Button variant="contained" color="primary" type="submit" onClick={this.onSubmit}>
                                <ChevronLeft />
                                <Trans>Return_to_Application_List</Trans>
                            </Button>
                        </FormControl>
                    </div>
                </CardContent>
            </Card>
            );
        }
        if (this.props.groupAppInfo && this.props.groupAppInfo.thirdParty) {
            return (
                <Card style={{ padding: "24px", marginTop: "32px" }}>
                <CardContent>
                    <Grid item={true} xs={12} direction={"row"} container={true}>
                        <Grid item={true} md={12} xs={12}>
                            {documentsToReview}
                        </Grid>
                        <Grid item={true} md={12} xs={12}>
                            {documentUpload}
                        </Grid>
                    </Grid>
                    <div className={classes.centerContent}>
                        <FormControl>
                            <Button variant="contained" color="primary" type="submit" onClick={this.onSubmit}>
                                <ChevronLeft />
                                <Trans>Return_to_Application_List</Trans>
                            </Button>
                        </FormControl>
                    </div>
                </CardContent>
            </Card>
            );
        }

        return (
            <Card style={{ padding: "24px", marginTop: "32px" }}>
                {this.props.country === 'CAN' ?
                <CardHeader title={i18next.t("boarding_decision_header_title_can")} style={{ textAlign: "center" }} component="h1"/> :
                <CardHeader title={i18next.t("boarding_decision_header_title")} style={{ textAlign: "center" }} component="h1"/>
                }
                <CardContent>
                    <Typography variant="subtitle1">
                        {subtext1}
                        {referenceNumber} {this.props.country === 'CAN' ?
                            <Trans>boarding_decision_header_body_3_can</Trans> :
                            <Trans>boarding_decision_header_body_3</Trans>}
                    </Typography>
                    <br />
                    <Grid item={true} xs={12} direction={"row"} container={true}>
                        <Grid item={true} md={7} xs={12} style={{ paddingRight: "24px" }}>
                            {euWhatsNextText}
                            <Typography variant="h6">
                                <Trans>boarding_decision_body_title_1</Trans>
                            </Typography>
                            <Typography variant="body1">
                                <Trans>boarding_decision_body1_content_1</Trans> <Trans>boarding_decision_body1_content_2</Trans>
                            </Typography>
                            <br />
                            {supportText}
                            {documentUpload}
                        </Grid>
                        <Grid item={true} md={5} xs={12}>
                            {documentsToBeSigned}
                            {documentsToReview}
                        </Grid>
                        <Paper elevation={0} className={classes.feedbackBox}>
                            <Typography variant="h6">
                                <img src={feedbackIcon} alt="feedback" style={{ marginBottom: "-4px" }} />{" "}
                                <Trans>boarding_decision_survey_title</Trans>
                            </Typography>
                            <Typography variant="body1">
                                <Trans>boarding_decision_survey_body_1</Trans>
                                <br />
                                <a href="https://elavon.us2.qualtrics.com/SE/?SID=SV_e38iER6SKhDExNz" className={classes.link}>
                                    <Trans>boarding_decision_survey_body_2</Trans>
                                </a>
                            </Typography>
                        </Paper>
                    </Grid>
                    <div className={classes.centerContent}>
                        <FormControl>
                            <Button variant="contained" color="primary" type="submit" onClick={this.onSubmit}>
                                <ChevronLeft />
                                <Trans>Return_to_Application_List</Trans>
                            </Button>
                        </FormControl>
                    </div>
                </CardContent>
            </Card>
        );
    }

    private setHasWetSignNeededDocuments(hasWetSignNeededDocuments: boolean) {
        this.setState({
            hasWetSignNeededDocuments,
        });
    }

    private onSubmit() {
        this.props.clearSkipToApp();
        this.props.history.push("/applist");
    }

    private setWaitStatus(appId: number, isThirdPartyApp: any) {
        if (shouldCheckWaitStatus(this.props.appInfo, this.props.groupAppInfo)) {
            checkWaitStatus(appId, isThirdPartyApp, this.props.cToken).subscribe((response: ShouldShowWaitPageResponse) => {
                if (response.showWaitPage) {
                    this.props.history.push("/updateinprogress");
                }
            });
        }
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(pageStyles)(ApplicationComplete));
