import { Card, CardContent, CardHeader } from "@material-ui/core";
import * as React from "react";

// TODO: Replace strings with string keys. Should also have them translated.
export default class SignaturePageESignFailure extends React.Component {
    public render() {
        return (
            <Card>
                <CardHeader title="Application Submission in Progress" role="heading" aria-level={1} />
                <CardContent>
                    <div>
                        <p>Your application has already been submitted and is currently being processed.</p>
                        <p>Please come back later or contact your sales agent for support.</p>
                    </div>
                </CardContent>
            </Card>
        );
    }
}
