import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import CustomerFlow from "../../components/customer/CustomerFlow";
import { CustomerAppState } from "../../reducers/customer_ui";

const mapStateToProps = (state: CustomerAppState) => ({
    showCookieModal: state.rest.showCookieModal,
    isLoggedIn: state.auth.loggedIn,
    authenticationDestination: state.rest.destination,
    country: state.JurisdictionInfo.country,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        enableCookies: () => {
            dispatch({ type: "SET_COOKIES", payload: true });
        },
        toggleCookieModal: () => {
            dispatch({ type: "TOGGLE_COOKIE_MODAL" });
        },
        setSkipToApp: () => {
            dispatch({ type: "SET_SKIP_TO_APP" });
        },
    };
};

export const WithoutRouter = connect(mapStateToProps, mapDispatchToProps)(CustomerFlow);

export default withRouter(WithoutRouter as any);
