// Formik and Enzyme are incompatible, which explains some of the very strange sidestepping of istanbul and ts-lint present in this file.

import { Typography , CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { StyledComponentProps, StyleRules, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { Formik, FormikProps } from "formik";
import i18next from "i18next";
import * as React from "react";
import { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import Month from "../../models/Month";
import { areCookiesEnabled } from "../../services/CookieService";
import RegexService from "../../services/RegexService";
import ScarecrowPropertiesService from "../../services/ScarecrowPropertiesService";
import Classnames from "classnames";

export interface RegistrationSubmissionValues {
    type: number;
    password: string;
    confirmationCode?: string; // when type === 1
    phoneLast4?: string; // when type === 2
    birthMonth?: string; // when type === 2
    birthYear?: string; // when type === 2
    birthDay?: string; // when type === 2
}

interface BirthDateComponents {
    birthYear: string;
    birthMonth: string;
    birthDay: string;
}

interface State {
    passwordMinLength: number;
}

interface Props {
    pending: boolean;
    isType2Verification: boolean;
    country: string;
    confirmationCode?: string;
    areCookiesAccepted: boolean;
    emailAddress: string;
    toggleCookieModal: () => void;
    onSubmit: (values: RegistrationSubmissionValues) => void;
}

const styles: StyleRules = {
    form: {
        padding: "0px",
        display: "flex",
        alignItems: "left",
        justifyContent: "space-around",
        flexDirection: "column",
    },
    formControl: {
        marginTop: "18px",
        marginBottom: "4px",
        minWidth: "200px",
    },
    subtitle1: {
        color: "#2b7bb9",
        fontSize: "20px",
        fontWeight: 400,
    },
    text: {
        paddingTop: "16px",
        fontSize: "16px",
        paddingLeft: "40px",
    },
    body1: {
        paddingTop: "10px",
        fontSize: "12px",
        fontWeight: "normal",
        color: "rgba(0, 0, 0, 0.87)",
    },
    wordWrap: {
        wordWrap: 'break-word',
    },
};

class CustomerFlowRegistrationForm extends Component<Props & StyledComponentProps, State> {
    private initialValues;

    constructor(props) {
        super(props);

        this.validate = this.validate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            passwordMinLength: 14,
        };

        this.initialValues = {
            createPass: "",
            confirmPass: "",
        };

        if (this.props.isType2Verification) {
            this.initialValues.dob = "";
            this.initialValues.phone = "";
        } else {
            this.initialValues.confCode = this.props.confirmationCode || "";
        }
    }

    public componentDidMount() {
        ScarecrowPropertiesService.subscribe({
            next: (function(props) {
                this.setState({
                    passwordMinLength: props.passwordMinLength,
                });
            }).bind(this),
        });
    }

    public render() {
        const classes = this.props.classes!;
        const minLength = Number(this.state.passwordMinLength);

        if ((this.props.country === "CAN" || this.props.country === "USA")) {
            return (
                <Formik initialValues={this.initialValues} validate={this.validate} onSubmit={this.onSubmit}>
                    {(formik) => (
                        <form onSubmit={formik.handleSubmit} className={classes.form}>
                            {this.generateRegionalFields(classes, formik)}
                            <Grid container={true} xs={12} md={12} direction={"row"} item={true} >
                                <Grid item={true} container={true} md={4} xs={12} direction={"column"}
                                    style={{ paddingRight: "2px" }}>
                                    <Grid className={classes.passwordInput}>
                                        <Grid item={true} md={4}>
                                            <FormControl className={classes.formControl}>
                                                <TextField
                                                    id="createPass"
                                                    label={<Trans i18nKey={"password"} />}
                                                    type="password"
                                                    value={formik.values.createPass}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={/* istanbul ignore next */ formik.touched.createPass
                                                        && !!formik.errors.createPass}
                                                    helperText={/* istanbul ignore next */ formik.touched.createPass
                                                        && formik.errors.createPass}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} md={4}>
                                            <FormControl className={classes.formControl}>
                                                <TextField
                                                    id="confirmPass"
                                                    label={<Trans i18nKey={"passwordTwo"} />}
                                                    type="password"
                                                    value={formik.values.confirmPass}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={/* istanbul ignore next */ formik.touched.confirmPass
                                                        && !!formik.errors.confirmPass}
                                                    helperText={/* istanbul ignore next */ formik.touched.confirmPass
                                                        && formik.errors.confirmPass}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} md={4}>
                                            <FormControl className={classes.formControl}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={!formik.isValid || !formik.dirty}>
                                                    <Trans i18nKey={"submit"} />
                                                    {this.props.pending ? <CircularProgress variant="indeterminate"
                                                        size={15} /> : <ChevronRight />}
                                                </Button>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item={true} container={true} md={7} xs={12} direction={"column"}>
                                    <Grid className={classes.text} md={7}>
                                        <Typography variant="subtitle1" className={classes.subtitle1}>
                                            <h5><strong><Trans>password_requirements</Trans></strong></h5>
                                        </Typography>
                                        <Typography variant="body1" className={Classnames(classes.body1, classes.wordWrap)}>
                                            <ul>
                                                <h5><li><Trans>password_minlength_error</Trans></li></h5>
                                                <h5><li><Trans>passwords_dont_match_error</Trans></li></h5>
                                                <h5><li><Trans>password_three_categories</Trans></li></h5>
                                                <ul>
                                                    <h5><li><Trans>password_digits</Trans></li></h5>
                                                    <h5><li><Trans>password_uppercase</Trans></li></h5>
                                                    <h5><li><Trans>password_lowercase</Trans></li></h5>
                                                    <h5><li><Trans>password_special_characters</Trans></li></h5>
                                                </ul>
                                            </ul>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            );
        } else {
            return (
                <Formik initialValues={this.initialValues} validate={this.validate} onSubmit={this.onSubmit}>
                    {(formik) => (
                        <form onSubmit={formik.handleSubmit} className={classes.form}>
                            {this.generateRegionalFields(classes, formik)}
                            <Tooltip title={i18next.t("password_tooltip", { minLength })} enterTouchDelay={1} interactive={true}>
                                <Grid item={true} xs={12}>
                                    <FormControl className={classes.formControl}>
                                        <TextField
                                            id="createPass"
                                            label={<Trans i18nKey={"password"} />}
                                            type="password"
                                            value={formik.values.createPass}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={/* istanbul ignore next */ formik.touched.createPass && !!formik.errors.createPass}
                                            helperText={/* istanbul ignore next */ formik.touched.createPass && formik.errors.createPass}
                                        />
                                    </FormControl>
                                </Grid>
                            </Tooltip>
                            <Grid item={true} xs={12}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        id="confirmPass"
                                        label={<Trans i18nKey={"passwordTwo"} />}
                                        type="password"
                                        value={formik.values.confirmPass}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={/* istanbul ignore next */ formik.touched.confirmPass && !!formik.errors.confirmPass}
                                        helperText={/* istanbul ignore next */ formik.touched.confirmPass && formik.errors.confirmPass}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <FormControl className={classes.formControl}>
                                    <Button variant="contained" color="primary" type="submit" disabled={!formik.isValid || !formik.dirty}>
                                        <Trans i18nKey={"submit"} />
                                        {this.props.pending ? <CircularProgress variant="indeterminate" size={15} /> : <ChevronRight />}
                                    </Button>
                                </FormControl>
                            </Grid>
                        </form>
                    )}
                </Formik>
            );
        }
    }

    // EU validates customers differently than NA.
    private generateRegionalFields(classes: Partial<Record<string, string>>, formik: FormikProps<any>): React.JSX.Element {

        const phoneNumberText = this.getPhoneNumberText();

        if (!this.props.isType2Verification) {
            if(this.props.confirmationCode) {
                return (
                    <Grid item={true} md={4} xs={12}>
                            <TextField
                                type="hidden"
                                id="confCode"
                                value={this.props.confirmationCode}
                            />
                    </Grid>
                );
            } else {
                return (
                    <Grid item={true} md={4} xs={12}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="confCode"
                                label={<Trans i18nKey={"confirmation_code"} />}
                                value={this.props.confirmationCode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={/* istanbul ignore next */ formik.touched.confCode && !!formik.errors.confCode}
                                helperText={/* istanbul ignore next */ formik.touched.confCode && formik.errors.confCode}
                            />
                        </FormControl>
                    </Grid>
                );
            }
        } else {
            return (
                <Fragment>
                    <Grid item={true} md={4} xs={12}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="dob"
                                label={<Trans i18nKey={"date_of_birth"} />}
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={formik.values.dob}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={/* istanbul ignore next */ formik.touched.dob && !!formik.errors.dob}
                                helperText={/* istanbul ignore next */ formik.touched.dob && formik.errors.dob}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item={true} md={4} xs={12}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="phone"
                                label={<Trans i18nKey={phoneNumberText} />}
                                type="string"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={/* istanbul ignore next */ formik.touched.phone && !!formik.errors.phone}
                                helperText={/* istanbul ignore next */ formik.touched.phone && formik.errors.phone}
                            />
                        </FormControl>
                    </Grid>
                </Fragment>
            );
        }
    }

    private validate(values) {
        let errors = {};
        if (!this.props.isType2Verification) {
            if (values.confCode === "") {
                errors = { ...errors, confCode: i18next.t("required_field") };
            }
        } else {
            if (values.dob === "") {
                errors = { ...errors, dob: i18next.t("required_field") };
            }

            errors = { ...errors, ...this.validatePhoneNumber(values.phone) };
        }

        errors = { ...errors, ...this.validatePassword(values.createPass) };

        if (values.confirmPass !== values.createPass) {
            errors = { ...errors, confirmPass: i18next.t("password_fields_must_match") };
        }

        return errors;
    }

    private validatePhoneNumber(phone: string) {
        let error = {};

        if (phone === "") {
            error = { phone: i18next.t("required_field") };
        } else if (phone.length > 4) {
            error = { phone: i18next.t("max_length", { max: 4 }) };
        } else if (phone.length < 4) {
            error = { phone: i18next.t("min_length", { min: 4 }) };
        }

        return error;
    }

    private validatePassword(password: string) {
        let error = {};

        const max = 128;
        const minLength = Number(this.state.passwordMinLength);

        if (password === "") {
            error = { createPass: i18next.t("required_field") };
        } else if (password.length > max) {
            error = { createPass: i18next.t("max_length", { max }) };
        } else if (password.toLowerCase().indexOf("password") !== -1) {
            error = { createPass: i18next.t("password_cannot_contain_password") };
        } else if (!RegexService.validPasswordCharsRegex.test(password)) {
            error = { createPass: i18next.t("password_invalid_characters") };
        } else if (!this.satisfiesPasswordCharCategories(password)) {
            error = { createPass: i18next.t("password_complexity_error") };
        } else if (this.containsEmailSubstring(password)) {
            error = { createPass: i18next.t('password_cannot_contain_username') };
        } else if (password.length < minLength) {
            error = { createPass: i18next.t("min_length", { minLength }) };
        }

        return error;
    }

    private containsEmailSubstring(password: string): boolean {
        const PASSWORD_TOKEN_DELIMITERS: RegExp = new RegExp(/[._\-@]/);
        const PASSWORD_TOKEN_MIN_LENGTH: number = 3;
        const { emailAddress = '' } = this.props;

        const substrings = (emailAddress.toLowerCase())
            .split(PASSWORD_TOKEN_DELIMITERS)
            .filter((substring) => substring.length >= PASSWORD_TOKEN_MIN_LENGTH);
        return substrings.some((substring) => (password.toLowerCase()).includes(substring));
    }

    private satisfiesPasswordCharCategories(password: string): boolean {
        let count = 0;
        if (RegexService.digitsRegex.test(password)) {
            count++;
        }
        if (RegexService.lowercaseRegex.test(password)) {
            count++;
        }
        if (RegexService.uppercaseRegex.test(password)) {
            count++;
        }
        if (RegexService.specialCharsRegex.test(password)) {
            count++;
        }
        return count >= 3;
    }

    private onSubmit(values) {
        if ((this.props.country !== "CAN" && this.props.country !== "USA") && (!areCookiesEnabled() || !this.props.areCookiesAccepted)) {
            this.props.toggleCookieModal();
        } else {
            const registrationResult: RegistrationSubmissionValues = {
                password: values.confirmPass,
                type: this.props.isType2Verification ? 2 : 1,
            };

            if (this.props.isType2Verification) {
                registrationResult.phoneLast4 = values.phone;

                const parsedBirthday = this.parseDateOfBirth(values.dob);
                registrationResult.birthMonth = parsedBirthday.birthMonth;
                registrationResult.birthDay = parsedBirthday.birthDay;
                registrationResult.birthYear = parsedBirthday.birthYear;
            } else {
                registrationResult.confirmationCode = values.confCode;
            }

            this.props.onSubmit(registrationResult);
        }
    }

    private parseDateOfBirth(dob: string): BirthDateComponents {
        const dobParts = dob.split("-"); // yyyy-mm-dd
        const parsedDOB: BirthDateComponents = {
            birthYear: dobParts[0],
            birthMonth: Month[Number(dobParts[1])],
            birthDay: dobParts[2],
        };
        return parsedDOB;
    }

    private getPhoneNumberText() {
        switch (new URLSearchParams(window.location.search).get('rn')) {
            case 'ATHENA_HEALTH':
                return 'set_password.office_phone_number_last_4';
            default:
                return 'set_password.phone_number_last_4';
        }
    }
}

export default withStyles(styles)(CustomerFlowRegistrationForm);
