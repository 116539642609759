import App, { LogicalAppStatus } from "./App";
import GroupInfo from "./GroupInfo";

export default interface GroupApp {
    registrationDetailList?: [];
    appInfo: App;
    groupInfo: GroupInfo;
    groupStatus?: any;
    index: number;
    merchantAccountCreated?: boolean;
    readyForTurnOver?: boolean;
    logicalStatus: LogicalAppStatus;
    id: string;
    thirdParty?: boolean;
}

export function isGroupApp(app: any): app is GroupApp {
    return "groupInfo" in app;
}
