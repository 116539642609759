import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid,
    IconButton, MenuItem, Select, TextField,
} from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import i18next from "i18next";
import * as React from "react";
import { Trans } from "react-i18next";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { SalesPersonContact } from "../../models/SalesPersonContact";
import ARHttp from "../../services/ARHttp";

const styles = (theme) => createStyles({
    form: {
        padding: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column",
    },
    formControl: {
        marginTop: "18px",
        marginBottom: "4px",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            minWidth: "300px",
        },
    },
    buttonContainer: {
        width: "fit-content",
        alignSelf: "flex-end",
        paddingRight: "25px",
        marginBottom: "1rem",
    },
    ["@media (max-width: 766px)"]: {
        modalContainer: {
            position: "absolute",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            outline: "none",
            margin: "16px",
        },
    },
    ["@media (min-width: 767px)"]: {
        modalContainer: {
            position: "absolute",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            outline: "none",
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
        },
        thirdPartyModalContainer: {
            margin: "3em 5em 1em",
        },
    },
});

interface Props extends WithStyles {
    onClose: () => void;
    onSubmit: (values) => void;
    country?: string;
    referrer?: string;
    brand?: string;
    open: boolean;
    isThirdParty: boolean;
    salesPersonContact: SalesPersonContact
}

class CustomerFlowContactUs extends React.Component<Props, any> {
    private initialValues;

    constructor(props) {
        super(props);

        this.state = {
            reponse: "",
        };

        this.validate = this.validate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.initialValues = {
            supportEmail: "Support",
            email: "",
            subjectLine: "",
            body: "",
        };
    }

    public render() {
        const { classes, country, referrer, open, onClose } = this.props;

        const ukOpayoPhoneNumber = "0845 111 44 66";
        const irishOpayoPhoneNumber = "01 2311 777";
        const keyBankPhoneNumber = "1-866-644-8274";
        const phoneNumbers = {
            USA: "1-877-326-7989",
            GBR: "0800 028 1662",
            IRL: "1800 995 085",
            POL: "22 381 03 16",
            DNK: "+45 38 48 74 88",
            SWE: "+46 8 593 66 467",
            NOR: "+47 24 15 99 19",
            FIN: "+46 859 366 467",
        };

        let phoneNumber;
        if (country) {
            if (referrer === "OPAYO") {
                if (country === "GBR") {
                    phoneNumber = ukOpayoPhoneNumber;
                } else if (country === "IRL") {
                    phoneNumber = irishOpayoPhoneNumber;
                }
            } else if (referrer === "KEY_BANK") {
                phoneNumber = keyBankPhoneNumber;
            } else {
                phoneNumber = phoneNumbers[country];
            }
        }

        if (this.props.isThirdParty) {
            if (this.props.salesPersonContact) {
                return (
                    <Dialog id="contactModal"
                            open={open}
                            disableBackdropClick={true}
                            disableEscapeKeyDown={false}
                            aria-labelledby="dialogTitle"
                            onRendered={this.setAttributesOnRendered}>
                        <DialogTitle className={classes.thirdPartyModalContainer} id="dialogTitle">
                            <Trans>third_party_assistance_text</Trans>
                            <br/>
                            {this.getSalesAgentName(this.props.salesPersonContact)}
                            {this.getSalesPhoneNumber(this.props.salesPersonContact)}
                            {this.getSalesEmail(this.props.salesPersonContact)}
                        </DialogTitle>
                        <div className={classes.buttonContainer}>
                            <Button variant="contained" color="primary" endIcon={<CloseIcon/>} onClick={onClose} style={{width: "150px"}}>
                                <Trans>cookie_banner_close</Trans>
                            </Button>
                        </div>
                    </Dialog>
                );
            } else {
                return (
                    <Dialog id="contactModal"
                            open={open}
                            disableBackdropClick={true}
                            disableEscapeKeyDown={false}
                            aria-labelledby="dialogTitle">
                        <DialogTitle className={classes.thirdPartyModalContainer} id="dialogTitle">
                            <Trans>third_party_assistance_text_no_sales_contact</Trans>
                        </DialogTitle>
                        <div className={classes.buttonContainer}>
                            <Button variant="contained" color="primary" endIcon={<CloseIcon/>} onClick={onClose} style={{width: "150px"}}>
                                <Trans>cookie_banner_close</Trans>
                            </Button>
                        </div>
                    </Dialog>
                );
            }

        }

        return (
            <Formik initialValues={this.initialValues} validate={this.validate} onSubmit={this.onSubmit}>
                {(formik) => (
                    <Dialog id="contactModal"
                            open={open}
                            disableBackdropClick={true}
                            disableEscapeKeyDown={false}
                            aria-labelledby="dialogTitle"
                            onRendered={this.setAttributesOnRendered}>
                        <DialogTitle id="dialogTitle">
                            <Trans>contact_us</Trans>
                            {onClose ? (
                                <IconButton aria-label="Close" onClick={onClose} style={{ float: "right", padding: "0px" }}>
                                    <CloseIcon />
                                </IconButton>
                            ) : null}
                        </DialogTitle>
                        <form onSubmit={formik.handleSubmit}>
                            <DialogContent>
                                <Grid item={true} xs={12}>
                                    <FormControl className={classes.formControl}>
                                        <Select value={formik.values.supportEmail} onChange={formik.handleChange} name="supportEmail">
                                            <MenuItem value={"Support"}>
                                                <Trans>contact_Support</Trans>
                                            </MenuItem>
                                            <MenuItem value={"Sales"}>
                                                <Trans>contact_Sales</Trans>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <FormControl className={classes.formControl}>
                                        <TextField
                                            id="contactFormEmail"
                                            label={i18next.t("reply_To_Email_Address")}
                                            type="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.email && !!formik.errors.email}
                                            helperText={
                                                formik.touched.email && formik.errors.email ? (
                                                    <Trans i18nKey={formik.errors.email as string} values={{ max: 55 }} />
                                                ) : (
                                                    undefined
                                                )
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <FormControl className={classes.formControl}>
                                        <TextField
                                            id="subjectLine"
                                            label={i18next.t("email_Subject")}
                                            type="text"
                                            value={formik.values.subjectLine}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.subjectLine && !!formik.errors.subjectLine}
                                            helperText={
                                                formik.touched.subjectLine && formik.errors.subjectLine ? (
                                                    <Trans i18nKey={formik.errors.subjectLine as string} values={{ max: 55 }} />
                                                ) : (
                                                    undefined
                                                )
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <FormControl className={classes.formControl}>
                                        <TextField
                                            id="body"
                                            label={i18next.t("email_Message_Body")}
                                            type="text"
                                            multiline={true}
                                            rows={4}
                                            value={formik.values.body}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.body && !!formik.errors.body}
                                            helperText={
                                                formik.touched.body && formik.errors.body ? (
                                                    <Trans i18nKey={formik.errors.body as string} values={{ max: 1000 }} />
                                                ) : (
                                                    undefined
                                                )
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="primary" type="submit" disabled={this.shouldDisableSubmit(formik)}>
                                    <Trans i18nKey={"submit"} />
                                </Button>
                                <Grid>{this.state.response}</Grid>
                            </DialogActions>
                            <Divider />
                            {phoneNumber ? <DialogActions>
                                <Button variant="contained" color="primary">
                                    {i18next.t("call") + " "} {phoneNumber}
                                </Button>
                            </DialogActions> : <></>}
                        </form>
                    </Dialog>
                )}
            </Formik>
        );
    }

    private validate(values) {
        return {
            ...this.validatesubjectLine(values.subjectLine),
            ...this.validateBody(values.body),
            ...this.validateEmail(values.email),
        };
    }

    private validatesubjectLine(subject: string) {
        let error = {};

        const max = 55;

        if (subject === "") {
            error = { subjectLine: "required_field" };
        } else if (subject.length > max) {
            error = { subjectLine: "max_length" };
        }
        return error;
    }

    private validateBody(bodyLine: string) {
        let error = {};

        const max = 1000;

        if (bodyLine === "") {
            error = { body: "required_field" };
        } else if (bodyLine.length > max) {
            error = { body: "max_length" };
        }
        return error;
    }

    private validateEmail(email: string) {
        let error = {};
        // eslint-disable-next-line max-len
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email === "") {
            error = { email: "required_field" };
        } else if (email.length > 55) {
            error = { email: "max_length" };
        } else if (!re.test(String(email).toLocaleLowerCase())) {
            error = { email: "invalid_email" };
        }
        return error;
    }

    private shouldDisableSubmit(formik) {
        return !formik.isValid || !formik.dirty;
    }

    private onSubmit(formik) {
        ARHttp({
            method: "POST",
            url: "/scwebapi/contactsupport",
            body: {
                subject: formik.subjectLine,
                body: formik.body,
                senderEmailAddress: formik.email,
                qualifier: formik.supportEmail,
            },
        })
            .pipe(
                catchError((error) => {
                    this.setState({ response: i18next.t("contactUs_Error") });
                    return of(error);
                }),
            )
            .subscribe((res) => this.handleServerResponse);
    }

    private getSalesAgentName(salesPersonContact: SalesPersonContact) {
        if (salesPersonContact.name && salesPersonContact.name.firstName && salesPersonContact.name.lastName) {
            const salesPersonName = salesPersonContact.name.firstName + " " + salesPersonContact.name.lastName;
            return (<React.Fragment>{salesPersonName} <br/></React.Fragment>);
        }
        return null;
    }

    private getSalesPhoneNumber(salesPersonContact: SalesPersonContact) {
        if (salesPersonContact.phone && salesPersonContact.phone.number) {
            return (<React.Fragment>{salesPersonContact.phone.number} <br/></React.Fragment>);
        }
        return null;
    }

    private getSalesEmail(salesPersonContact: SalesPersonContact) {
        if (salesPersonContact.emailAddress) {
            return(<React.Fragment>{salesPersonContact.emailAddress} <br/></React.Fragment>);
        }
        return null;
    }

    private handleServerResponse(response) {
        if (response.responseId !== 0) {
            this.setState({ response: i18next.t("contactUs_Error") });
        } else {
            this.setState({ response: i18next.t("contactUs_Success") });
        }
        return false;
    }

    private setAttributesOnRendered() {
        const modalContainer = document.querySelector("#contactModal > div.MuiDialog-container.MuiDialog-scrollPaper") as any;
        if (modalContainer) {
            modalContainer.setAttribute("role", "presentation");
        }
    }
}
export default withStyles(styles)(CustomerFlowContactUs);
