import { FormControl, Grid, InputLabel, RadioGroup } from "@material-ui/core";
import * as React from "react";
import { GetQuizResponse, QuizQuestion } from "../../services/EIDQuizService";
import EIDQuizChoice from "./EIDQuizChoice";

interface Props {
    quiz?: GetQuizResponse;
    handleChange: (questionNumber: number, event: any) => void;
    quizAnswers: { [quizAnswerIndex: number]: string };
}

export default class EIDQuizQuestion extends React.Component<Props> {
    constructor(props) {
        super(props);
    }

    public render() {
        const { quiz, quizAnswers, handleChange } = this.props;
        if (quiz) {
            return (
                <React.Fragment>
                    {quiz.quizQuestions.map((quizQuestion: QuizQuestion) => {
                        return (
                            <Grid key={quizQuestion.questionNumber} xs={12} sm={12} md={12} lg={12} item={true}>
                                <div>
                                    <InputLabel>{quizQuestion.questionText}</InputLabel>
                                </div>
                                <FormControl>
                                    <RadioGroup
                                        value={"false"}
                                        id={"quizQuestion_" + quizQuestion.questionNumber}>
                                        <EIDQuizChoice
                                            quizQuestion={quizQuestion}
                                            quizAnswers={quizAnswers}
                                            handleChange={handleChange}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        );
                    })}
                </React.Fragment>
            );
        }
        return <div />;
    }
}
