import ProvinceAbreviatedClass from "./ProvinceAbreviatedClass";

enum StatesAbreviatedClass {
    AL = "USA_AL",
    AK = "USA_AK",
    AZ = "USA_AZ",
    AR = "USA_AR",
    CA = "USA_CA",
    CO = "USA_CO",
    CT = "USA_CT",
    DE = "USA_DE",
    DC = "USA_DC",
    FL = "USA_FL",
    GA = "USA_GA",
    HI = "USA_HI",
    ID = "USA_ID",
    IL = "USA_IL",
    IN = "USA_IN",
    IA = "USA_IA",
    KS = "USA_KS",
    KY = "USA_KY",
    LA = "USA_LA",
    ME = "USA_ME",
    MD = "USA_MD",
    MA = "USA_MA",
    MI = "USA_MI",
    MN = "USA_MN",
    MS = "USA_MS",
    MO = "USA_MO",
    MT = "USA_MT",
    NE = "USA_NE",
    NV = "USA_NV",
    NH = "USA_NH",
    NJ = "USA_NJ",
    NM = "USA_NM",
    NY = "USA_NY",
    NC = "USA_NC",
    ND = "USA_ND",
    OH = "USA_OH",
    OK = "USA_OK",
    OR = "USA_OR",
    PA = "USA_PA",
    RI = "USA_RI",
    SC = "USA_SC",
    SD = "USA_SD",
    TN = "USA_TN",
    TX = "USA_TX",
    UT = "USA_UT",
    VT = "USA_VT",
    VA = "USA_VA",
    WA = "USA_WA",
    WV = "USA_WV",
    WI = "USA_WI",
    WY = "USA_WY",
}

export function getCountryFromState(state: StatesAbreviatedClass | ProvinceAbreviatedClass) {
    const stateComponents = state.split("_");
    if (stateComponents.length !== 2) {
        throw new Error("Unable to get country from state enum");
    }
    return stateComponents[0];
}

export default StatesAbreviatedClass;
