import { createStyles, Grid, IconButton, withStyles, WithStyles } from "@material-ui/core";
import { DeleteForeverOutlined } from "@material-ui/icons";
import * as React from "react";
import ApplicationDocumentService from "../../services/ApplicationDocumentService";

const style = (theme) => createStyles({
    deleteButton: {
        position: "absolute",
        right: "5px",
        top: "-3px",
    },
});

interface Props extends WithStyles {
    appId: number;
    cToken: string;
    documentLink: any;
    setAppError: () => void;
    listDocuments: (appId: number) => any;
    isThirdPartyApp?: boolean;
    shouldHideRemoveDocument?: boolean;
}

class CustomerDocumentLink extends React.Component<Props, any> {
    constructor(props) {
        super(props);

        this.removeDocuments = this.removeDocuments.bind(this);
    }

    public render() {
        const { docURL, description } = this.props.documentLink;
        return (
            <Grid item={true} xs={12}>
                <a href={docURL} target="_blank" rel="noreferrer">{description}</a>
                {this.props.shouldHideRemoveDocument ? undefined : this.getRemoveDocumentLink()}
            </Grid>
        );
    }

    public getRemoveDocumentLink() {
        return (
            <IconButton aria-label="Remove Document" onClick={this.removeDocuments} style={{display: "inline"}}>
                <DeleteForeverOutlined/>
            </IconButton>
        );
    }

    private removeDocuments(): void {
        const { appId, setAppError, listDocuments, cToken, documentLink, isThirdPartyApp } = this.props;

        ApplicationDocumentService.removeScarecrowDocuments(cToken, appId,
            [documentLink.documentId], isThirdPartyApp).subscribe(
            function(res) {
                listDocuments(appId);
            },
            function(error: Error) {
                setAppError();
            },
        );
    }
}

export default withStyles(style)(CustomerDocumentLink);
