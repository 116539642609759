import { combineReducers } from "redux";
import errorStateReducer, { ErrorState } from "../ErrorState";
import jurisdictionInfoReducer, { JurisdictionInfoState } from "../jurisdictionInfo";
import customerAuthenticationReducer, { CustomerAuthenticationStatus } from "./customerAuthentication";
import customerFlowReducer, { CustomerFlowState } from "./customerFlow";
import { customerLoginReducer, CustomerLoginState } from "./customerFlowLogin";
import { forgotPasswordReducer, ForgotPasswordState } from "./customerForgotPassword";


export interface CustomerAction {
    type: string;
    payload?: any;
}

export interface CustomerAppState {
    loginState: CustomerLoginState;
    forgotPassword: ForgotPasswordState;
    rest: CustomerFlowState;
    auth: CustomerAuthenticationStatus;
    ErrorState: ErrorState;
    JurisdictionInfo: JurisdictionInfoState;
}

export default combineReducers({
    loginState: customerLoginReducer,
    forgotPassword: forgotPasswordReducer,
    rest: customerFlowReducer,
    auth: customerAuthenticationReducer,
    ErrorState: errorStateReducer,
    JurisdictionInfo: jurisdictionInfoReducer,
});
