import { IconButton, Snackbar } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { StyledComponentProps, StyleRules, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import { History } from "history";
import i18next from "i18next";
import * as React from "react";
import { Component, Fragment, RefObject } from "react";
import { Trans } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import ConnectedFlowForgotPasswordModal from "../../containers/customer/ConnectedFlowForgotPasswordModal";
import ConnectedLanguageSelector from "../../containers/customer/ConnectedLanguageSelector";
import { areCookiesEnabled } from "../../services/CookieService";
import AssistedLogInForm from "./AssistedLogInForm";
import AppLoader from '@usb-ui-tools/micro-app-loader/dist/cjs/index.js';
import Grid from "@material-ui/core/Grid";
import ARHttp from "../../services/ARHttp";
import { v4 as uuidv4 } from "uuid";


const styles: StyleRules = {
    pageContent: {
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column",
        maxWidth: "960px",
    },
    cardContent: {
        padding: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column",
        width: "fit-content",
    },
    ["@media (min-width: 767px)"]: {
        snackBar: {
            maxWidth: "100%",
        },
    },
    form: {
        padding: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column",
    },
    formControl: {
        marginTop: "18px",
        marginBottom: "4px",
        minWidth: "300px",
    },
    addMargin: {
        marginTop: "2em",
        marginBottom: "2em",
    },
    subtext: {
        fontSize: "10px",
        fontWeight: "normal",
        color: "rgb(82, 82, 82)",
    },
    errorText: {
        color: "#83380c",
    },
};

interface Props {
    country: string;
    language: string;
    referrer: string;
    loginPending: boolean;
    loginError: boolean;
    sessionExpired: boolean;
    isThirdParty: boolean;
    resetPasswordSuccess: boolean;
    unlockSuccess: boolean;
    hasAcceptedCookies: boolean;
    onSubmit: (username: string, password: string, history?: History, transmitToken?: string) => void;
    showCookieModal: () => void;
    clearExpiredState: () => void;
    clearResetPasswordSuccessState: () => void;
    clearUnlockSuccess: () => void;
    passwordExpired: boolean;
}

interface State {
    snackBarOpen: boolean;
    resetPasswordSuccessSnackBarOpen: boolean;
    isThirdPartyApp: boolean;
    unlockSuccessToastOpen: boolean;
    showNetworkError: boolean;
    showFinalizeLoginError: boolean;
    showConfigFetchError: boolean;
    showUserLockedError: boolean;
    showUserIncorrectLogin: boolean;
    hideLoginWidget: boolean;
    loginLoading: boolean;
    hasDoneFetchConfig: boolean;
    header: object;
    configSettings: object;
    configApiUrl: string;
    loginWidgetManifestPath: string;
}

class AssistedLogin extends Component<Props & StyledComponentProps & RouteComponentProps, State> {
    // eslint-disable-next-line
    private loginWidgetRef: RefObject<HTMLElement>;

    constructor(props) {
        super(props);

        this.state = {
            snackBarOpen: false,
            resetPasswordSuccessSnackBarOpen: false,
            isThirdPartyApp: false,
            unlockSuccessToastOpen: false,
            showConfigFetchError: false,
            showNetworkError: false,
            showFinalizeLoginError: false,
            showUserLockedError: false,
            showUserIncorrectLogin: false,
            hideLoginWidget: true,
            loginLoading: true,
            hasDoneFetchConfig: false,
            header: {},
            configSettings: {},
            configApiUrl: '',
            loginWidgetManifestPath: '',
        };

        this.closeSnackbar = this.closeSnackbar.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.closeResetPasswordSuccessSnackBar = this.closeResetPasswordSuccessSnackBar.bind(this);
        this.closeUnlockSuccess = this.closeUnlockSuccess.bind(this);
        this.onTransmitAuthorizationSuccess = this.onTransmitAuthorizationSuccess.bind(this);
        this.onTransmitAuthorizationFailure = this.onTransmitAuthorizationFailure.bind(this);
        this.loginWidgetRef = React.createRef<HTMLElement>();

    }

    public componentDidMount() {
        if (this.props.sessionExpired) {
            this.setState({ snackBarOpen: true });
        }
        if (this.props.resetPasswordSuccess) {
            this.setState({ resetPasswordSuccessSnackBarOpen: true });
        }
        if (this.props.unlockSuccess) {
            this.setState({ unlockSuccessToastOpen: true });
        }
        if (this.props.isThirdParty) {
            this.setState({isThirdPartyApp: true});
        }

        return ARHttp({
            method: "GET",
            url: "/aws/mfa-config-settings",
            noErrorHandling: true,
        })
        .subscribe(
            (data: any) => {
            const { responseId, mfaConfig } = data;
            if (responseId !== 0) {
                this.setState({showConfigFetchError: true, hideLoginWidget: true, loginLoading: false});
                return;
            }

            const {
                header, configSettings, configApiUrl, hideLoginWidget,
            } = mfaConfig;
            let { loginWidgetManifestPath } = mfaConfig;

            if (hideLoginWidget) {
                this.setState({hideLoginWidget: true, loginLoading: false, hasDoneFetchConfig: true});
                return;
            }

            const basePagePath = `/${window.location.pathname.replace(/^\/+|\/+$/g, '')}`; // turns /assisted-signup/ into /assisted-signup

            // Akamai proxy is set up on /assisted-signup/auth/...,
            // but we have to add the /assisted-signup part here if the manifest path doesn't have a protocol/hostname on it
            if (loginWidgetManifestPath.startsWith('/') && !loginWidgetManifestPath.startsWith(basePagePath)) {
                loginWidgetManifestPath = basePagePath + loginWidgetManifestPath;
            }

            this.setState({
                hideLoginWidget,
                header,
                configSettings,
                configApiUrl,
                loginWidgetManifestPath,
                loginLoading: false,
                hasDoneFetchConfig: true,
            });
            this.assignCorrelationId(header, configSettings);
            },
            () => {
                this.setState({
                    hideLoginWidget: true,
                    loginLoading: false,
                    showNetworkError: true,
                });
            },
        );
    }

    public componentDidUpdate(prevProps, prevState) {
        if (prevState.hideLoginWidget !== this.state.hideLoginWidget) {
            if (!this.state.hideLoginWidget) {
                const userLang = 'userLanguage';
                const userLangFromCookie = this.getCookie(userLang);
                const params = new URLSearchParams(window.location.search);
                const userLangFromUrlParams = params.get(userLang);
                const locale = userLangFromCookie || userLangFromUrlParams;
                if (locale) {
                    let language = locale.toLowerCase().replace(/_/g, '-');
                    const noChangeLangs = ['en-us', 'fr-ca', 'en-ca', 'en-gb'];
                    if (!noChangeLangs.includes(language)) {
                        language = locale.slice(0, 2);
                    }
                    setTimeout(function() {
                        window.postMessage({ lang: language }, window.location.origin);
                    }, 1500);
                }
            }
        }
        if (!this.state.hideLoginWidget) {
            if (((prevProps.loginError !== this.props.loginError) && this.props.loginError) ||
                ((prevProps.passwordExpired !== this.props.passwordExpired) && this.props.passwordExpired)) {
                this.setState({loginLoading: true});
                return;
            }
            if (this.state.hasDoneFetchConfig && this.state.loginLoading) {
                this.setState({loginLoading: false});
            }
        }
    }

    public render() {
        if (this.state.loginLoading) {
            return null;
        }
        const classes = this.props.classes!;
        window.document.title = i18next.t("customer_ui_login");

        window.process = process;

        const appNameForSiteCat = '';
        const uxNameForSiteCat = '';
        const clientNameForSiteCat = '';
        const appDynamicsKey = '';

        let configError = <></>;
        let networkError = configError;
        let loginError = networkError;
        let passwordExpiredError = loginError;
        let userLockedError = passwordExpiredError;
        let userIncorrectLogin = userLockedError;
        let finalizeLoginError = userIncorrectLogin;
        if (this.state.showConfigFetchError) {
            configError =  (
            <div className={classes.addMargin} id="configError">
                <div className={classes.subtext} style={{width: "auto", textAlign: "center"}}>
                    <u>Note:</u> <Trans i18nKey={"login_widget_config_error"} />
                </div>
            </div>
            );
        }
        if (this.state.showNetworkError) {
            networkError =  (
            <div className={classes.addMargin}>
                <Grid item={true} xs={12}>
                    <Typography variant="body2" className={classes.errorText} id="networkError">
                        <Trans>login_widget_network_error</Trans>
                    </Typography>
                </Grid>
            </div>
            );
        }
        if (this.props.loginError) {
            loginError = (
            <div className={classes.addMargin}>
                <Grid item={true} xs={12}>
                    <Typography variant="body2" className={classes.errorText} id="loginError">
                        <Trans>login_error</Trans>
                    </Typography>
                </Grid>
            </div>
            );
        }
        if (this.props.passwordExpired) {
            passwordExpiredError = (
            <div className={classes.addMargin}>
                <Grid item={true} xs={12}>
                    <Typography variant="body2" className={classes.errorText} id="passwordExpiredError">
                        <Trans>password_expired_error</Trans>
                    </Typography>
                </Grid>
            </div>
            );
        }
        if (this.state.showUserLockedError) {
            userLockedError = (
            <div className={classes.addMargin}>
                <Grid item={true} xs={12}>
                    <Typography variant="body2" className={classes.errorText} id="retryAttemptsError">
                        <Trans>login_widget_retry_attempts_error</Trans>
                    </Typography>
                </Grid>
            </div>
            );
        }
        if (this.state.showFinalizeLoginError) {
            finalizeLoginError = (
            <div className={classes.addMargin}>
                <Grid item={true} xs={12}>
                    <Typography variant="body2" className={classes.errorText} id="finalizeLoginError">
                        <Trans>login_widget_finalize_login_token_error</Trans>
                    </Typography>
                </Grid>
            </div>
            );
        }
        if (this.state.showUserIncorrectLogin) {
            userIncorrectLogin = (
            <div className={classes.addMargin}>
                <Grid item={true} xs={12}>
                    <Typography variant="body2" className={classes.errorText} id="incorrectLoginError">
                        <Trans>login_widget_incorrect_login</Trans>
                    </Typography>
                </Grid>
            </div>
            );
        }
        let loginContent = (
        <>
        <CardHeader title={<Trans i18nKey={"enterPassword.title"} />} component="h1" />
        {this.renderSnackbars(classes)}
        <CardContent className={classes.cardContent}>
            {this.showLanguageSelector(this.props.country)}
            { this.state.isThirdPartyApp ?
                <Typography variant="body1">
                    {""}
                </Typography> :
                <Typography variant="body1">
                    {this.props.country == 'CAN' ? <Trans i18nKey={"enterPassword.body_can"} /> : <Trans i18nKey={"enterPassword.body"} />}
                </Typography>
            }
            {loginError}
            {passwordExpiredError}
            {userLockedError}
            {finalizeLoginError}
            {userIncorrectLogin}
            <AppLoader
                elementRef={this.loginWidgetRef}
                id="auth-login"
                isDomainPrefixedToManifestPath={false}
                manifestPath={this.state.loginWidgetManifestPath}
                namespace="USBAuthLoginModule"
                appProps={{
                    configApiHeaders: this.state.header,
                    configSettings: this.state.configSettings,
                    onAuthorizationSuccess: this.onTransmitAuthorizationSuccess,
                    onAuthorizationFailure: this.onTransmitAuthorizationFailure,
                    appNameForSiteCat,
                    uxNameForSiteCat,
                    clientNameForSiteCat,
                    isOLB: false,
                    isAppDEnabled: true,
                    isReportingEnabled: false,
                    isIovationEnabled: false,
                    isJSLoggerEnabled: false,
                    configApiURL: this.state.configApiUrl,
                    appDKey: appDynamicsKey,
                    isNewLASUrl: true,
                    isShieldMFAEnabled: true,
                    isHeaderRequired: false,
                    isFooterRequired: false,
                }}
            />
            <br />
            <ConnectedFlowForgotPasswordModal />
        </CardContent></>
        );
        if (this.state.hideLoginWidget) {
            loginContent = (
            <>
            <CardHeader title={<Trans i18nKey={"enterPassword.title"} />} component="h1" />
                    {this.renderSnackbars(classes)}
                    <CardContent className={classes.cardContent}>
                        {this.showLanguageSelector(this.props.country)}
                        { this.state.isThirdPartyApp ?
                            <Typography variant="body1">
                                {""}
                            </Typography> :
                            <Typography variant="body1">
                                {   this.props.country == 'CAN' ?
                                        <Trans i18nKey={"enterPassword.body_can"} /> : 
                                        <Trans i18nKey={"enterPassword.body"} />
                                }
                            </Typography>
                        }
                        <AssistedLogInForm
                            onSubmit={this.onSubmit}
                            pending={this.props.loginPending}
                            error={this.props.loginError}
                            passwordExpired={this.props.passwordExpired}
                        />
                        <br />
                        <ConnectedFlowForgotPasswordModal />
                        {configError}
                        {networkError}
                    </CardContent>
            </>
            );
        }

        return (
            <Card className={classes.pageContent} style={{ padding: "24px", marginTop: "32px"}}>
               {loginContent}
            </Card>
        );
    }

    private onTransmitAuthorizationSuccess(transmitResponse) {
        const user = JSON.parse(atob(transmitResponse?.token.split('.')[1].toString()))?.sub;
        this.props.onSubmit(user, '', this.props.history, transmitResponse.token);
        return;
    }

    private onTransmitAuthorizationFailure(transmitResponse) {
        if (transmitResponse?.msg?._data?.failure_data?.reason?.type === 'locked') {
            this.setState({showUserLockedError: true, loginLoading: true});
        } else if (transmitResponse?.Status !== 7) {
            if (transmitResponse?.msg?._data?.assertion_error_code === 5) {
                this.setState({showUserIncorrectLogin: true, loginLoading: true});
            } else {
                this.setState({showFinalizeLoginError: true, loginLoading: true});
            }
        }
        return;
    }

    private assignCorrelationId(header, configSettings) {
        const id = uuidv4(); // generates uuid v4
        header['Correlation-ID'] = id;
        if (configSettings?.transmitConfigs?.policyParams) {
          configSettings.transmitConfigs.policyParams.correlationId = id;
        }
    }

    private showLanguageSelector(country) {
        if (country === "CAN") {
            return <ConnectedLanguageSelector />;
        } else {
            return null;
        }
    }

    private onSubmit(username: string, password: string) {
        if ((this.props.country !== "CAN" && this.props.country !== "USA") && (!areCookiesEnabled() || !this.props.hasAcceptedCookies)) {
            this.props.showCookieModal();
        } else {
            this.props.onSubmit(username, password, this.props.history);
        }
    }

    private closeSnackbar() {
        this.props.clearExpiredState();
        this.setState({ snackBarOpen: false });
    }

    private closeResetPasswordSuccessSnackBar() {
        this.props.clearResetPasswordSuccessState();
        this.setState({ resetPasswordSuccessSnackBarOpen: false });
    }

    private closeUnlockSuccess() {
        this.props.clearUnlockSuccess();
        this.setState({ unlockSuccessToastOpen: false });
    }

    private renderSnackbars(classes: Partial<Record<string, string>>) {
        return (
            <Fragment>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    ContentProps={{ className: classes.snackBar }}
                    open={this.state.snackBarOpen}
                    onClose={this.closeSnackbar}
                    message={<Trans i18nKey={"alert_session_expired"} />}
                    action={[
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.closeSnackbar}>
                            <Close />
                        </IconButton>,
                    ]}
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    ContentProps={{ className: classes.snackBar }}
                    open={this.state.resetPasswordSuccessSnackBarOpen}
                    onClose={this.closeResetPasswordSuccessSnackBar}
                    message={<Trans i18nKey={"password_reset_success"} />}
                    action={[
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.closeResetPasswordSuccessSnackBar}>
                            <Close />
                        </IconButton>,
                    ]}
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    ContentProps={{ className: classes.snackBar }}
                    open={this.state.unlockSuccessToastOpen}
                    onClose={this.closeUnlockSuccess}
                    message={<Trans i18nKey={"unlockaccount_success_detail"} />}
                    action={[
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.closeUnlockSuccess}>
                            <Close />
                        </IconButton>,
                    ]}
                />
            </Fragment>
        );
    }

    private getCookie = (name: string): string | undefined => {
        const value = "; " + document.cookie;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts
                .pop()!
                .split(";")
                .shift();
        }
    }

}

export default withRouter(withStyles(styles)(AssistedLogin) as any);
