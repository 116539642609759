import { CustomerAction } from ".";

export interface CustomerAuthenticationStatus {
    initialAttemptPending: boolean;
    loggedIn: boolean;
    username?: string;
    ctoken?: string;
}

const initialState: CustomerAuthenticationStatus = {
    initialAttemptPending: true,
    loggedIn: false,
};

export default function customerAuthenticationReducer(state: CustomerAuthenticationStatus = initialState, action: CustomerAction) {
    switch (action.type) {
        case "INITIAL_TEST_COMPLETE":
            return {
                ...state,
                initialAttemptPending: false,
            };

        case "TOUCH_SUCCESS":
            return {
                ...state,
                loggedIn: true,
                ctoken: action.payload,
            };

        case "LOGIN_SUCCESS":
            return {
                ...state,
                loggedIn: true,
                ctoken: action.payload.token,
            };

        case "WHOAMI_SUCCESS":
            return {
                ...state,
                username: action.payload,
            };

        case "WHOAMI_FAILURE":
        case "TOUCH_FAILURE":
        case "LOGOUT":
        case "EXPIRE_SESSION":
            return {
                ...state,
                loggedIn: false,
                username: undefined,
                ctoken: undefined,
            };

        default:
            return state;
    }
}
