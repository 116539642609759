import App, { LogicalAppStatus } from "../models/App";
import GroupApp from "../models/GroupApp";
import { GroupAppStatus } from "../models/GroupAppStatus";

export function getLogicalStatusFromApp(app: App): LogicalAppStatus {
    if (app.sensitiveDataRemoved) {
        switch (app.appState) {
            case "ROUTED_TO_SIGNER":
            case "ROUTED_TO_COMPLETER":
            case "HOLD_AFTER_SIGNATURE":
            case "HOLD_SIGNATURE_EXPIRED":
                return LogicalAppStatus.EXPIRED;
            default:
                return LogicalAppStatus.DATA_REMOVED;
        }
    }
    switch (app.appState) {
        case "ROUTED_TO_COMPLETER":
            return LogicalAppStatus.TO_COMPLETER;

        case "ROUTED_TO_SIGNER":
            return LogicalAppStatus.TO_SIGNER;

        case "DATASETONE":
        case "DATASETONE_PROCESSING":
        case "DATASETONE_CREDIT_ERROR":
        case "DATASETTWO":
        case "DATASETTWO_PROCESSING":
        case "DATASETTWO_BOARDING_ERROR":
        case "DOCUMENTS":
        case "DOCUMENTS_PROCESSING":
        case "DOCUMENTS_ERROR":
            return LogicalAppStatus.PROCESSING;

        case "HOLD_AFTER_SIGNATURE":
            return LogicalAppStatus.HOLD;

        case "HOLD_SIGNATURE_EXPIRED":
            return LogicalAppStatus.HOLD_EXPIRED;

        case "FINISHED":
            if (
                app.boardingStatus === "DECLINED" ||
                app.boardingStatus === "WITHDRAWN" ||
                app.decision === "DECLINE" ||
                app.decision === "EXCEED_MAX_REVENUE"
            ) {
                return LogicalAppStatus.DECLINED;
            } else {
                return LogicalAppStatus.COMPLETED;
            }

        case "DECISION":
            return LogicalAppStatus.DECLINED;

        case "NONE":
        case "DRAFT":
        default:
            return LogicalAppStatus.ERROR;
    }
}

export function getLogicalStatusFromGroup(group: GroupApp): LogicalAppStatus {
    if (group.appInfo.sensitiveDataRemoved) {
        return LogicalAppStatus.DATA_REMOVED;
    }
    switch (group.groupInfo.status) {
        case GroupAppStatus.DRAFT:
            return LogicalAppStatus.TO_SIGNER;

        case GroupAppStatus.EXECUTING:
        case GroupAppStatus.ERROR:
            return LogicalAppStatus.PROCESSING;

        case GroupAppStatus.SUBMITTED:
        case GroupAppStatus.COMPLETED:
            return LogicalAppStatus.COMPLETED;

        default:
            return LogicalAppStatus.ERROR;
    }
}
