import { History } from "history";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import CustomerFlowRegistration from "../../components/customer/CustomerFlowRegistration";
import { SalesPersonContact } from "../../models/SalesPersonContact";
import { CustomerAppState } from "../../reducers/customer_ui";

const mapStateToProps = (state: CustomerAppState) => ({
    country: state.JurisdictionInfo.country,
    userLanguage: state.rest.userLanguage,
    createAccountToken: state.rest.createAccountString,
    userAcceptedCookies: state.rest.cookiesEnabled || state.rest.gdprCookiesPolicyEnabled,
    confCode: state.rest.confirmationCode,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setType2Verification: (isType2: boolean) => {
            dispatch({ type: "SET_TYPE_2_VERIFICATION", payload: isType2 });
        },
        toggleCookieModal: () => {
            dispatch({ type: "TOGGLE_COOKIE_MODAL" });
        },
        registerSuccess: (token: string, history: History) => {
            dispatch({
                type: "LOGIN_SUCCESS",
                payload: {
                    token,
                    history,
                },
            });
        },
        setIsForThirdPartyAndSalesContact: (thirdParty: boolean, salesPersonContact?: SalesPersonContact) => {
            dispatch({type: "SET_THIRD_PARTY_AND_SALES_CONTACT", payload: {
                    thirdParty,
                    salesPersonContact,
                }});
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerFlowRegistration);
