import { Card, CardContent, CardHeader } from "@material-ui/core";
import * as React from "react";
import { Component } from "react";
import { Trans } from "react-i18next";

export default class SignaturePageFailure extends Component {
    public render() {

        const failedToLoaderHeaderElem = <Trans>document_signature_load_failure_header</Trans>;

        return (
            <Card>
                <CardHeader title={failedToLoaderHeaderElem} role="heading" aria-level={1} />
                <CardContent>
                    <Trans>document_signature_load_failure_body</Trans>
                </CardContent>
            </Card>
        );
    }
}
