import { Checkbox, FormControlLabel } from "@material-ui/core";
import * as React from "react";
import { Trans } from "react-i18next";

interface Props {
    toggleTermsOfService?: () => void;
    termsLink?: string;
    country?: string;
}

export default class TermsOfService extends React.Component<Props, any> {
    constructor(props) {
        super(props);

        this.onCheck = this.onCheck.bind(this);
    }

    public render() {
        const { termsLink, country } = this.props;

        let label;
        if (country === "POL") {
            label = <Trans>signature_accept_tos</Trans>;
        } else {
            label = (
                <p>
                    <Trans>terms_of_service_checkbox</Trans>
                    <a href={termsLink} target="_blank" rel="noreferrer">{<Trans>terms_of_service_checkbox_link</Trans>}</a>
                </p>
            );
        }

        return (
            <FormControlLabel
                value="end"
                control={<Checkbox color="primary" onChange={this.onCheck} />}
                label={label}
                labelPlacement="end"
                />
        );
    }

    private onCheck(event) {
        if (this.props.toggleTermsOfService) {
            this.props.toggleTermsOfService();
        }
    }
}
